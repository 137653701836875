import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import useStylePreguntasListas from './useStylePreguntasListas';
import { useNavigate } from 'react-router-dom';
const PreguntasListas = ({ questionCount, folderId, documentId, onClose }) => {
  const classes = useStylePreguntasListas();
  const navigate = useNavigate();
  return (
    <div>
      <div className={classes.overlay} onClick={onClose}></div> {/* Add this */}
      <Box className={classes.popup}>
        <Typography className={classes.title} component="div">
          ¡Listo para estudiar!🚀
        </Typography>
        <Typography className={classes.subtitle}>
          Ya tienes preparadas las preguntas para que memorices a la perfección.
        </Typography>
        <Button
          className={classes.button}
          onClick={() => {
            navigate(`/${folderId}/${documentId}`);
            onClose();
          }}
          style={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            textTransform: 'none',
            width: '60%',
            fontWeight: '600',
            fontFamily: "'Inter', sans-serif",
            borderRadius: '24px',
            padding: '8px 24px',
            fontSize: '16px',
            marginBottom: "5px",
            display: 'block',
            margin: 'auto',
          }}
        >
          Ver preguntas
        </Button>
      </Box>
    </div>
  );
};

export default PreguntasListas;
