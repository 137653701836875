import { makeStyles } from "@material-ui/core/styles"

const useStylesPricing = makeStyles((theme) => ({
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: '32px',
    color: '#4FB3BF',
    textAlign: 'center',
    maxWidth: '60%',
    margin: '0 auto',
    lineHeight: 1.2,
  },
  container: {
    backgroundColor: 'transparent',
    padding: '20px',
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
    display: 'flex', // Added
    flexDirection: 'column', // Added
    alignItems: 'center', // Added
  },
  typedCard: {
    backgroundColor: '#6BBDBD',
    borderRadius: '40px',
    padding: '44px',
    color: '#FFFFFF',
    width: '80%',
    maxWidth: '600px',
    margin: '50px auto',
    boxSizing: 'border-box',
  },
  typedTitle: {
    fontWeight: 700,
    fontSize: '42px',
    marginBottom: theme.spacing(1),
  },
  typedSubtitle: {
    fontWeight: "700",
    fontSize: "18px",
    marginLeft: "50px",
    lineHeight: "1.2",
    marginBottom: theme.spacing(2)

  },
  featureList: {
    padding: 0,
    
  },
  featureItem: {
    padding: '4px 0',
    
  },
  checkIcon: {
    color: '#FFFFFF',
  },
  planTitle: {
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: "24px",
  },
  planContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  planBox: {
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    padding: '12px',
    width: '48%',
    textAlign: 'center',
  },
  price: {
    color: '#4FB3BF',
    fontWeight: 700,
    '& span': {
      fontSize: '0.8em',
      fontWeight: 400,
    },
  },
  subscribeButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: "20px",
    marginTop: "24px",
    color: '#4FB3BF',
    fontWeight: 600,
    fontSize: "18px",
    display: 'block',
    margin: '0 auto',
    textTransform: "none",
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#4FB3BF',
    },
  },
}));

export default useStylesPricing;
