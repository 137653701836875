import { getCsrfToken } from '../Api/TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// Stream justification from backend
export const streamJustification = async (data, onChunk, user, token) => {
    const user_id = user['sub'];

    try {
        const response = await fetch(`${BACKEND_URL}/api_v1/justification/?user_id=${user_id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                "X-CSRFToken": getCsrfToken()
            },
            body: JSON.stringify(data),
            credentials: 'include'
        });

        if (!response.body) {
            throw new Error('ReadableStream not supported');
        }



        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';

        const processText = (text) => {
            try {
                const lines = text.split('\n');
                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        const data = JSON.parse(line.slice(6));
                        if (data.content === '[DONE]') {
                            onChunk('[DONE]');
                            return true;
                        }
                        if (data.content) {
                            // Send only the new content
                            onChunk(data.content);
                        }
                    }
                }
            } catch (e) {
                console.error('Error processing stream:', e);
            }
            return false;
        };

        while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value, { stream: true });
            buffer += chunk;

            const messages = buffer.split('\n\n');
            buffer = messages.pop() || '';

            for (const message of messages) {
                if (processText(message)) {
                    return;
                }
            }
        }
    } catch (error) {
        console.error('Stream error:', error);
        throw error;
    }
};
