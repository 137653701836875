import React from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Typography, Box } from '@mui/material';

const ErrorPreguntes = ({ open, onClose }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="xs" 
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '24px', // Increased border radius here
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" component="span" style={{fontFamily: "'Inter', sans-serif", fontWeight: 'bold', fontSize: '20px'}}>
            ❌ Error
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom style={{fontFamily: "'Inter', sans-serif", fontSize: '16px', marginBottom: '20px'}}>
          Ha habido un error al generar las preguntas de test.
          Por favor, pruébalo de nuevo más tarde.
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
                backgroundColor: '#6BBDBD',
                border: "1px solid #6BBDBD",
                color: 'white',
                textTransform: 'none',
                fontWeight: '600',
                fontFamily: "'Inter', sans-serif",
                borderRadius: '24px',
                padding: '8px 24px',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                boxShadow: 'none',

                '&:hover': {
                  backgroundColor: '#6BBDBD',
                },
                width: '50%',
                maxWidth: 'none',
              }}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorPreguntes;
