import { makeStyles } from "@material-ui/core/styles";

const useChooseFileStyles = makeStyles((theme) => ({

    gridContainer: {
        flexWrap: "nowrap",
        display: "flex",
        overflowY: "hidden",
        // Other styles...
        justifyContent: "flex-start", // Add this line
        maxWidth: "none", // Add this line
        margin: "0px", // Add this line
    },

    leftPart: {
        //     maxWidth: "initial",
        //     flexBasis: '50vw', // Add this line
        border: "1px solid #6BBDBD",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px", // Add this line
        borderBottomRightRadius: "0px",
        borderBottom: "0px",
        //     marginLeft: "50px",
        //     backgroundColor: "white"
        display: "flex",
        flexDirection: "column",
        flex: 2, // This will make the paper fill the height of its container
        overflowY: 'auto',
        backgroundColor: "white",
        height: "100vh",



    },
    paperLeft: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        flexGrow: 1,
        minHeight: "0", // Ensure it can shrink to fit its container
        overflowY: "auto",  // Enable vertical scrolling
        backgroundColor: "white",
        margin: "20px 50px 3px 50px",
        paddingBottom: "100px"

    },

    rightPart: {
        // height: "calc(100vh - 40px)", /* Full viewport height */
        // borderLeft: "1px solid black",
        boxShadow: "none !important",
        marginBottom: "40px"
        , flexBasis: '22vw', // Sets the base size of the left part
        [theme.breakpoints.up('2000')]: {
            flexBasis: '22vw',

        },
        [theme.breakpoints.down('1500')]: {
            flexBasis: '22vw', // Sets the base size of the left part

        },
    },
    title: {
        color: "#026277",
        textAlign: "center",
        fontSize: "25px",
        fontWeight: "bold"
    },
    subtitle: {
        textAlign: "center",
        fontSize: "14px",

    },
    boxWrite: {
        backgroundColor: '#6BBDBD',
        color: "white",
        width: 129,
        height: 115,
        display: "flex",
        flexDirection: "column",
        borderRadius: "20px",
        padding: "8px"
    },
    boxAudio: {
        backgroundColor: 'white',
        color: "#6BBDBD",
        width: 129,
        height: 115,
        display: "flex",
        flexDirection: "column",
        borderRadius: "20px",
        border: " 1px solid #6BBDBD",
        padding: "8px"

    }
    , buttonEmpezar: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "14px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "80px",

        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",


        }

    },
    stepsTitle: {
        fontSize: "14px",
        fontWeight: "bold",
        margin: "10px 0px 0px 0px"
    },
    description: {
        fontSize: "14px"
    }

}));

export default useChooseFileStyles;