import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Grid,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import FeedbackPopup from './SecondCancellationPopup';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: "20px",
    maxWidth: "400px",
  },
}));

const StatItem = ({ value, label }) => (
  <Box textAlign="center">
    <Typography variant="h6" fontWeight="bold" style={{ fontSize: '1.25rem', fontFamily: 'Inter, sans-serif' }}>
      {value}
    </Typography>
    <Typography variant="body2" style={{ fontSize: '0.8rem', fontFamily: 'Inter, sans-serif', color: "#666666" }}>
      {label}
    </Typography>
  </Box>
);

const FarewellPopup = ({ open, onClose, onContinue, userName, stats }) => {
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);

  const handleContinue = async () => {
    await onContinue();
    window.location.href = 'https://typedai.com';
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  return (
    <>
      <StyledDialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography variant="h5" align="left" gutterBottom sx={{ fontSize: '1.5rem', fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}>
            Te echaremos de menos... 😢
          </Typography>

          <Box display="flex" justifyContent="center" my={3}>
            <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Cancelation.svg" alt="Farewell" style={{ width: 120, height: 120 }} />
          </Box>

          <Typography variant="body1" align="left" paragraph sx={{ fontSize: '1rem', fontFamily: 'Inter, sans-serif' }}>
            {userName}, nos entristece verte partir... Esperamos haberte ayudado en tu oposición .
          </Typography>

          <Typography variant="body1" align="center" fontWeight="bold" paragraph sx={{ fontSize: '1rem', fontFamily: 'Inter, sans-serif', marginTop: "15px" }}>
            ¿Qué has estudiado con Typed?
          </Typography>

          <Grid container spacing={2} justifyContent="space-between" sx={{ marginTop: "15px" }}>
            {stats.map((stat, index) => (
              <Grid item key={index} xs={6} sm={3}>
                <StatItem value={stat.value} label={stat.label} />
              </Grid>
            ))}
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              color="primary"
              onClick={onClose}
              sx={{ width: "150px", backgroundColor: "#6BBDBD", color: "white", "&:hover": { backgroundColor: "#6BBDBD" }, marginRight: "10px", textTransform: "none", borderRadius: "20px", fontFamily: 'Inter, sans-serif', fontWeight: "bold", fontSize: "0.8rem", boxShadow: "none" }}

            >
              Volver a Typed
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleContinue}
              sx={{ backgroundColor: "#AA161D", color: "white", "&:hover": { backgroundColor: "#AA161D" }, textTransform: "none", borderRadius: "20px", fontFamily: 'Inter, sans-serif', fontWeight: "400", fontSize: "0.8rem", boxShadow: "none" }}
            >
              Continuar
            </Button>
          </Box>
        </DialogContent>
      </StyledDialog>
      
    </>
  );
};

export default FarewellPopup;
