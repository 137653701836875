import { Box } from "@material-ui/core";
import React from "react";
import DocumentListFolder from "../Documents/AllListDocumentsFolder";

function FolderContent({ 
    listDocuments, 
    folder, 
    updateListDocuments, 
    accessToken, 
    user, 
    setShowPremium 
}) {
    return (
        <Box style={{
            marginTop: '20px',
            marginBottom: '80px',
            backgroundColor: 'white',
            borderRadius: '20px'
        }}>
            <DocumentListFolder
                listDocuments={listDocuments}
                isLoading={false}
                selectedDocument={null}
                onDocumentSelect={() => {}}
                selectable={false}
                box={true}
                token={accessToken}
                user={user}
                updateListDocuments={updateListDocuments}
                setShowPremium={setShowPremium}
            />
        </Box>
    );
}

export default FolderContent;
