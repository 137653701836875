import React, { useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),

  },
}));

const FeedbackPopup = ({ open, onClose }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const loadTally = () => {
      if (window.Tally && iframeRef.current) {
        window.Tally.loadEmbeds();
      } else {
        // If Tally or iframe is not available, retry after a short delay
        setTimeout(loadTally, 100);
      }
    };

    if (open) {
      loadTally();
    }
  }, [open]);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Typography variant="h5" align="left" gutterBottom sx={{ fontSize: '1.5rem', fontWeight: 'bold', fontFamily: 'Inter, sans-serif' }}>
          Te echaremos de menos... 😢
        </Typography>
        <Box sx={{ width: '100%', height: '667px' }}>
          <iframe
            ref={iframeRef}
            data-tally-src="https://tally.so/embed/nGJOye?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Churn survey"
          ></iframe>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default FeedbackPopup;
