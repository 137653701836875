import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import './Calendar.css';

const SimpleCalendar = ({ user_calendar }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  // Convert user_calendar dates to Date objects, only if user_calendar exists
  const streakDates = user_calendar ? user_calendar.map(entry => new Date(entry.login_date)) : [];

  const tileClassName = ({ date, view }) => {
    if (view === 'month' && streakDates.length > 0) {
      const hasStreak = streakDates.some(
        streakDate =>
          streakDate.getDate() === date.getDate() &&
          streakDate.getMonth() === date.getMonth() &&
          streakDate.getFullYear() === date.getFullYear()
      );

      return hasStreak ? 'streak-day' : null;
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '16px',
          fontFamily: 'Fira Sans',
          paddingTop: "10px",
          paddingBottom: "5px"
        }}
        gutterBottom
      >
        Streak Calendar
      </Typography>
      <Box>
        <Calendar
          onChange={setCurrentDate}
          value={currentDate}
          tileClassName={tileClassName}
          locale="es-ES"
        />
      </Box>
    </>
  );
};

export default SimpleCalendar;