import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchUserInfo, fetchUserSubscription } from '../Api/UserApi'; // Assuming these are your API calls
import { useAuth } from "../Auth/Authhandle";
import posthog from 'posthog-js'

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
    const [hasSubscription, setHasSubscription] = useState(false);
    const [userData, setUserData] = useState(false);
    const { accessToken, user } = useAuth(); // Use the hook at the top level

    const fetchData = async () => {
        if (accessToken && user) {
            try {
                const userDataResults = await fetchUserInfo(accessToken, "GET", null, user);
                const subResult = await fetchUserSubscription(accessToken, user);

                setHasSubscription(subResult.manager === userDataResults.guid && subResult.plan.name === 'Pro');
                if (subResult && subResult.plan.pages) {
                    userDataResults.maxPages = subResult.plan.pages;
                }
                // Now userDataResults includes subscription pages
                setUserData(userDataResults);
                posthog.identify(
                    userDataResults.guid, // Required. Replace 'distinct_id' with your user's unique identifier
                    { email: userDataResults.email, name: userDataResults.name },  // $set, optional
                );
            } catch (error) {
                window.location.replace("/innactive")
                console.error("Error fetching subscription data:", error);
                // Handle the error appropriately
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [accessToken, user]);

    return (
        <SubscriptionContext.Provider value={{ hasSubscription, setHasSubscription, userData, fetchData }}>
            {children}
        </SubscriptionContext.Provider>
    );
};
