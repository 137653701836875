import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    fontFamily: 'Inter, sans-serif',
    borderRadius: "24px",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "white",
  color: "#333",
  fontFamily: 'Inter, sans-serif',
  
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3, 3),
}));

const FirstWeek = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleRedirectToPlans = () => {
    onClose();
    navigate('/plans');
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <StyledDialogTitle>
        <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}>
        🤔 ¿Necesitas más créditos?
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography variant="body1">
          Tus créditos de bienvenida se han agotado...😢
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginTop:"10px", marginBottom:"20px"}}>Sigue estudiado gratis con el <strong>Plan Básico</strong></Typography>
        <Box sx={{ textAlign: 'center'}}>
          <Typography variant="h2" sx={{ color: '#FFA500', fontWeight: 'bold' }}>
            100
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600, color: '#A0A0A0' }}>Créditos diarios</Typography>
        </Box>
      </StyledDialogContent>
      <StyledDialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, width: '100%' }}>
          <Button variant="contained" color="error" onClick={onClose} sx={{ textTransform: 'none', fontWeight: 600, fontSize: '16px', fontFamily: 'Inter, sans-serif', backgroundColor: '#AA161D', color: 'white', borderRadius: '24px' }}>
            Seguir con Básico
          </Button>
          <Button 
            variant="contained" 
            color="info" 
            onClick={handleRedirectToPlans} 
            sx={{ textTransform: 'none', fontWeight: 600, fontSize: '16px', fontFamily: 'Inter, sans-serif', backgroundColor: '#6BBDBD', color: 'white', borderRadius: '24px', onHover: { backgroundColor: '#6BBDBD', color: 'white' } }}
          >
            Más créditos
          </Button>
        </Box>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default FirstWeek;
