import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  Grid,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
const PopupDiagram = ({ open, onClose, onGenerate, sectionTitle }) => {
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleGenerate = () => {
    onGenerate(title, text);
    onClose();
  };

  const isFormComplete = title.trim() !== '' && text.trim() !== '';

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="lg"
      fullWidth 
      PaperProps={{
        style: { 
          borderRadius: '20px',
          height: "80%",
          display: 'flex',
          flexDirection: 'column',
        }
      }}
    >
      <DialogTitle style={{ 
        fontFamily: 'Inter', 
        fontWeight: 'bold', 
        paddingRight: '48px',
        display: 'flex',
        alignItems: 'center'
      }}>
        <AccountTreeIcon style={{ color: 'black', marginRight: '8px' }} />
        <span>Generar esquema sobre {sectionTitle}</span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* Left column content */}
            <Box sx={{ marginBottom: "5px" }}>
              {/* Title TextField */}
              <Typography variant="body1" gutterBottom style={{ fontFamily: 'Inter' }}>
                Introduce un título para tu esquema:
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={title}
                onChange={handleTitleChange}
                placeholder="Título del esquema"
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  marginBottom: "16px",
                }}
                InputProps={{
                  style: {
                    height: "40px",
                  },
                }}
              />
            </Box>
            <Box>
              {/* Text TextField */}
              <Typography variant="body1" gutterBottom style={{ fontFamily: 'Inter' }}>
                Introduce a continuación el texto sobre el que quieres generar el esquema.
              </Typography>
              <TextField
                multiline
                rows={21}
                fullWidth
                variant="outlined"
                value={text}
                onChange={handleTextChange}
                placeholder="Puedes introducir hasta 10.000 caracteres o, aproximadamente, 1.500 palabras."
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  minHeight: '450px',
                }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="15px">
              {/* Character count and chapter info */}
              <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: '600' }}>
                {text.length} de 10.000 caracteres
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Inter' }}>
                El esquema se guardará en el capítulo XXX.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {/* Right column - Gray rectangle */}
            <Box
              sx={{
                backgroundColor: '#f0f0f0',
                height: '100%',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" color="textSecondary">
                Preview will appear here
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingRight: '24px', paddingBottom: '30px', marginTop: '-20px' }}>
        <Tooltip title="Debes rellenar los campos anteriores" disableHoverListener={isFormComplete}>
          <span>
            <Button 
              onClick={handleGenerate} 
              color="primary" 
              variant="contained" 
              disabled={!isFormComplete}
              style={{ 
                fontFamily: 'Inter', 
                textTransform: 'none', 
                backgroundColor: isFormComplete ? '#6BBDBD' : '#ccc',
                color: 'white',  
                borderRadius: '20px', 
                padding: '8px 24px', 
                fontSize: '14px', 
                width: '150px',
                fontWeight: '600',
                boxShadow: "none"
              }}
            >
              ¡Generar!
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDiagram;
