import React, { createContext, useState, useContext } from 'react';

const GenerateQuestionsContext = createContext();

export const GenerateQuestionsProvider = ({ children }) => {
    const [needGenerateQuestions, setNeedGenerateQuestions] = useState(false);

    return (
        <GenerateQuestionsContext.Provider value={{ needGenerateQuestions, setNeedGenerateQuestions }}>
            {children}
        </GenerateQuestionsContext.Provider>
    );
};

export const useGenerateQuestions = () => useContext(GenerateQuestionsContext);
