import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Tooltip, TextField, FormControl, Select, MenuItem } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { fetchExamId, fetchFolderExams } from '../Api/ExamsApi';
import { useAuth } from "../Auth/Authhandle";
import useExamListStyles from "./Styles/useExamListStyles";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';

function ExamListView() {
    const { t } = useTranslation();
    const classes = useExamListStyles();
    const { accessToken, user } = useAuth();

    const [listExams, setListExams] = useState([]);
    const [currentPage, setCurrentPage] = useState(0); // Starting index at 0 for the first page
    const rowsPerPage = 10; // Fixed value as per your requirement
    const indexOfLastExam = (currentPage + 1) * rowsPerPage;
    const indexOfFirstExam = indexOfLastExam - rowsPerPage;
    // At the top where you define other hooks
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFolder, setSelectedFolder] = useState('');
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        async function fetchExams() {
            if (accessToken && user) {
                try {
                    setLoading(true);
                    const examsResponse = await fetchFolderExams(accessToken, "GET", null, user, null, null);
                    // Transform the response into an array of objects, each with a guid
                    const transformedExams = Object.entries(examsResponse).map(([guid, examDetails]) => ({
                        guid,
                        ...examDetails,
                    }));
                    setListExams(transformedExams);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                    setLoading(false);
                }
            }
        }

        fetchExams();
    }, [accessToken, user]);

    useEffect(() => {
        // Extract unique folders from exams
        if (listExams.length > 0) {
            const uniqueFolders = [...new Set(listExams.map(exam => exam.location))];
            setFolders(uniqueFolders);
        }
    }, [listExams]);

    // Filter function
    const filteredExams = listExams.filter(exam => {
        const matchesSearch = exam.title.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesFolder = !selectedFolder || exam.location === selectedFolder;
        return matchesSearch && matchesFolder;
    });

    const handleRemoveExam = async (exam_id) => {
        // Immediately filter out the exam optimistically

        try {
            // Perform the deletion request
            await fetchExamId(accessToken, "DELETE", exam_id, user);
            // No need to do anything here on success, as we've already updated the UI
            setListExams(currentExams => currentExams.filter(exam => exam.guid !== exam_id));

        } catch (error) {
            console.error(error);
            // Handle error - e.g., show an alert or undo the optimistic update

            // Optionally, fetch the exams again or revert the change in the state
        }
    };


    if (loading) return <div>Loading...</div>;
    const totalExams = listExams.length;
    const totalPages = Math.ceil(totalExams / rowsPerPage);

    const currentExams = listExams.slice(indexOfFirstExam, indexOfLastExam);
    return (

        <Grid container className={classes.gridContainer} key={listExams.length}>
            <Grid item xs={12} className={classes.titleSection}>
                <Typography variant="h6"
                    style={{
                        color: "var(--slate-900, #0F172A)",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "36px",
                        letterSpacing: "-0.225px",
                        paddingLeft: 2
                    }}> Tus exámenes </Typography>
            </Grid>

            {/* Search and Filter Section */}
            <Grid item xs={12} style={{ marginLeft: '50px' }}>
                <Box display="flex" gap="15px">
                    <TextField
                        placeholder="Buscar examen"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                            flexGrow: 1,
                            maxWidth: '700px',
                            marginRight: '20px',
                            fontSize: '14px'
                        }}
                        InputProps={{
                            style: { fontSize: '14px' }
                        }}
                    />
                    <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
                        <Select
                            value={selectedFolder}
                            onChange={(e) => setSelectedFolder(e.target.value)}
                            displayEmpty
                            style={{ fontSize: '14px' }}
                        >
                            <MenuItem value="" style={{ fontSize: '14px' }}>Todas las carpetas</MenuItem>
                            {folders.map((folder) => (
                                <MenuItem key={folder} value={folder} style={{ fontSize: '14px' }}>
                                    {folder}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>

            <Grid item xs={12}>
                {filteredExams.length > 0 ? (
                    <TableContainer
                        component={Paper}
                        className={`${classes.tableContainer} custom-scrollbar`}
                        style={{
                            marginTop: '20px',
                            maxHeight: '65vh',
                            overflowY: 'auto',
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Título</TableCell>
                                    {/* <TableCell>Carpeta</TableCell> */}
                                    <TableCell>Fecha</TableCell>
                                    <TableCell align="right">Puntuación</TableCell>
                                    <TableCell align="center">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredExams.map((exam) => (
                                    <TableRow key={exam.guid}>
                                        <TableCell>{exam.title}</TableCell>
                                        {/* <TableCell>{exam.location}</TableCell> */}
                                        <TableCell>{exam.date}</TableCell>
                                        <TableCell align="right">
                                            {exam.answered ? `${exam.score}/100` : "No finalizado"}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box display="flex" justifyContent="center" gap={1} alignItems="center">
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        fontSize: "14px",
                                                        textTransform: "none",
                                                        backgroundColor: "#6BBDBD",
                                                        borderRadius: "6px",
                                                        color: "white",
                                                        marginRight: "5px"
                                                    }}
                                                    onClick={() => navigate(`/exam/${exam.guid}`)}
                                                >
                                                    Ver
                                                </Button>
                                                {exam.is_task && (
                                                    <Tooltip title="Eliminar examen">
                                                        <DeleteIcon
                                                            style={{
                                                                fontSize: "20px",
                                                                color: "#ff4444",
                                                                cursor: "pointer",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}
                                                            onClick={() => handleRemoveExam(exam.guid)}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography className={classes.noExams}>
                        No se encontraron exámenes
                    </Typography>
                )}
            </Grid>

            {/* Pagination section remains the same */}
        </Grid>
    );
}

export default ExamListView;
