import React from 'react';
import { Box, Typography, Container } from '@mui/material';



const ErrorPreguntesDoc = () => {
  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <img
          src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/ERRORDOCS.svg"
          alt="Error"
          style={{ width: 300, height: 300, marginTop: "100px" }}
        />
      </Box>

      <Typography variant="h6" align="center" sx={{ fontFamily: 'Inter, sans-serif' }}>
        Error al generar las preguntas. Estamos trabajando en ello. Disculpa las molestias.
      </Typography>
    </Container>
  );
};

export default ErrorPreguntesDoc;
