import { makeStyles } from '@material-ui/core/styles';

const useStylePremium = makeStyles((theme) => ({
  '@import': "url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&display=swap')",
  popupContainer: {
    backgroundColor: '#F8FFFF',
    borderRadius: '3vh',
    padding: '2vh',

    width: '40vw',

    margin: 'auto',
    boxShadow: '0 0.4vh 0.6vh rgba(0, 0, 0, 0.1)',
    border: '0.1vh solid #6BBDBD',
    fontFamily: "'Inter', sans-serif",
    maxWidth: '90vw', // Added for responsiveness
    // [theme.breakpoints.down('1200')]: {
    //   width: '55vh',
    // },
  },
  title: {
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '2vh',
    textAlign: 'center',
    padding: '1vh',
    fontFamily: "'Inter', sans-serif",
    lineHeight: 1.2,
    fontSize: '2rem',
    fontWeight: '900',
  },
  price: {
    color: '#6BBDBD',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '2vh',
    fontSize: 'calc(0.8vw + 0.8vh)',
    '& span': {
      fontSize: 'calc(2vw + 1vh)',
    },
  },
  highlight: {
    backgroundColor: 'rgba(0, 181, 100, 0.2)',
    borderRadius: '2vh',
    padding: '1vh 1.5vh',
    marginBottom: '2vh',
    textAlign: 'center',
    color: '#2C7655',
    fontSize: '1rem',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subtitle: {
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '1vh',
    fontSize: '1rem',
    marginLeft: '1.5vh',
  },
  checkIcon: {
    color: '#4caf50 !important',
    fontSize: 'calc(1vw + 0.8vh) !important',
  },
  featureList: {
    paddingLeft: 0,
    marginLeft: '1.5vh',
  },
  featureItem: {
    padding: '0.2vh 0',
    display: 'flex',
    alignItems: 'center',
  },
  subscribeButton: {
    backgroundColor: '#6BBDBD !important',
    color: 'white !important',
    fontWeight: 'bold !important',
    padding: '1vh 2vh !important',
    marginTop: '2vh !important',
    width: '70%',
    borderRadius: '2.5vh !important',
    textTransform: 'none !important',
    fontSize: '0.85rem !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    display: 'block !important',
  },
}));

export default useStylePremium;
