import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import QuizIcon from '@mui/icons-material/Quiz';
import WhatshotIcon from '@mui/icons-material/Whatshot';

const StatsHome = ({ statsData }) => {
  const stats = [

    {
      value: statsData?.correct_answers || 0,
      label: 'Respuestas correctas',
      icon: QuizIcon,
      bgColor: '#E8F4FF',
      iconColor: '#5AA6FF'
    },
    {
      value: statsData?.incorrect_answers || 0,
      label: 'Respuestas incorrectas',
      icon: SentimentVeryDissatisfiedIcon,
      bgColor: '#FFE9E9',
      iconColor: '#FF7676'
    },
    {
      value: statsData?.most_productive_day?.exam_count || 0,
      label: 'Mejor día',
      icon: EmojiEventsIcon,
      bgColor: '#E2F1F1',
      iconColor: '#6BBDBD'
    },

    {
      value: statsData?.streak || 0,
      label: 'Racha más larga',
      icon: WhatshotIcon,
      bgColor: '#FFF2E5',
      iconColor: '#FF9840'
    }
  ];

  return (
    <Box >
      <Typography sx={{ fontWeight: 'bold', fontSize: '16px', fontFamily: 'Fira Sans', paddingTop: "10px", paddingBottom: "5px" }} gutterBottom>
        Estadísticas
      </Typography>

      <Grid container spacing={1}>
        {stats.map((stat, index) => (
          <Grid item xs={6} sm={6} key={index}>
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'white',
                border: '1px solid #F0F4F4',
                borderRadius: '20px',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      backgroundColor: stat.bgColor,
                      borderRadius: '50%',
                      width: 30,
                      height: 30,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {React.createElement(stat.icon, { sx: { color: stat.iconColor } })}
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      color: stat.iconColor
                    }}
                  >
                    {stat.value}
                  </Typography>
                </Box>
                <Typography
                  sx={{ fontSize: '14px', fontFamily: 'Fira Sans', color: '#A0A0A0' }}
                >
                  {stat.label}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StatsHome;
