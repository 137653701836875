import React, { useEffect, useState, useCallback } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Container,
    Typography, Button, TextField
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useSubscription } from '../Context/SubscriptionContext';

import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import SmallAlert from "../NewComponents/Modal/smallAlert";
import { useAuth } from "../Auth/Authhandle";
import { useTranslation } from 'react-i18next';

import * as handle from "./handleAdmin"
import ModalParent from "../AllModalsActions/ParentModal";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const customTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0, // Extra small devices (phones)
            sm: 600, // Small devices (tablets)
            md: 1100, // Medium devices (laptops)
            lg: 1450, // Large devices (desktops)
            xl: 2000, // Extra large devices (large desktops)
        },
    },
    palette: {
        primary: {
            //main: "#026277",
            main: "#026277",
        },
    },
});
// Import other SVG images as needed
const styles = {
    header: {
        color: "#A0A0A0",
        borderBottom: "none",
        position: "sticky", // Make the header sticky
        zIndex: 1, // Ensure it's above the table content
        top: 0, // Stick to the top
        backgroundColor: "#FAFAFA"
        , padding: '2px 50px',
        paddingLeft: '15px',
        paddingBottom: '10px'

    },
    disabledRow: {
        backgroundColor: '#f2f2f2', // A light gray color
        // other styling if needed
    },
    tableStyle: {
        width: 'auto', // This will make the table only as wide as its content
        margin: 'auto', // This centers the table in the parent container
        tableLayout: 'fixed',
    },
    fileListContainer: {
        // ...other styles
        backgroundColor: "#FAFAFA",
        borderRadius: "20px",
        marginBottom: "30px",
        marginTop: "30px",
        overflowY: "auto",
        boxShadow: "None",
        overflowX: "hidden",
        padding: '20px', // Add padding around the entire table
        // Removed display and justifyContent
        height: '330px', // Keep this if you want a fixed height
        overflow: "auto"
    },
    disabledButton: {
        backgroundColor: '#f2f2f2', // A light gray color
        // other styling if needed
    },
    tableRow: {
        height: "40px", // Adjust the height as per your preference
    },
    tableCell: {
        borderBottom: "none",
        color: "#060D01",

        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%" /* 24px */,
        cursor: "pointer", // Change cursor on hover to pointer
        minWidth: '30px', // Set a minimum width
        textAlign: 'left', // Center text
        padding: '15px 50px 15px ',

    },
    tableCell_special: {
        paddingLeft: 0,
        borderBottom: "none",


    },
    // ... other styles for different columns
    tableCellEmail: {
        minWidth: '200px', // Adjust based on content
        borderBottom: "none",
        color: "#060D01",

        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%" /* 24px */,
        cursor: "pointer", // Change cursor on hover to pointer
        // ... other shared styles
    },
    tableCellDate: {
        width: '200px', // Adjust based on content
        borderBottom: "none",
        color: "#060D01",

        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%" /* 24px */,
        cursor: "pointer", // Change cursor on hover to pointer
        // ... other shared styles
    },
    tableCellActive: {
        width: '15%', // Adjust based on content
        borderBottom: "none",
        color: "#060D01",

        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%" /* 24px */,
        cursor: "pointer", // Change cursor on hover to pointer
        // ... other shared styles
    },
    tableCellActions: {
        width: '10%', // Adjust based on content
        borderBottom: "none",

        // ... other shared styles
    },


    stickyHeader: {
        position: "sticky !important",
        top: 0,
        zIndex: 1,
        backgroundColor: "white",
    },
    title: {

        fontSize: "30px",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "-0.225px",
        marginBottom: "100px"
    },
    button: {
        borderRadius: "6px",
        background: "#026277",
        color: "white",

        fontSize: "14px",
        textTransform: "None",
        lineHeight: "24px", /* 171.429% */
        height: "40px",
        marginBottom: "10px"

    },
    tableBody: {
        height: '100%',
    },



};
const useStyles = makeStyles({
    // ... other styles

    tableRow: {
        '&:hover': {
            backgroundColor: 'rgba(2, 98, 119, 0.20);', // Set the hover color to pink
        },
    },
    deleteIcon: {
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.4)', // Scale the icon on hover
            transition: 'transform 0.2s ease-in-out', // Smooth transition
            borderRadius: '50%',
        },
    },

    // ... other styles
});

const AdminConfig = () => {
    const { hasSubscription } = useSubscription();
    const navigate = useNavigate();

    const { t } = useTranslation();

    //Modal info
    const [titleOption, setTitle] = useState("");
    const [descriptionOption, setDescription] = useState("");
    const [buttonOption, setButton] = useState("");
    const [placeholderMessage, setPlaceholderMessage] = useState("");
    const [typeModal, setTypeModal] = useState("");
    const [openMenu, setOpenMenu] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState("");


    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [method, setMethod] = useState('');
    const { accessToken, user } = useAuth(); // Use the hook at the top level


    const pageSize = 2; // Number of items per page
    const classes = useStyles();

    const handleRemoveUser = useCallback(() => {
        setOpenMenu(false);
        setModalOpen(true);
        setTitle(t('remove_user_title'));
        setDescription(t('remove_user_description'));
        setButton(t('remove_user_button'));
        setPlaceholderMessage(t('remove_user_placeholder')); // Add this key to your translation files
        setTypeModal("removeuser");
    }, [t, setOpenMenu, setModalOpen, setTitle, setDescription, setButton, setPlaceholderMessage]);

    const handleAddUser = useCallback(() => {
        setOpenMenu(false);
        setModalOpen(true);
        setTitle(t('add_user_title'));
        setDescription(t('add_user_description'));
        setButton(t('add_user_button'));
        setPlaceholderMessage(t('add_user_placeholder')); // Add this key to your translation files
        setTypeModal("adduser");
    }, [t, setOpenMenu, setModalOpen, setTitle, setDescription, setButton, setPlaceholderMessage]);



    const deleteUser = async (email) => {
        try {

            // Replace with your actual delete request to the backend
            const response = await fetch(`${BACKEND_URL}/api_v1/subscription/user/${encodeURIComponent(email)}`, {
                method: 'DELETE',

            })
            await handle.updateUsersList(currentPage, searchQuery, accessToken, setUsers, setTotalPages, setHasMore, pageSize);


        } catch (error) {
            console.error('Error deleting user:', error);
            // Handle errors, e.g., show an error message
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (hasSubscription) {

                    if (accessToken && user) {
                        const requestOptions = {
                            method: "GET",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${accessToken}`, // Include the token in the Authorization header
                            },
                        };
                        const response = await fetch(`${BACKEND_URL}/api_v1/subscription/?page=${currentPage}&search=${searchQuery}&user_id=${user.sub}`,
                            requestOptions);
                        const data = await response.json();
                        if (data.results.length > 0) {
                            setUsers(data.results);
                            setHasMore(data.next !== null);
                            const totalItems = data.count - 1; // Assuming 'count' is the total number of items
                            setTotalPages(Math.ceil(totalItems / pageSize) - 1);
                        }

                    }
                }
                else {
                    navigate("/"); // Use your home page route

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentPage, searchQuery, hasSubscription, accessToken, user]); // Fetch data whenever currentPage or searchQuery changes

    return (

        <div>

            <ThemeProvider theme={customTheme}>

                {showAlert &&
                    <SmallAlert
                        message={message}
                        onClose={() => setShowAlert(false)}

                    />
                }


                {
                    modalOpen && (
                        <ModalParent
                            open={true}
                            title={titleOption}
                            description={descriptionOption}
                            sendButton={buttonOption}
                            placeholdMessage={placeholderMessage}
                            type={typeModal}
                            onClose={() => {
                                setModalOpen(false);
                            }}
                            emailToDelete={selectedEmail}
                            setMessage={setMessage}
                            setShowAlert={setShowAlert}
                            token={accessToken}

                            updateUsersList={async () => {
                                handle.updateUsersList(currentPage, searchQuery, accessToken, setUsers, setTotalPages, setHasMore, pageSize, user['sub'])
                            }} // pass the function as a prop
                        />
                    )
                }
                <Container
                    style={{

                        marginTop: 70,
                        height: "calc(90vh - 70px)",
                        overflowY: "scroll",
                        width: "50%"
                    }}
                >
                    <Typography style={styles.title}>{t('account_admin')}</Typography>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                        <TextField
                            style={{
                                marginRight: '30px', // Adjust margin as needed
                                width: '400px', // Adjust width as needed
                                borderRadius: '6px', // Rounded corners
                                backgroundColor: '#FAFAFA', // Light gray background
                                fontSize: '14px', // Font size
                                border: '1px solid #D0D0D0', // Subtle border color
                                height: '40px', // Adjust the height to match the button
                                display: 'flex',

                                // Additional styling can be added here
                            }}
                            variant="standard" // Changed to standard variant
                            placeholder={t('search_users')}
                            size="small"
                            InputProps={{
                                disableUnderline: true, // Remove underline
                                style: {
                                    height: '100%', // Make the input field take full height
                                    padding: '10px 14px', // Adjust inner padding if needed
                                },
                            }}
                            onChange={(e) => setSearchQuery(e.target.value)}

                        />


                        <Button style={styles.button} onClick={() => {
                            handleAddUser(
                                setModalOpen,
                                setTitle,
                                setDescription,
                                setButton,
                                setPlaceholderMessage,
                                setTypeModal,
                                setOpenMenu,
                                setMethod
                            );
                        }}>{t('add_users')}</Button>
                    </div>
                    <TableContainer component={Paper} style={styles.fileListContainer}>
                        <Table style={styles.tableStyle}>
                            <TableHead style={styles.stickyHeader}>
                                <TableRow>
                                    <TableCell style={styles.header}>{t('email')}</TableCell>
                                    <TableCell style={styles.header}>{t('date_start')}</TableCell>
                                    <TableCell style={styles.header}>{t('active')}</TableCell>
                                    <TableCell style={styles.header}></TableCell>

                                    {/* <TableCell style={{
              ...styles.header, cursor: "pointer", // Change cursor on hover to pointer
            }} onClick={() => {
              updateListDocuments(); // Call the function to update listTranscripts
              // Log "Hello" when the cell is clicked
            }} > <RefreshIcon style={{ marginLeft: "10px" }} /></TableCell>  */}

                                </TableRow>
                            </TableHead>
                            <TableBody style={users && users.length === 1 ? { ...styles.tableBody, alignItems: 'flex-start' } : styles.tableBody}>

                                {users.map((userStatus, index) => (
                                    // Compute fileType and imageSrc outside the JSX



                                    <TableRow key={index} className={classes.tableRow}>
                                        <TableCell style={styles.tableCellEmail}>
                                            {userStatus.user_info.email}
                                        </TableCell>
                                        <TableCell style={styles.tableCellDate}>
                                            {handle.isValidDate(userStatus.user_info.start_date) ?
                                                new Date(userStatus.user_info.start_date).toLocaleDateString('en-GB') :
                                                ''}
                                        </TableCell>
                                        <TableCell style={styles.tableCellActive}>
                                            {!userStatus.is_active ? (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M6 18L18 6M6 6L18 18" stroke="#FF0000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M18.6663 7L9.49967 16.1667L5.33301 12" stroke="#026277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>)}

                                        </TableCell>
                                        <TableCell style={styles.tableCellActions} onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click event
                                            ;
                                            setSelectedEmail(userStatus.user_info.email);

                                            handleRemoveUser(
                                                setModalOpen,
                                                setTitle,
                                                setDescription,
                                                setButton,
                                                setPlaceholderMessage,
                                                setTypeModal,
                                                setOpenMenu,
                                                setMethod,
                                            );

                                            // deleteUser(userStatus.user_info.email)
                                        }}>
                                            {/* SVG code here */}
                                            <svg
                                                className={classes.deleteIcon}

                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
                                                    fill="#111827"
                                                />
                                            </svg>
                                        </TableCell>

                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px' }}>
                        <Button
                            style={{ ...styles.button, marginRight: '20px' }}
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            {t("previous")}
                        </Button>
                        {/* <Typography>
                        {currentPage} de {totalPages}
                    </Typography> */}

                        <Button
                            style={{ ...styles.button, marginLeft: '20px' }}
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === totalPages || !hasMore}
                        >
                            {t("next")}
                        </Button>
                    </div>

                </Container>
            </ThemeProvider >

        </div>

    );
};



export default AdminConfig;
