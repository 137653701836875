import React, { useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ProvaTemplate = () => {
  const [htmlContent, setHtmlContent] = useState(`
    <h1>Sample HTML Content</h1>
    <p>This is a paragraph of text.</p>
    <ul>
      <li>First item</li>
      <li>Second item</li>
      <li>Third item</li>
    </ul>
  `);

  const generatePDF = () => {
    const content = htmlToPdfmake(htmlContent);

    const documentDefinition = {
      content: content,
      watermark: { text: 'TYPED AI', color: '#6BBDBD', opacity: 0.3, bold: true, fontSize: 70 },
    };

    pdfMake.createPdf(documentDefinition).download('document_from_html.pdf');
  };

  return (
    <div>
      <button onClick={generatePDF}>Generate PDF from HTML</button>
    </div>
  );
};

export default ProvaTemplate;
