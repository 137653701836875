import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, TextField, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useNavigate } from 'react-router-dom';
import { fetchUserFolder } from '../../Api/FolderApi';
import { useTranslation } from 'react-i18next';
import DeleteCarpeta from '../../PopUpsAlerts/DeleteDoc/DeleteCarpeta';

const FOLDER_COLORS = [
    '#FF6B6B', // Coral Red
    '#4ECDC4', // Turquoise
    '#45B7D1', // Sky Blue
    '#96CEB4', // Sage Green
    '#FFEEAD', // Soft Yellow
    '#D4A5A5', // Dusty Rose
    '#9B5DE5', // Purple
    '#F15BB5', // Pink
    '#00BBF9', // Bright Blue
    '#00F5D4', // Mint
    '#FEE440', // Yellow
    '#8338EC', // Deep Purple
];

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 20,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
        border: "1px solid #E7E7E7",
        cursor: "pointer",
        overflow: 'hidden',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
        }
    },
    colorStrip: {
        height: '12px',
        width: '12px',
        borderRadius: '4px',
        margin: '16px 0 0 16px',
        backgroundColor: props => props.color || FOLDER_COLORS[props.index % FOLDER_COLORS.length],
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: "10px",
        paddingTop: "20px"
    },
    title: {
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: "Fira Sans"
    },
    iconButton: {
        cursor: 'pointer',
        color: '#E7E7E7',
    },
    content: {
        padding: "10px 10px 0 10px",

    },
    description: {
        color: '#4A5568',
        fontSize: '14px',
        marginBottom: '8px',
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "10px",
        marginBottom: "10px",
        borderRadius: '0 0 10px 10px',
        textAlign: 'right',
    },
    footerText: {
        fontSize: '12px',
        color: '#026277',
        margin: "auto"
    },
}));

const FolderCard = ({ element, accessToken, user, onDeleteFolder, index }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [editableTitle, setEditableTitle] = useState("");
    const { t } = useTranslation();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [currentColor, setCurrentColor] = useState(element.color);


    const handleEditClick = (event) => {
        event.stopPropagation();
        setIsEditing(true);
    };
    useEffect(() => {
        setEditableTitle(element.name)
    }, [element])
    const handleTitleBlur = async (event) => {
        setIsEditing(false);

        if (editableTitle.length > 0) {
            await fetchUserFolder(accessToken, "PUT", element.guid, { "name": editableTitle, "type": "rename" }, user);
        }


    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickMenu = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);

    };

    const handleCloseMenu = (event) => {
        event.stopPropagation()

        setAnchorEl(null);
    };
    const handleDeleteClick = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        await fetchUserFolder(accessToken, "DELETE", element.guid, null, user);
        setDeleteDialogOpen(false);
        onDeleteFolder();
    };

    const handleTitleChange = (event) => {
        setEditableTitle(event.target.value);
    };


    const truncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return text.substring(0, length) + '...';
    };

    const handleColorChange = async (color) => {
        try {
            await fetchUserFolder(accessToken, "PUT", element.guid, {
                "color": color,
                "type": "color"
            }, user);
            setCurrentColor(color);
            setAnchorEl(null);
        } catch (error) {
            console.error('Error updating folder color:', error);
        }
    };

    return (
        <>

            <Card className={classes.card} onClick={() => navigate(`/${element.guid}`)}>

                <Box className={classes.header}>
                    <Box style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                        <Box
                            style={{
                                backgroundColor: currentColor,
                                width: "24px",
                                height: "24px",
                                borderRadius: "4px",
                                marginRight: "10px",
                                cursor: "pointer"
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClickMenu(e);
                            }}
                        />
                        {isEditing ? (
                            <TextField
                                value={editableTitle}
                                onChange={handleTitleChange}
                                variant="standard"
                                className={classes.title}
                                onClick={(event) => event.stopPropagation()}
                                onBlur={handleTitleBlur}
                                autoFocus
                                InputProps={{
                                    classes: {
                                        input: classes.titleInput,
                                    },
                                }}
                            />
                        ) : (
                            <Typography variant="h6" className={classes.title}>
                                {truncateText(editableTitle, 19)}
                            </Typography>
                        )}
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {FOLDER_COLORS.map((color) => (
                            <MenuItem
                                key={color}
                                onClick={() => handleColorChange(color)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px'
                                }}
                            >
                                <Box
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        backgroundColor: color,
                                        borderRadius: '4px',
                                        marginRight: '8px'
                                    }}
                                />
                                {color === currentColor && '✓'}
                            </MenuItem>
                        ))}
                    </Menu>
                    <svg onClick={handleEditClick} className={classes.iconButton} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4 16.0001V20.0001L8 20.0001L18.8686 9.13146L18.8695 9.13061C19.265 8.73516 19.4628 8.53736 19.5369 8.3092C19.6021 8.10835 19.6022 7.89201 19.5369 7.69117C19.4627 7.46284 19.2646 7.26474 18.8686 6.86872L17.1288 5.12892C16.7345 4.7346 16.5369 4.53704 16.3091 4.46301C16.1082 4.39775 15.8919 4.39775 15.691 4.46301C15.463 4.53709 15.2652 4.73488 14.8704 5.12976L14.8686 5.13146L4 16.0001Z" stroke="#6BBDBD" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                    </svg>
                </Box>
                <CardContent className={classes.content}>

                    <Typography className={classes.description}>  {element.document_guids ? element.document_guids.length : 0} {"Apuntes"}
                    </Typography>
                    <Typography className={classes.description}>  {element.exam_guids ? element.exam_guids.length : 0} {"Exámenes"}
                    </Typography>
                </CardContent>
                <Box className={classes.footer}>
                    <Typography className={classes.footerText}>
                        {/* Add any footer text here if needed */}
                    </Typography>
                    <DeleteOutlineIcon
                        onClick={handleDeleteClick}
                        className={classes.iconButton}
                    />
                </Box>

            </Card>
            <DeleteCarpeta
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onDelete={handleDeleteConfirm}
                carpetaName={element.name}
            />
        </>
    );
};

export default FolderCard;
