
import { getCsrfToken } from './TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export async function fetchFolderExams(token, method = "GET", folder_id, user, formData, limit) {
    const user_id = user['sub'];
    let apiUrl = "";
    if (folder_id && method === "GET") {
        apiUrl = `${BACKEND_URL}/api_v1/exams-folder/${folder_id}/?user_id=${user_id}`;
    }
    else {

        apiUrl = `${BACKEND_URL}/api_v1/exams/?user_id=${user_id}&limit=${limit}`;
    }
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (method === "POST" && formData instanceof FormData) {
            requestOptions.body = formData;
        } else {
            if (formData) {

                requestOptions.body = JSON.stringify(formData);
            }
        }

        const response = await fetch(apiUrl, requestOptions);

        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}

export async function fetchExamId(token, method = "GET", exam_id, user, formData) {
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/exam/${exam_id}/?user_id=${user_id}`;
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': 'application/json',

        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if ((method === "POST" || method === "PUT") && formData) {


            requestOptions.body = JSON.stringify(formData);
        }


        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        if (method == "DELETE") {
            return response
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}


export async function fetchExamsbyDocumentId(token, method = "GET", folder_id, document_id, user) {
    const user_id = user['sub'];
    let apiUrl = `${BACKEND_URL}/api_v1/exams/${folder_id}/${document_id}/?user_id=${user_id}`;


    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };



        const response = await fetch(apiUrl, requestOptions);

        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}






export async function fetchAllCorrectAIRemarks(token, method = "POST", exam_id, user) {
    const user_id = user['sub'];
    let apiUrl = `${BACKEND_URL}/api_v1/exam-correction/${exam_id}/?user_id=${user_id}`;


    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };



        const response = await fetch(apiUrl, requestOptions);

        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}


export const fetchRestartExam = async (accessToken, method, exam_id, user) => {
    const user_id = user['sub'];

    const response = await fetch(`${BACKEND_URL}/api_v1/restart-exam/${exam_id}/?user_id=${user_id}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};