import React from 'react';
import { Box, Typography, Container, useMediaQuery } from '@mui/material';
import Lottie from 'lottie-react';
import animationData from '../Images/singing-contract.json';
import { styled } from '@mui/system';

const TemasEmpty = styled('div')({
  backgroundColor: '#f5f5f5',
  border: '2px dashed #ccc',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  color: '#666',
  fontSize: '18px',
  fontWeight: "600",
  fontFamily: 'Inter, sans-serif',
  marginBottom: '20px',
  width: '60%',
  margin: '50px auto', // Center horizontally
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

});

const LoadingScreen = ({ numberOfQuestions, stepValue, messageStep, pages }) => {
  const isSmallScreen = useMediaQuery('(max-width:1200px)');

  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Lottie
          animationData={animationData}
          style={{ width: 200, height: 200, marginTop: "40px" }}
        />
      </Box>

      <Typography variant="h6" align="center" sx={{
        fontFamily: 'Inter, sans-serif',
        fontSize: isSmallScreen ? '14px' : '18px' // Adjust font size based on screen width
      }}>
        Estamos generando aproximadamente <span style={{ color: '#6BBDBD', fontWeight: 'bold' }}>{Math.round(numberOfQuestions * 0.8)} preguntas</span>
      </Typography>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        sx={{
          fontFamily: 'Inter, sans-serif',
          fontSize: isSmallScreen ? '14px' : '18px' // Adjust font size based on screen width
        }}
      >
        para ayudarte a memorizar
      </Typography>
      <TemasEmpty sx={{
        fontFamily: 'Inter, sans-serif',
        fontSize: isSmallScreen ? '14px' : '18px' // Adjust font size based on screen width
      }}>
        Esta acción tardará {pages < 50 ? '3' : pages < 150 ? '5' : '10'} minutos aproximadamente. Te enviaremos un correo cuando esté listo.
      </TemasEmpty>
      {/* <Typography variant="h6" align="center" gutterBottom sx={{ fontFamily: 'Inter, sans-serif' }}>{stepValue || 0}/3  {messageStep}</Typography> */}
    </Container>
  );
};

export default LoadingScreen;
