import React from 'react';
import { Box, Typography, Card, Tooltip, CircularProgress, Divider, Stack } from '@mui/material';

const DocumentStatsCard = ({ document, selected, onClick }) => {
  const getProgress = () => {
    const total = document.status.memorized + document.status.learning +
      document.status.notKnown + document.status.notStarted;
    const percentageMemorized = total > 0 ?
      (document.status.memorized / total) * 100 : 0;
    const result = percentageMemorized.toFixed(0);
    return isNaN(result) || result === 'null' ? '0' : result;
  };

  // Updated stats configuration using SVG images
  const requiredStats = [
    {
      value: document.status.memorized,
      icon: <img
        src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/greenCard.svg"
        alt="Memorized"
        style={{ width: '20px', height: '20px' }}
      />,
      label: 'Memorizadas',
      tooltip: 'Preguntas Memorizadas',
      color: '#4CAF50'  // Green
    },
    {
      value: document.status.learning,
      icon: <img
        src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/yellowCard.svg"
        alt="Learning"
        style={{ width: '20px', height: '20px' }}
      />,
      label: 'Aprendiendo',
      tooltip: 'Preguntas en Aprendizaje',
      color: '#FFC107'  // Yellow
    },
    {
      value: document.status.notKnown,
      icon: <img
        src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/redCar.svg"
        alt="Incorrect"
        style={{ width: '20px', height: '20px' }}
      />,
      label: 'Incorrectas',
      tooltip: 'Preguntas Incorrectas',
      color: '#AA161D'  // Red
    },
    {
      value: document.status.notStarted,
      icon: <img
        src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/grayCard.svg"
        alt="Not Started"
        style={{ width: '20px', height: '20px' }}
      />,
      label: 'Sin empezar',
      tooltip: 'Preguntas Sin Empezar',
      color: '#9E9E9E'  // Gray
    }
  ];

  const optionalStats = [
    {
      value: document.questions?.filter(q => q.isLocked)?.length || 0,
      icon: <img
        src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/lockCard.svg"
        alt="Locked"
        style={{ width: '20px', height: '20px' }}
      />,
      label: 'Bloqueadas',
      tooltip: 'Preguntas Bloqueadas',
      color: '#757575'  // Dark gray for locked
    }
  ];

  return (
    <Card
      onClick={() => onClick(document)}
      sx={{
        p: 2,
        cursor: 'pointer',
        border: selected ? `1px solid #6BBDBD` : `1px solid #E7E7E7`,
        borderRadius: '20px',
        mb: 2,
        backgroundColor: selected ? '#E2F1F1' : 'white',
        position: 'relative',
        minHeight: '120px',
        transition: 'all 0.2s ease',
        '&:hover': {
          borderColor: '#6BBDBD',
          backgroundColor: selected ? '#E2F1F1' : '#F5F9F9'
        }
      }}
    >
      {selected && (
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            width: 20,
            height: 20,
            borderRadius: '50%',
            backgroundColor: '#6BBDBD',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '14px'
          }}
        >
          ✓
        </Box>
      )}
      {/* Header with progress circle and title */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
        <Box sx={{
          position: 'relative',
          width: 50,
          height: 50,
          mr: 2,
          flexShrink: 0
        }}>
          <CircularProgress
            variant="determinate"
            value={getProgress()}
            size={60}
            thickness={4}
            sx={{
              color: '#6BBDBD',
              '& .MuiCircularProgress-circle': {
                strokeLinecap: 'round',
              },
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '45px',
              height: '45px',
              borderRadius: '50%',
              backgroundColor: 'white',
            }}
          >
            <Typography sx={{ color: '#6BBDBD', fontWeight: 'bold', fontSize: '13px' }}>
              {getProgress()}%
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          flexGrow: 1,
          textAlign: 'left',
          minWidth: 0
        }}>
          <Tooltip title={document.title} placement="top">
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '15px',
                fontFamily: 'Fira Sans',
                color: '#333',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                lineHeight: 1.4,
                marginRight: '8px'
              }}
            >
              {document.title}
            </Typography>
          </Tooltip>
        </Box>
      </Box>

      <Divider sx={{ mb: 2 }} />

      {/* Question stats section */}
      <Stack
        direction="row"
        spacing={2}
        flexWrap="wrap"
        useFlexGap
        sx={{
          gap: '16px !important',
          justifyContent: 'center'
        }}
      >
        {/* Always show required stats */}
        {requiredStats.map((stat, index) => (
          <Tooltip key={index} title={stat.tooltip} arrow>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
              minWidth: '45px',
              flexBasis: 'auto'
            }}>
              <Typography sx={{
                fontSize: '14px',
                fontFamily: 'Inter',
                color: stat.color,  // Use the corresponding color
                fontWeight: 500
              }}>
                {stat.value}
              </Typography>
              {stat.icon}
            </Box>
          </Tooltip>
        ))}

        {/* Show optional stats only if value > 0 */}
        {optionalStats.map((stat, index) => (
          stat.value > 0 && (
            <Tooltip key={index} title={stat.tooltip} arrow>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
                minWidth: '60px',
              }}>
                <Typography sx={{
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  color: stat.color,  // Use the corresponding color
                  fontWeight: 500
                }}>
                  {stat.value}
                </Typography>
                {stat.icon}
              </Box>
            </Tooltip>
          )
        ))}
      </Stack>
    </Card>
  );
};

export default DocumentStatsCard;