import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button, IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1300,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: theme.spacing(3),
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '800px', // Fixed width
    maxHeight: '90%',
    overflow: 'auto',
    fontFamily: 'Inter',
  },
  onboardingTitle: {
    fontWeight: 'bold',
    fontSize: '22px',
    fontFamily: 'Inter',
  },
  onboardingList: {
    paddingLeft: 0,
    width: '100%',
    fontFamily: 'Inter',
  },
  onboardingListItem: {
    paddingLeft: 0,
    paddingTop: '4px',
    paddingBottom: '4px',
    fontFamily: 'Inter',
  },
  onboardingListItemMain: {
    paddingBottom: '8px',
  },
  onboardingListItemText: {
    '& .MuiTypography-body1': {
      fontSize: '16px',
      lineHeight: 1.6,
    },
    fontFamily: 'Inter',
  },
  imageBox: {
    width: '100%',
    height: '300px',
    borderRadius: '8px',
    marginBottom: theme.spacing(2),
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '10px',
  },
  backButton: {
    color: '#6BBDBD',
    borderColor: '#6BBDBD',
    fontSize: '15px',
    backgroundColor: 'transparent',
    fontWeight: 600,
    padding: '8px 20px',
    borderRadius: '24px',
    textTransform: 'none',
    cursor: 'pointer !important',
    '&:hover': {
      cursor: 'pointer !important',
    },
  },
  nextButton: {
    backgroundColor: '#6BBDBD',
    color: 'white',
    fontSize: '15px',
    fontWeight: 600,
    padding: '8px 20px',
    borderRadius: '24px',
    textTransform: 'none',
    boxShadow: 'none',
    cursor: 'pointer !important',
    '&:hover': {
      cursor: 'pointer !important',
      backgroundColor: '#5AACAC',
    },
  },
  listItemIcon: {
    minWidth: '30px',
    color: '#6BBDBD',
    marginRight: '10px',
  },
}));

const Improvements = ({ open, onClose }) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);

  const contentStep1 = [
    "Estudia por temas y capítulos, y esfuerzate en los que te cuesten más.",
    "Decenas de preguntas por tema y capítulo para que puedas practicar todo lo que necesites.",
    "Mide tu progreso y mejora día a día.",
  ];

  const contentStep2 = [
    "Hemos añadido la tecnología OCR para que puedas subir PDFs y otros documentos escaneados.",
    "Estudia a partir del BOE o directamente de tus apuntes.",
  ];

  const contentStep3 = [
    "A partir de ahora podrás usar Typed para estudiar cada día, no te quedes sin créditos mensuales.",
    "Aumentamos el número de créditos del plan Pro para que puedas estudiar aún más.",
    "Estudia y prepara cualquier documento."
  ];

  const getStepContent = () => {
    switch (step) {
      case 1:
        return {
          title: "📝 Centenares de preguntas por documento",
          content: contentStep1,
          image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/ListoEstudiar.svg"
        };
      case 2:
        return {
          title: "📖 Sube PDFs y documentos escaneados",
          content: contentStep2,
          image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/DOCS.svg"
        };
      case 3:
        return {
          title: "😊 Quitamos el límite de créditos mensuales",
          content: contentStep3,
          image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/CreditosLimite.svg"// Replace with actual image path
        };
      default:
        return { title: "", content: [], image: '' };
    }
  };

  const { title, content, image } = getStepContent();

  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      onClose();
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    open && (
      <div className={classes.modalOverlay}>
        <Box className={classes.modalContent}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography className={classes.onboardingTitle}>
              ⚡ ¿Qué hemos mejorado?
            </Typography>
            <IconButton aria-label="close" size="small" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Grid container spacing={2} >
            <Grid item xs={7}>
              <List className={classes.onboardingList}>
                <ListItem className={`${classes.onboardingListItem} ${classes.onboardingListItemMain}`}>
                  <ListItemText
                    primary={
                      <Typography variant="body1" style={{ fontWeight: 600, fontSize: '18px' }}>
                        {title}
                      </Typography>
                    }
                    className={classes.onboardingListItemText}
                  />
                </ListItem>
                {content.map((text, index) => (
                  <ListItem key={index} className={classes.onboardingListItem}>
                    <CheckCircleOutlineIcon className={classes.listItemIcon} />
                    <ListItemText
                      primary={text}
                      className={classes.onboardingListItemText}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'top' }}>
              <Box
                className={classes.imageBox}
                style={{ backgroundImage: `url(${image})` }}
              />
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  className={classes.backButton}
                  onClick={handleBack}
                  disabled={step === 1}
                >
                  Atrás
                </Button>
                <Button
                  onClick={step === 3 ? onClose : handleNext}
                  className={classes.nextButton}
                  style={{ cursor: 'pointer !important' }}
                >
                  {step === 3 ? "Ir a Typed" : "Siguiente"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    )
  );
};

export default Improvements;
