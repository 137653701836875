import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box
} from '@mui/material';
import useStylesPopupGettingStarted from './useStylesPopupGettingStarted';
import Confetti from 'react-confetti';


const getDialogTitle = (action) => {
  switch (action) {
    case 'upload':
      return '¡Has subido tu primer documento!🎉';
    case 'test':
      return '¡Has hecho tu primer test!🎉';
    case 'summary':
      return '¡Has generado tu primer resumen! 🎉';
  }
};

const PopupsGettingStarted = ({ open, onClose, action }) => {
  const classes = useStylesPopupGettingStarted();
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (open) {
      setShowConfetti(true);
      const timer = setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [open]);


  return (
    <>
      {open && (
        <Box
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 1400,
            pointerEvents: 'none',
          }}
        >
          {showConfetti && (
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
              numberOfPieces={200}
            />
          )}
        </Box>
      )}
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        className={classes.dialog}
      >
        <Box className={classes.title}>
          <DialogTitle style={{ marginBottom: 0 }}>
            <Typography variant="h6">
              {getDialogTitle(action)}
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent className={classes.content}>
          <Box className={classes.creditsNumber}>
            +30<Typography variant="subtitle1" style={{ fontSize: '14px', color: '#4CAF50', display: 'inline', fontWeight: 'bold' }}>Créditos gratis</Typography>
          </Box>
          <Box className={classes.creditsTextContainer}>
            <Typography variant="body1" >
              Sigue completando acciones para ganar más créditos gratuitos
            </Typography>
          </Box>
        </DialogContent>

        <Button
          variant="contained"
          onClick={onClose}
          style={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            textTransform: 'none',
            width: '60%',
            fontWeight: '600',
            fontFamily: "'Inter', sans-serif",
            borderRadius: '20px',
            padding: '8px 24px',
            fontSize: '16px',
            margin: '16px auto',
            display: 'block',
          }
          }

        >
          ¡Seguir estudiando!
        </Button>

      </Dialog>
    </>
  );
};

export default PopupsGettingStarted;
