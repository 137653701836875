import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, IconButton, Box, Typography, Button } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useStyleCorrection from "./styles/useStyleCorrection";
import TextEditor from "../NewComponents/TextEditor";
import "./styles/ReadComponents.css";
import QuillEditor from "../NewComponents/TextEditor";
// replace with your SVG path
// replace with your SVG path
import { ReactComponent as SendIcon } from './icons/send.svg'; // replace with your SVG path
import { createMemorise, fetchMemorise, updateMemorise } from "./Api/handle";
import PDFViewerComponent from "../Documents/Viewers/PdfViewer";
import IndexList from "./ListIndexComponent";
import CorrectionList from "./CorrectionList";
import Confetti from 'react-confetti';
import MemorizationCard from "./CardScore";
import moment from 'moment-timezone';
import { useCredits } from "../Context/CreditsContext";

function CorrectionComponent({ fileChosen, accessToken, user, initPage, finalPage, setShowUpgrade }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyleCorrection();
    const { updateCredits, credits } = useCredits();

    const [isRecording, setIsRecording] = useState(false);
    const [transcript, setTranscript] = useState("");
    const [recognition, setRecognition] = useState(null);
    const [editorValue, setEditorValue] = useState("");
    const [selected, setSelected] = useState('Feedback');
    const handleButtonClick = (value) => {
        setSelected(value);
    };

    const { memorise_id } = useParams();
    const [fileData, setFileData] = useState(fileChosen);
    const [memoriseData, setMemoriseData] = useState(null);
    const [isCardOpen, setIsCardOpen] = useState(true); // Initially show the card

    const [isConfettiVisible, setIsConfettiVisible] = useState(false);
    // useEffect(() => {
    //     if (memoriseData && memoriseData.grade > 50) {
    //         setIsConfettiVisible(true);
    //         const timer = setTimeout(() => {
    //             setIsConfettiVisible(false);
    //         }, 5000); // 5 seconds
    //         return () => clearTimeout(timer);
    //     }
    // }, [memoriseData]);

    const getCurrentDateTimeInMadrid = () => {
        return moment().tz("Europe/Madrid").format("YYYY-MM-DD HH:mm:ss");
    };

    useEffect(() => {
        if (memorise_id && accessToken) {
            fetchMemorise(accessToken, memorise_id, user)
                .then(data => {
                    setFileData(data.document);
                    setMemoriseData(data);
                    setEditorValue(data.text)
                })
                .catch(error => {
                    console.error("Error fetching file data:", error);
                });
        }
    }, [fileChosen, memorise_id, accessToken, user]);


    const truncateText = (text, length) => {
        if (text) {
            if (text.length <= length) {
                return text;
            }
            return text.substring(0, length) + '...';
        }
    };
    useEffect(() => {
        if ('webkitSpeechRecognition' in window) {
            const speechRecognition = new window.webkitSpeechRecognition();
            speechRecognition.continuous = true;
            speechRecognition.interimResults = true;
            speechRecognition.lang = 'es-ES';

            speechRecognition.onresult = (event) => {
                let interimTranscript = "";
                for (let i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        setEditorValue(prev => prev + event.results[i][0].transcript + ' ');
                    } else {
                        interimTranscript += event.results[i][0].transcript;
                    }
                }
            };

            speechRecognition.onerror = (event) => {
                console.error("Speech recognition error detected: " + event.error);
            };

            setRecognition(speechRecognition);
        } else {
            console.warn("Web Speech API is not supported by this browser.");
        }
    }, []);

    const handleStartRecording = () => {
        if (recognition) {
            recognition.start();
            setIsRecording(true);
        }
    };

    const handleStopRecording = () => {
        if (recognition) {
            recognition.stop();
            setIsRecording(false);
        }
    };

    const handleSendTranscript = () => {
        createMemorise(accessToken, { "text": editorValue, "file_id": fileData.guid, "initPage": initPage, "finalPage": finalPage, "type_post": "correction" }, user);
    };
    // const debounceSaveData = useCallback(debounce((content) => {
    //     saveData(content);
    // }, 2000), []);

    const handleEditorChange = (value) => {
        setEditorValue(value);
        // debounceSaveData(value);
    };
    const renderContent = () => {
        switch (selected) {
            case 'Feedback':
                return (
                    <Grid container className={classes.grid}>
                        {memoriseData && (<CorrectionList items={memoriseData.feedback} />)}
                    </Grid>
                );
            case 'Original':
                return (
                    <Grid container className={classes.grid} style={{ height: "70vh" }}>
                        <Grid item xs={12}>
                            <PDFViewerComponent docInfo={fileData} token={accessToken} />

                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    };
    const getColor = (value) => {
        if (value < 50) {
            return 'red';
        } else if (value < 70) {
            return 'orange';
        } else {
            return '#00C608';
        }
    };
    const handleCardClose = () => {
        setIsCardOpen(false); // Hide the card when the close function is called
        setIsConfettiVisible(true);
        const timer = setTimeout(() => {
            setIsConfettiVisible(false);
        }, 5000); // 5 seconds
        return () => clearTimeout(timer);
    };

    const handleRepeateMemorise = async () => {
        if (credits === -100 || credits >= 20) {
            const dateTime = getCurrentDateTimeInMadrid();

            await updateMemorise(accessToken, memorise_id, { "grade": 0, "feedback": "", "text": "", "date": dateTime }, user)
            updateCredits()
            let newPath = `/memorise/${memorise_id}/read`.trim();

            navigate(newPath)
        }
        else {
            setShowUpgrade(true)
        }
    }
    return (
        <Container style={{ display: "flex", flexDirection: "row", gap: "50px", backgroundColor: "#F8FFFF", maxWidth: "none", height: "100vh", overflowY: "hidden", }}>
            {isCardOpen && memoriseData && <MemorizationCard onClose={handleCardClose} grade={memoriseData.grade} expectedGrade={memoriseData.predictions} />}
            {isConfettiVisible && <Confetti />}

            <Grid container spacing={0} className={classes.leftPart}>
                <Paper elevation={3} className={classes.paperLeft}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
                        <Typography className={classes.title}>{t("correction")} {truncateText(fileData.title, 20)}</Typography>
                        <div><Button className={classes.buttonEmpezar} onClick={() => handleRepeateMemorise()}>{t("repeat")}</Button></div>
                    </div>
                    <div id="editor-container" style={{ height: "70vh" }} className="notranslate">
                        <QuillEditor
                            value={editorValue}
                            onChange={handleEditorChange}
                            theme="snow"

                        />

                    </div>

                </Paper>
            </Grid>
            <Grid
                item
                className={classes.rightPart}
                style={{ overflowY: "scroll", paddingBottom: "30px" }}

            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {t("have_remembered")}
                    </Typography>
                    {memoriseData && (
                        <Typography style={{ fontSize: "30px", fontWeight: "bold", color: getColor(memoriseData.grade) }}>
                            {memoriseData.grade}%
                        </Typography>
                    )}
                </div>


                {/* <Box className={classes.containerSelectors}>
                    <Button
                        className={`${classes.button} ${selected === 'Feedback' ? classes.selectedButton : classes.unselectedButton}`}
                        onClick={() => handleButtonClick('Feedback')}
                    >
                        {t("Feedback")}
                    </Button>
                    <Button
                        className={`${classes.button} ${selected === 'Original' ? classes.selectedButton : classes.unselectedButton}`}
                        onClick={() => handleButtonClick('Original')}
                    >
                        {t("Original")}
                    </Button>

                </Box> */}

                {/* {renderContent()} */}
                <Grid container className={classes.grid}>
                    {memoriseData && (<CorrectionList items={memoriseData.feedback} />)}
                </Grid>
            </Grid>


        </Container>
    );
}

export default CorrectionComponent;
