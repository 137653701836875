import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Button,
  Box
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
  dialog: {
    
    '& .MuiDialog-paper': {
      fontFamily: 'Inter, sans-serif',
      borderRadius: '20px',
      width: '750px !important',
      maxWidth: '900px',
    },
  },
  dialogTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '2rem',
    fontFamily: 'Inter, sans-serif',
  },
  dialogContent: {
    fontFamily: 'Inter, sans-serif',
  },
  iconButton: {
    color: 'white',
    '&:hover': {
      backgroundColor: (props) => props.color,
    },
    fontFamily: 'Inter, sans-serif',
  },
  featureDescription: {
    color: theme.palette.text.secondary,
    fontFamily: 'Inter, sans-serif',
  },
  button: {
    display: 'block',
    margin: '2rem auto 0',
    backgroundColor: '#4db6ac',
    '&:hover': {
      backgroundColor: '#3d9d90',
    },
    textTransform: 'none',
  },
}));


const NewPopupOnboarding = ({ open, onClose }) => {
  const classes = useStyles();

  const handleEmpezarClick = () => {
    onClose(); // Call the onClose function passed as a prop
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
      className={classes.dialog}
      sx={{
        '& .MuiDialog-paper': {
          width: '900px',
          maxWidth: '900px',
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: '18px', fontFamily: 'Inter, sans-serif', paddingBottom: 0 }}>
        ¡Bienvenido a Typed!
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box sx={{ marginBottom: '2.5rem' }}>
          <Typography variant="subtitle1" sx={{
            textAlign: 'left',
            fontFamily: 'Inter, sans-serif',
            fontSize: '14px',
            fontWeight: '400',
            color: '#666666',
            marginBottom: '1rem'
          }}>
            Descubre como te ayudaremos con tu oposición
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {[
            { icon: <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/PencilLine.svg" alt="Pencil" />, title: "Estudia", color: "#ffc107", description: "Responde a centenares de preguntas test" },
            { icon: <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Files.svg" alt="Files" />, title: "Resume", color: "#673ab7", description: "Obtén resúmenes completos en segundos" },
            { icon: <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Chat.svg" alt="Chat" />, title: "Resuelve dudas", color: "#4db6ac", description: "Resuelve tus duda con la ayuda de la IA" },
          ].map((feature, index) => (
            <Grid item xs={12} sm={4} key={index} sx={{ textAlign: 'center' }}>
              <Box
                sx={{
                  backgroundColor: feature.color,
                  width: '48px',
                  height: '48px',
                  margin: '0 auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                }}
              >
                {feature.icon}
              </Box>
              <Typography variant="h6" sx={{ fontFamily: 'Inter, sans-serif', fontSize: '16px', fontWeight: 'bold', marginTop: '0.5rem' }}>
                {feature.title}
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Inter, sans-serif', fontSize: '14px', fontWeight: '400', marginTop: '0.2rem', color: '#666666' }}>
                {feature.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleEmpezarClick}
          sx={{
            display: 'block',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 'bold',
            fontSize: '14px',
            boxShadow: 'none',
            borderRadius: '20px',
            width: '175px',
            margin: '2rem auto 0',
            backgroundColor: '#6BBDBD',
            '&:hover': {
              backgroundColor: '#6BBDBD',
            },
            textTransform: 'none',
          }}
        >
          ¡Empezar!
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default NewPopupOnboarding;
