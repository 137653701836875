
import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Tooltip,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../Auth/Authhandle";
import { FaRegCopy } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css'; // Ensure this at the top to include styles
import { InlineMath, BlockMath } from 'react-katex';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'; // ensure the KaTeX CSS is imported
import "./css/chat.css"; // Import the CSS stylesheet
import { WebSocketContext } from "../WebSocketProvider";
import posthog from 'posthog-js'
import MarkdownRender from "./MarkdownAnswer";
import { useCredits } from "../Context/CreditsContext";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles({
  bounce: {
    "0%, 20%, 50%, 80%, 100%": { transform: "translateY(0)" },
    "40%": { transform: "translateY(-5px)" },
    "60%": { transform: "translateY(-3px)" },
  },
  chatFixed: {
    // position: "sticky",
    // borderRadius: "15px",
    // height: "100%",
    // maxHeight: "100%", // Adjust as needed
    // overflowY: "auto", // To allow internal scrolling of the chat
    // // zIndex: 1000, // Make sure the chat is above other elements
    // // ... other necessary styles
  },
});



const Chat = ({ height, messages, setMessages, accessToken, user, isPdf = false, fullscreen, className, folder_id = "" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const classes = useStyles();

  const params = useParams();
  const { t } = useTranslation();
  // Use folder_id from useParams if the prop is an empty string
  const effectiveFolderId = folder_id || params.folder_id;


  const { updateCredits, credits } = useCredits();


  // State to store the input from the user
  const [input, setInput] = useState('');
  // State to store the responses/messages
  const [responses, setResponses] = useState(() => {
    // Initialize from localStorage if available
    const savedResponses = localStorage.getItem('chat_responses');
    return savedResponses ? JSON.parse(savedResponses) : [];
  });  // Ref to manage the WebSocket connection
  // Ref to scroll to the latest message
  const messagesEndRef = useRef(null);
  // Maximum number of attempts to reconnect
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const maxReconnectAttempts = 5;
  const location = useLocation();

  const pathSegments = location.pathname.split('/').filter(Boolean); // Removes empty strings from array
  const examIndex = pathSegments.indexOf('exam');

  // const examId = pathSegments[examIndexExam + 1];
  let collection, document_id;
  if (!isPdf && examIndex !== -1 && pathSegments.length > examIndex + 1) {
    collection = effectiveFolderId
  } else if (examIndex !== -1 && pathSegments.length > examIndex + 1) {
    // If 'exam' is found and there is an ID following 'exam', use it as the collection
    collection = pathSegments[examIndex + 1];
  } else {
    // If 'exam' is not found in the URL, use the second segment as 'document_id', if available
    document_id = pathSegments.length > 1 ? pathSegments[1] : undefined;
    collection = effectiveFolderId || document_id; // Use 'folder_id' as a default; fall back to 'document_id' if 'folder_id' is not available
  }

  const { wsChat, chatMessageCallback } = useContext(WebSocketContext);


  const ongoingStream = useRef(null);
  useEffect(() => {
    if (chatMessageCallback) {
      setIsLoading(false);
      let sender = chatMessageCallback.name;

      switch (chatMessageCallback.event) {
        case 'on_parser_start':
          ongoingStream.current = { id: chatMessageCallback.run_id, content: '' };
          setResponses(prevResponses => {
            const newResponses = [...prevResponses, { sender, message: '', id: chatMessageCallback.run_id }];
            localStorage.setItem('chat_responses', JSON.stringify(newResponses));
            return newResponses;
          });
          break;
        case 'on_parser_stream':
          if (ongoingStream.current && chatMessageCallback.run_id === ongoingStream.current.id) {
            ongoingStream.current.content += chatMessageCallback.data.chunk;
            setResponses(prevResponses => {
              const newResponses = [...prevResponses];
              const index = newResponses.findIndex(msg => msg.id === chatMessageCallback.run_id);
              if (index !== -1) {
                newResponses[index] = { ...newResponses[index], message: ongoingStream.current.content };
              }
              localStorage.setItem('chat_responses', JSON.stringify(newResponses));

              return newResponses;
            });
          }
          break;
        case 'on_parser_end':
          updateCredits();
          break;
        case 'Error':
          setResponses(prevResponses => {
            const newResponses = prevResponses.map(msg =>
              msg.id === chatMessageCallback.run_id ? { sender: "StrOutputParser", message: "Your credits for messages have finished." } : msg
            );
            localStorage.setItem('chat_responses', JSON.stringify(newResponses));
            return newResponses;
          });
          break;
        default:
          console.log("Received unknown event", chatMessageCallback.event);
      }
    }
  }, [chatMessageCallback]);

  // useEffect(() => {
  //   if (chatMessageCallback) {

  //     let sender = chatMessageCallback.name;
  //     setIsLoading(false); // Stop the typing animation when a message is received
  //     switch (chatMessageCallback.event) {
  //       case 'on_parser_start':
  //         ongoingStream.current = { id: chatMessageCallback.run_id, content: '' };
  //         setResponses(prevResponses => [
  //           ...prevResponses,
  //           { sender, message: '', id: chatMessageCallback.run_id }
  //         ]);
  //         break;
  //       case 'on_parser_stream':
  //         if (ongoingStream.current && chatMessageCallback.run_id === ongoingStream.current.id) {
  //           setResponses(prevResponses =>
  //             prevResponses.map(msg =>
  //               msg.id === chatMessageCallback.run_id ? { ...msg, message: msg.message + chatMessageCallback.data.chunk } : msg
  //             )
  //           );
  //         }
  //         break;

  //       case 'Error':
  //         // Display some UI to inform the user
  //         setResponses(prevResponses =>
  //           prevResponses.map(msg =>
  //             msg.id === chatMessageCallback.run_id ? { sender: "StrOutputParser", message: "Your credits for messages have finished." } : msg
  //           )
  //         );



  //         break;
  //       default:
  //         // Handle any other events
  //         console.log("Received unknown event", chatMessageCallback.event);
  //     };
  //   }
  // }, [chatMessageCallback]);
  // // Function to handle reconnection attempts with exponential backoff
  // const handleReconnect = () => {
  //   if (reconnectAttempts < maxReconnectAttempts) {
  //     let timeout = Math.pow(2, reconnectAttempts) * 1000; // Exponential backoff
  //     setTimeout(() => {
  //       setupWebSocket(); // Attempt to reconnect
  //     }, timeout);
  //   } else {
  //     console.log("Max reconnect attempts reached, not attempting further reconnects.");
  //   }
  // };

  // Effect hook to setup and cleanup the WebSocket connection
  useEffect(() => {
    const defaultProfessorMessage = {
      sender: "StrOutputParser",
      message: t('initial_bot_message'),
      id: 'default-msg',
    };
    if (responses.length === 0) {
      setResponses([defaultProfessorMessage]); // This sets the default message as the first message in the chat
      localStorage.setItem('chat_responses', JSON.stringify([defaultProfessorMessage]));
    }
    // setupWebSocket(); // Setup WebSocket on component mount

    // return () => {
    //   if (ws.current.readyState === WebSocket.OPEN) {
    //     ws.current.close(); // Close WebSocket on component unmount
    //   }
    // };
  }, []);

  // Effect hook to auto-scroll to the latest message
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [responses]);

  // Function to render each message
  // const renderMessage = (response, index) => (
  //   <div key={index} className={`message ${response.sender}`}>
  //     <strong>{response.sender}</strong> <p>{response.message}</p>
  //   </div>
  // );


  const TypingAnimation = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "10px",
        }}
      >
        <Typography
          style={{
            padding: "6px",
            maxWidth: "60%",
            borderRadius: "15px",
            backgroundColor: "#026277",
            color: "white",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="dot dot1"></span>
          <span className="dot dot2"></span>
          <span className="dot dot3"></span>
        </Typography>
      </div>
    );
  };

  const renderMessage = (response, index) => {


    // Decide the image based on sender
    let imageSrc;
    if (response.sender === "You") {
      imageSrc = user.picture;
    } else if (response.sender === "StrOutputParser") { // Assuming "Profesor" is the label used
      imageSrc = require("./img/teacher.png");
    } else {
      imageSrc = require("./img/teacher.png"); // Default image if needed
    }

    return (
      <>
        <div

        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end", // Aligns items along the bottom
              marginBottom: "5px", // Spacing between the name and the message
              marginTop: "15px"
            }}
          >
            <img src={imageSrc} alt={response.sender} style={{
              width: response.sender === "You" ? "22px" : "25px", // Smaller size for the specific link
              height: response.sender === "You" ? "22px" : "30px", // Smaller size for the specific link
              borderRadius: response.sender === "You" ? "50%" : "0%",
              marginRight: "10px",
            }} />
            <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>{response.sender === 'StrOutputParser' ? t("professor") : user.given_name}</Typography>

          </div>
        </div>
        <div style={{
          marginLeft: "36px",
          fontWeight: 500
          // marginBottom: "45px"
        }}>
          <MarkdownRender response={response} />



        </div>
      </>
    );
  };
  // Handler for input changes
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };


  // Handler for form submission
  const handleSubmit = (e) => {

    if (e) e.preventDefault();

    // Trim the input and check if it's not empty
    if (input.trim()) {
      setIsLoading(true)
      const userMessage = { sender: "You", message: input };
      setResponses(prevResponses => [...prevResponses, userMessage]);
      posthog.capture('send_message_chat');

      wsChat.current.send(JSON.stringify({ message: input, folder_id: collection, document_id: document_id, user_id: user.sub })); // Send message through WebSocket
      setInput(''); // Clear input field after sending
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) { // Prevent sending the message when Shift + Enter keys are pressed together
      e.preventDefault(); // Prevent the default action to avoid form submission if inside a form
      handleSubmit(e);
    }
  };

  return (
    <>
      < Card
        style={{
          maxWidth: "100%",
          height: height || "400px",
          display: "flex",
          borderRadius: "15px",
          // position: "sticky"
          // border: "1px solid",
          // border: "2px solid #DFE3E7",
        }
        }
        className={className} // Using the className prop for styling

      >
        <CardContent
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <div style={{ padding: "15px", borderBottom: "1px solid #DFE3E7", display: "flex", flexDirection: "row" }}>
            <img src={require("./img/teacher.png")}
              alt="Teacher"
              style={{
                marginRight: "10px", // Add some space between the image and the text
                verticalAlign: "middle", // Aligns the image vertically if flex is not working as expected
                height: '30px', // Set the image height to match your line-height if needed

              }}
            />
            <Typography
              style={{
                display: 'flex', // Set to flex to align image and text
                alignItems: 'end', // Align items vertically
                marginLeft: "5px",
                color: "#026277", // or use the variable --slate-900 if it's defined elsewhere
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "36px",
                letterSpacing: "-0.165px",
                verticalAlign: "middle", // Aligns the image vertically if flex is not working as expected

              }}
            >

              {t("chat_title")}
            </Typography>
          </div>
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              height: "calc(400px - 70px)",
              paddingTop: "10px",
              padding: "16px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            {responses.map((response, index) => renderMessage(response, index))}
            <div ref={messagesEndRef} /> {/* Invisible element to help scroll into view */}

            {isLoading && <TypingAnimation />}
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: 10,
              marginRight: 10,
              marginLeft: 10,
              borderRadius: "8px",
              border: "1px solid #D5D4DC",
              background: "#F5F8FF",
            }}
          >
            <TextField
              fullWidth
              value={input}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: { padding: "5px", fontSize: "12px" }, // Apply styles here
              }}
              multiline
              style={{ padding: "10px", fontSize: "12px !important" }}
              placeholder="Haz preguntas sobre tu documento"
              onChange={handleInputChange}
              onKeyPress={handleKeyPress} // Use the handleKeyPress function
            />
            <Button
              color="primary"
              onClick={handleSubmit}
              endIcon={<SendIcon />}
              style={{ marginLeft: "10px" }}
            ></Button>
          </div>
        </CardContent>
      </Card >
    </>
  )

};

export default Chat;