import React, { createContext, useRef, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const WebSocketContext = createContext();

const WebSocketProvider = ({ children, url, urlchat }) => {
    const ws = useRef(null);
    const wsChat = useRef(null);
    const { getAccessTokenSilently, user } = useAuth0();
    const [isConnecting, setIsConnecting] = useState(false);
    const [messageCallback, setMessageCallback] = useState(null);
    const [chatMessageCallback, setChatMessageCallback] = useState(null);
    const reconnectTimeoutRef = useRef(null);

    const connectWebSocket = async () => {
        if (isConnecting || !user?.sub) return;
        setIsConnecting(true);

        try {
            const accessToken = await getAccessTokenSilently();
            const websocketUrl = `${url}?token=${accessToken}`;
            const websocketUrlChat = `${urlchat}?token=${accessToken}&user_id=${user?.sub}`;

            // Close existing connections first
            if (ws.current && ws.current.readyState !== WebSocket.CLOSED) {
                ws.current.close(1000);
            }
            if (wsChat.current && wsChat.current.readyState !== WebSocket.CLOSED) {
                wsChat.current.close(1000);
            }

            // Create new connections
            ws.current = new WebSocket(websocketUrl);
            ws.current.onmessage = event => {
                const message = JSON.parse(event.data);
                setMessageCallback(message);
            };

            ws.current.onerror = error => {
                console.error("WebSocket Error:", error);
            };

            ws.current.onclose = e => {
                if (e.code !== 1000 && !reconnectTimeoutRef.current) {
                    reconnectTimeoutRef.current = setTimeout(() => {
                        reconnectTimeoutRef.current = null;
                        connectWebSocket();
                    }, 5000);
                }
            };

            wsChat.current = new WebSocket(websocketUrlChat);
            wsChat.current.onmessage = event => {
                const message = JSON.parse(event.data);
                if (message.event === "Error") {
                    console.error("Chat error: No credits remaining");
                }
                setChatMessageCallback(message);
            };

            wsChat.current.onerror = error => {
                console.error("Chat WebSocket Error:", error);
            };

            wsChat.current.onclose = e => {
                if (e.code !== 1000 && !reconnectTimeoutRef.current) {
                    reconnectTimeoutRef.current = setTimeout(() => {
                        reconnectTimeoutRef.current = null;
                        connectWebSocket();
                    }, 5000);
                }
            };
        } catch (error) {
            console.error('Connection error:', error);
        } finally {
            setIsConnecting(false);
        }
    };

    useEffect(() => {
        if (user?.sub) {
            connectWebSocket();
        }

        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (ws.current) {
                ws.current.close(1000);
            }
            if (wsChat.current) {
                wsChat.current.close(1000);
            }
        };
    }, [user?.sub]);

    return (
        <WebSocketContext.Provider value={{ ws, wsChat, messageCallback, setMessageCallback, chatMessageCallback }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export { WebSocketProvider, WebSocketContext };

