import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth } from "../Auth/Authhandle";

const PointsContext = createContext();

export const usePoints = () => useContext(PointsContext);

export const PointsProvider = ({ children }) => {
    const [pointsData, setPointsData] = useState([]);
    const [allPointsData, setAllPointsData] = useState(null);
    const { accessToken, user } = useAuth();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const fetchAllPointsData = useCallback(async () => {
        if (accessToken && user) {
            try {
                const response = await fetch(`${BACKEND_URL}/api_v1/exams/?user_id=${user['sub']}&objective=true`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const days = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dm"];
                    const resultData = [];
                    const today = new Date();
                    const currentDayOfWeek = today.getDay();
                    const mondayOffset = currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek;

                    const startDate = new Date(today);
                    startDate.setDate(today.getDate() + mondayOffset);
                    const answeredExams = Object.values(data).filter(exam => exam.exam_answered_date);

                    for (let i = 0; i < 7; i++) {
                        const currentDate = new Date(startDate);
                        currentDate.setDate(startDate.getDate() + i);

                        const points = currentDate <= today
                            ? Object.values(answeredExams)
                                .filter(exam => new Date(exam.exam_answered_date).toDateString() === currentDate.toDateString())
                                .reduce((sum, exam) => sum + exam.num_questions, 0)
                            : 0;

                        const isToday = currentDate.toDateString() === today.toDateString();
                        const dayIndex = (currentDate.getDay() + 6) % 7;
                        const dayName = days[dayIndex];

                        resultData.push({
                            date: dayName,
                            points: points,
                            isToday: isToday
                        });
                    }
                    setAllPointsData(resultData);
                } else {
                    console.error('Failed to fetch exams data');
                }
            } catch (error) {
                console.error('Error fetching exams data:', error);
            }
        }
    }, [accessToken, user, BACKEND_URL]);

    return (
        <PointsContext.Provider value={{ allPointsData, fetchAllPointsData }}>
            {children}
        </PointsContext.Provider>
    );
};