import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import MermaidChart from './MermaidChart';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';

const PopUpMermaid = ({ open, handleClose, mermaidDefinition }) => {
  const handleDownload = () => {
    const element = document.querySelector('.mermaid svg');
    if (element) {
      // Get the SVG content
      const svgData = new XMLSerializer().serializeToString(element);
      
      // Create a Blob with the SVG content
      const svgBlob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
      
      // Create a download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(svgBlob);
      link.download = 'mermaid_diagram.svg';
      
      // Trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Mermaid SVG element not found');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '20px',
          width: '90vw',
          maxWidth: 'none',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" align="center" gutterBottom sx={{ paddingTop: '80px', fontWeight: 'bold', fontFamily: 'Inter' }}>
          Mermaid Diagram in React
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '70vh',
          width: '100%',
          overflow: 'hidden'
        }}>
          <MermaidChart />
        </Box>
        <Box sx={{ position: 'absolute', bottom: 20, right: 16 }}>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleDownload}
            sx={{
              textTransform: 'none',
              borderRadius: '20px',
              backgroundColor: '#6BBDBD',
              color: 'white',
              fontFamily: 'Inter, sans-serif',
              '&:hover': {
                backgroundColor: '#6BBDBD',
              },
            }}
          >
            Descargar esquema
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PopUpMermaid;
