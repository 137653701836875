import React, { useState, useEffect, useContext } from 'react';
import { Button, TextField, IconButton, Typography, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { updateSchema } from '../Api/SchemasApi';
import SearchIcon from '@mui/icons-material/Search';
import { WebSocketContext } from '../WebSocketProvider';
import './Styles/style.css';
import NeedCredits from '../NoCreditsModal/NeedCredits';
import { useCredits } from '../Context/CreditsContext';
import { createSchema } from '../Api/SchemasApi';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SchemasList = ({
    documentId,
    accessToken,
    user,
    isCreatingSchemas,
    setIsCreatingSchemas,
    schemas,
    setSchemas,
    isLoading,
    error,
    summary,
    setShowUpgrade
}) => {
    const navigate = useNavigate();
    const [editingId, setEditingId] = useState(null);
    const [editingName, setEditingName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const { messageCallback } = useContext(WebSocketContext);
    const [executingSchemas, setExecutingSchemas] = useState(false);
    const [showNeedCredits, setShowNeedCredits] = useState(false);
    const { updateCredits, credits } = useCredits();

    const handleCloseNeedCredits = () => {
        setShowNeedCredits(false);
    };

    useEffect(() => {
        if (messageCallback) {
            switch (messageCallback.type) {
                case "schema":
                    setIsCreatingSchemas(false);
                    setExecutingSchemas(messageCallback.executing || false);

                    setSchemas(prevSchemas => {
                        const exists = prevSchemas.some(schema => schema.guid === messageCallback.schema.guid);
                        if (!exists) {
                            return [...prevSchemas, {
                                guid: messageCallback.schema.guid,
                                name: messageCallback.schema.name,
                                description: messageCallback.schema.description,
                                is_locked: false
                            }];
                        }
                        return prevSchemas;
                    });
                    break;
            }
        }
    }, [messageCallback]);

    const handleUnlock = async (schemaId) => {
        try {
            if (credits === -100 || credits < 20) {
                setShowNeedCredits(true);
                return;
            }
            const schemaData = {
                is_locked: false
            };

            await updateSchema(accessToken, user, schemaId, schemaData);

            // Update the local state to reflect the change
            setSchemas(schemas.map(schema =>
                schema.guid === schemaId ? { ...schema, is_locked: false } : schema
            ));
            updateCredits();
            navigate(`/schema/${schemaId}`);
        } catch (error) {
            console.error('Error unlocking schema:', error);

        }
    };

    const handleView = (schemaId) => {
        navigate(`/schema/${schemaId}`);
    };

    const handleEditClick = (schema) => {
        setEditingId(schema.guid);
        setEditingName(schema.name);
    };

    const handleEditCancel = () => {
        setEditingId(null);
        setEditingName('');
    };

    const handleEditSave = async (schemaId) => {
        try {
            const schemaData = {
                name: editingName
            };

            await updateSchema(accessToken, user, schemaId, schemaData);

            // Update the local state to reflect the change
            setSchemas(schemas.map(schema =>
                schema.guid === schemaId ? { ...schema, name: editingName } : schema
            ));
            setEditingId(null);
        } catch (error) {
            console.error('Error updating schema name:', error);

        }
    };
    const handleGenerateSchemas = () => {
        if (credits != -100 && credits < summary.length * 10) {
            setShowUpgrade(true)
            return;
        }
        setIsCreatingSchemas(true);
        const payload = {

            "document_id": documentId,
        };
        createSchema(accessToken, user, documentId, payload);
    }

    const filteredSchemas = schemas.filter(schema =>
        schema.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (isCreatingSchemas) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
                padding: '32px'
            }}>
                <CircularProgress style={{ color: '#6BBDBD' }} />
                <Typography>Generaremos esquemas sobre tu resumen</Typography>
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (schemas.length === 0 && !isLoading) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
                padding: '32px'
            }}>
                <Typography>No se han generado ningún esquema.</Typography>
                {!summary && (

                    <Typography>Debes generar un resumen para poder generar esquemas.</Typography>
                )}
                <Button
                    variant="contained"
                    style={{
                        textTransform: 'none',
                        fontSize: '14px',
                        fontWeight: '600',
                        fontFamily: "'Inter', sans-serif",
                        color: 'white',
                        backgroundColor: !summary ? '#E0E0E0' : '#6BBDBD',
                        padding: '4px 16px',
                        borderRadius: '24px',
                        boxShadow: 'none',
                        border: !summary ? '1px solid #E0E0E0' : '1px solid #6BBDBD',
                    }}
                    onClick={() => handleGenerateSchemas()}
                    disabled={!summary}
                >
                    Generar esquemas
                </Button>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: '16px',
                width: '100%',
            }}>
                {[1, 2, 3].map((item) => (
                    <div key={item} style={{
                        padding: '20px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '8px',
                        animation: 'pulse 1.5s infinite',
                    }}>
                        <div style={{
                            height: '24px',
                            width: '200px',
                            backgroundColor: '#e0e0e0',
                            marginBottom: '8px',
                            borderRadius: '4px',
                        }} />
                        <div style={{
                            height: '16px',
                            width: '300px',
                            backgroundColor: '#e0e0e0',
                            borderRadius: '4px',
                        }} />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    padding: '16px',
                    width: '100%',
                }}>
                    <TextField
                        sx={{ width: '480px' }}
                        variant="outlined"
                        placeholder="Buscar esquemas..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size="small"
                        style={{ marginBottom: '16px', marginLeft: 'auto', marginRight: 'auto' }}
                        InputProps={{
                            startAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />,
                        }}
                    />
                    {executingSchemas && (
                        <Typography
                            className="loading-text"
                            style={{
                                textAlign: 'center',
                                color: '#666',
                                marginBottom: '8px'
                            }}
                        >
                            Generando esquemas
                        </Typography>
                    )}
                    <div className="schemas-list custom-scrollbar" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        maxHeight: 'calc(-100px + 75vh)',  // Adjust height to account for search bar
                        overflowY: 'auto',
                        marginBottom: '26px'
                    }}>
                        {filteredSchemas.map((schema, index) => (
                            <React.Fragment key={schema.guid}>
                                <div className="schema-item" style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '12px 0'
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: 1 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 1 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Typography style={{ fontWeight: 'bold', fontFamily: "'Fira Sans', sans-serif" }}>{schema.name}</Typography>
                                            </div>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{
                                                    fontSize: '14px',
                                                    color: '#666',
                                                    width: '100%',  // Take full width
                                                    wordWrap: 'break-word',  // Allow word wrapping
                                                    whiteSpace: 'pre-wrap'  // Preserve line breaks and wrapping
                                                }}
                                            >
                                                {schema.description}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Button
                                        variant="contained"
                                        onClick={() => schema.is_locked ? handleUnlock(schema.guid) : handleView(schema.guid)}
                                        style={{
                                            textTransform: 'none',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            fontFamily: "'Inter', sans-serif",
                                            color: !schema.is_locked ? 'white' : '#6BBDBD',
                                            backgroundColor: !schema.is_locked ? '#6BBDBD' : 'white',
                                            padding: '4px 16px',
                                            borderRadius: '24px',
                                            boxShadow: 'none',
                                            border: '1px solid #6BBDBD',
                                            marginRight: '16px'
                                        }}
                                    >
                                        {schema.is_locked ? 'Desbloquear' : 'Ver'}
                                    </Button>
                                </div>
                                {index < filteredSchemas.length - 1 && (
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#E0E0E0',
                                        width: '100%'
                                    }} />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SchemasList;
