import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import useStylePremium from './useStylePremium';

const Premium = ({ onClose, titleText }) => {
  const classes = useStylePremium();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSubscribe = () => {
    onClose(); // Close the popup
    navigate('/plans'); // Navigate to the PlanSelection screen
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Box
      className={classes.popupOverlay}
      onClick={handleOverlayClick}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300
      }}
    >
      <Box
        className={classes.popupContainer}
        onClick={(e) => e.stopPropagation()} // Prevent clicks inside the popup from closing it
        sx={{
          backgroundColor: 'white',
          padding: { xs: '16px', sm: '24px' },
          borderRadius: '35px',
          maxWidth: { xs: '90%', sm: '450px' },
          margin: 'auto',
          overflow: 'auto'
        }}
      >
        <Typography
          className={classes.title}
          sx={{
            fontSize: '1.2rem',
            fontWeight: '900',
            fontFamily: "'Inter', sans-serif"
          }}
        >
          {titleText === 'documents' && 'Sube más documentos con Premium✨'}
          {titleText === 'questions' && 'Desbloquea todas las preguntas con Premium✨'}
          {titleText === 'topics' && 'Haz tests personalizados con Premium✨'}
          {titleText === 'tests' && 'Test sin límites de preguntas con Premium✨'}
          {titleText === 'paginas' && 'Sube documentos más largos con Premium✨'}
          {titleText === 'descargar' && 'Descarga todos tus resúmenes con Premium✨'}
          {titleText === 'esquemas' && 'Descarga tus esquemas con Premium✨'}
        </Typography>
        <Typography className={classes.price}>
          Desde <Box component="span" sx={{ fontSize: '0.1rem', fontWeight: 'bold' }}>5,99€</Box>/mes
        </Typography>
        <Box className={classes.highlight}>
          <Typography fontWeight="bold" sx={{ fontSize: '0.8rem' }}>
            80% más asequible que cualquier alternativa
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            fontSize: '0.8rem',
            fontWeight: 'bold',
            marginLeft: '12px',
            textAlign: 'left',
            fontFamily: 'Inter, sans-serif'
          }}
        >
          ¿Porqué deberías suscribirte a Typed?
        </Typography>
        <List className={classes.featureList}>
          {[
            'Todas las preguntas desbloqueadas',
            'Estudia por temas',
            'Sube tantos documentos como quieras',
            'Sube hasta 200 páginas',
            'Sin límite de preguntas por examen',
            'Sin límite de créditos diario',
            'Descarga todos tus resúmenes',
            'Descarga tus esquemas',
            'Resuelve tus dudas 24/7 con Chat AI'
          ].map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                <svg width="18" height="18" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle' }}>
                  <path d="M11.8119 0C5.53644 0 0.449219 4.47715 0.449219 10C0.449219 15.5228 5.53644 20 11.8119 20C18.0873 20 23.1745 15.5228 23.1745 10C23.1672 4.47982 18.0842 0.00642897 11.8119 0Z" fill="#E2F1F1" />
                  <path d="M18.37 6.83337L11.8886 14.5742C11.734 14.7547 11.5035 14.873 11.2489 14.9024C10.9943 14.9319 10.7369 14.8701 10.5346 14.7309L5.90618 11.4742C5.49775 11.1866 5.43162 10.662 5.75846 10.3025C6.08531 9.94309 6.68136 9.88489 7.08978 10.1725L10.9493 12.89L16.8456 5.84754C17.0389 5.5922 17.376 5.45103 17.7231 5.48007C18.0702 5.50912 18.3711 5.70368 18.5063 5.98652C18.6415 6.26935 18.5892 6.59469 18.37 6.83337Z" fill="#6BBDBD" />
                </svg>
              </ListItemIcon>
              <ListItemText
                primary={text}
                primaryTypographyProps={{ sx: { fontSize: '0.8rem' } }}
              />
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          className={classes.subscribeButton}
          onClick={handleSubscribe}

        >
          ¡Suscribirse!
        </Button>
      </Box>
    </Box>

  );
};

export default Premium;
