import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const SubscriptionPopup = ({ open, onClose, onUpload }) => {
  const navigate = useNavigate();

  const handleSubscribe = () => {
    onClose();
    navigate('/plans');
  };

  const handleClose = () => {
    onClose();
    onUpload?.();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
          padding: '16px',
        }
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          sx={{
            color: '#2D2D2D',
            fontWeight: 600,
            fontSize: '20px',
            fontFamily: 'Fira Sans'
          }}
        >
          Documento demasiado largo
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          sx={{
            color: '#666666',
            fontSize: '16px',
            lineHeight: 1.5,
            fontFamily: 'Inter',
          }}
        >
          Hemos recortado el documento a las 40 páginas iniciales.
          Si quieres subir documentos más largos es necesaria una suscripción Premium.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            color: '#666666',
            fontFamily: 'Fira Sans',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Continuar
        </Button>
        <Button
          variant="contained"
          onClick={handleSubscribe}
          sx={{
            backgroundColor: '#6BBDBD',
            textTransform: 'none',
            borderRadius: '20px',
            fontFamily: 'Fira Sans',
            boxShadow: 'none',
            fontWeight: "bold",
            color: 'white',
            '&:hover': {
              backgroundColor: '#5AA9A9'
            }
          }}
        >
          Suscribirse
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionPopup; 