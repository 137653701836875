import { makeStyles } from "@material-ui/core/styles";

const useStylesReadFile = makeStyles((theme) => ({

    gridContainer: {
        flexWrap: "nowrap",
        display: "flex",
        overflowY: "hidden",
        // Other styles...
        justifyContent: "flex-start", // Add this line
        maxWidth: "none", // Add this line
        margin: "0px", // Add this line
    },

    leftPart: {
        //     maxWidth: "initial",
        //     flexBasis: '50vw', // Add this line
        border: "1px solid #6BBDBD",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px", // Add this line
        borderBottomRightRadius: "0px",
        borderBottom: "0px",
        //     marginLeft: "50px",
        //     backgroundColor: "white"
        display: "flex",
        flexDirection: "column",
        flex: 2, // This will make the paper fill the height of its container
        minHeight: '100%',
        height: "auto", overflowY: 'hidden',
        backgroundColor: "white"



    },
    paperLeft: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        flexGrow: 1,
        height: "100%",  // Ensure it takes the full height of its parent
        overflowY: "auto",  // Enable vertical scrolling
        backgroundColor: "white",
        margin: "20px 50px 3px 50px",

    },



    title: {
        color: "black",
        textAlign: "left",
        fontSize: "25px",
        fontWeight: "bold"
    },


    buttonEmpezar: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "14px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "80px",
        fontWeight: "bold",
        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",


        }

    },

    rightPart: {
        // height: "calc(100vh - 40px)", /* Full viewport height */
        // borderLeft: "1px solid black",
        boxShadow: "none !important",
        marginBottom: "40px"
        , flexBasis: '18vw', // Sets the base size of the left part
        [theme.breakpoints.up('2000')]: {
            flexBasis: '18vw',

        },
        [theme.breakpoints.down('1500')]: {
            flexBasis: '18vw', // Sets the base size of the left part

        },
    },


}));

export default useStylesReadFile;