// api.js
import { getCsrfToken } from './TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function fetchUserInfo(token, method = "GET", requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/user_info/?user_id=${user_id}`; // Update with your actual API endpoint
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (method === "PUT" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}


export async function fetchUserSettings(token, method = "GET", requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/user_settings/?user_id=${user_id}`; // Update with your actual API endpoint

    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };


        if (method === "PUT" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}




export async function postInvite(token, requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['sub']
    const apiUrl = `${BACKEND_URL}/api_v1/invite/?user_id=${user_id}`; // Update with your actual API endpoint

    try {
        const csrfToken = getCsrfToken();// Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: "POST",
            headers: headers,
        };

        if (requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return response
    } catch (error) {
        console.error('Error sending invitation', error);
        throw error;
    }
}

export async function fetchListUsers(token, page = 1, method = "GET", requestBody = null, user) {
    const user_id = user['sub'];
    // Include 'page' in the API request URL
    const apiUrl = `${BACKEND_URL}/api_v1/subscription/?user_id=${user_id}&page=${page}`;

    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (method === "POST" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }

        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}


export async function fetchUserSubscription(token, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/subscription/user/?user_id=${user_id}`; // Update with your actual API endpoint

    try {
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
        };


        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}






export async function fetchUserQuestionsOnboarding(token, method = "GET", requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/user_questions/?user_id=${user_id}`; // Update with your actual API endpoint
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (method === "POST" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}
