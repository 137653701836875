import { getCsrfToken } from './TokenApi';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function fetchQuestions(token, documentId, user) {
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/all_questions_document/${documentId}/?user_id=${user_id}`;

    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching questions:', error);
        throw error;
    }
}
export async function deleteTopic(token, documentId, topicId, user) {
    const user_id = user['sub'];
    const apiUrl = `${BACKEND_URL}/api_v1/documents/${documentId}/topics/${topicId}/?user_id=${user_id}`;

    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-CSRFToken': csrfToken,
        };

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return true;
    } catch (error) {
        console.error('Error deleting topic:', error);
        throw error;
    }
}

