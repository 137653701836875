import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GetAppIcon from '@mui/icons-material/GetApp';
import PopUpMermaid from './PopUpMermaid';
import FolderIcon from '@mui/icons-material/Folder';
import { useNavigate } from 'react-router-dom';

const PopUpSeeDiagrams = ({ open, onClose, diagrams, title }) => {
  const navigate = useNavigate();
  const [selectedDiagram, setSelectedDiagram] = useState(null);

  const handleViewDiagram = (diagram) => {
    // Open the map view with the diagram id in a new tab
    navigate(`/schema/${diagram.id}`, '_blank');
    // Don't close the current dialog
  };

  const handleCloseDiagramView = () => {
    setSelectedDiagram(null);
  };

  const handleDownload = (diagram) => {
    // Implement download functionality here
    console.log('Downloading diagram:', diagram.name);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth PaperProps={{
        style: {
          borderRadius: '20px',
          height: "90%",
          display: 'flex',
          flexDirection: 'column',
        }
      }}>
        <DialogTitle style={{ fontFamily: 'Inter', fontSize: '24px', fontWeight: 'bold', padding: '24px' }}>
          Esquemas de {title}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom style={{ fontFamily: 'Inter', marginBottom: '24px', fontSize: '16px' }}>
            Aquí podrás encontrar los esquemas que has generado hasta la fecha.
          </Typography>

          {diagrams.length > 0 ? (
            <Grid container spacing={3}>
              {diagrams.map((diagram, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card elevation={0} style={{ border: '1px solid #E0E0E0', borderRadius: '10px', height: '300px' }}>
                    <CardContent>
                      <Box
                        sx={{
                          width: '100%',
                          height: '180px',
                          backgroundColor: '#f5f5f5',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '10px'
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          Preview
                        </Typography>
                      </Box>
                      <Typography variant="body2" style={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold', marginTop: '20px' }}>
                        {diagram.name}
                      </Typography>
                      <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                        <Box display="flex" alignItems="center">
                          <FolderIcon style={{ fontSize: 16, marginRight: 4, color: '#a0a0a0' }} />
                          <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Inter' }}>
                            {diagram.category || 'Derecho'}
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton size="small" onClick={() => handleViewDiagram(diagram)} style={{ color: '#6BBDBD' }}>
                            <VisibilityIcon />
                            <Typography variant="body2" style={{ marginLeft: "5px", fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold' }}>Ver</Typography>
                          </IconButton>
                          <IconButton size="small" onClick={() => handleDownload(diagram)} style={{ color: '#6BBDBD' }}>
                            <GetAppIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="50vh">
              <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" width="200" height="200" />

              <Typography variant="body1" style={{ fontFamily: 'Inter', marginTop: '24px', fontSize: '18px', textAlign: 'center' }}>
                Aún no has generado ningún esquema
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions style={{ padding: '24px' }}>
          <Button onClick={onClose} color="primary" variant="contained" style={{ fontFamily: 'Inter', textTransform: 'none', backgroundColor: '#6BBDBD', color: 'white', fontSize: '16px', fontWeight: '600', borderRadius: '20px', padding: '10px 24px' }}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {selectedDiagram && (
        <PopUpMermaid
          open={!!selectedDiagram}
          handleClose={handleCloseDiagramView}
          mermaidDefinition={selectedDiagram.text}
        />
      )}
    </>
  );
};

export default PopUpSeeDiagrams;
