import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Container,
  Paper,
  Divider,
  Grid
} from "@material-ui/core";

import { fetchUserFolders } from '../Api/FolderApi'; // Import the fetchUserInfo function
import { useAuth } from "../Auth/Authhandle";
import { Skeleton } from "@material-ui/lab"; // Import the Skeleton component
import { useTranslation } from 'react-i18next';
import SmallAlert from "../NewComponents/Modal/smallAlert";
import useHomeStyles from "../Home/useHomeStyles";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import posthog from 'posthog-js'
import ModalParent from "../AllModalsActions/ParentModal";
import { useNavigation } from "../NavigationContext";
import ModalNotReady from "../NewComponents/ModalNotReady";
import { WebSocketContext } from '../WebSocketProvider';
import FullScreenLoader from "../NewComponents/LoadingExam";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useNavigate } from 'react-router-dom';
import { useSubscription } from "../Context/SubscriptionContext";
import Premium from "../PopUpsAlerts/Premium/Premium";
import Progress from "./Progress";
import { useLocation } from 'react-router-dom';
import NewPopupOnboarding from '../NewOnboarding/NewPopupOnboarding';
import Objetivos from '../Home/Components/Objetivos';
import StatsHome from '../Home/StatsHome';
import SimpleCalendar from '../Home/Components/SimpleCalendar';
// // import PreguntasListas from "../PopUpsAlerts/QuestionsReady/PreguntasListas";
import { usePoints } from '../Context/PointsContext';
import { fetchDailyStats } from '../Api/DailyApi';
import '../Main/style.css'
import DocumentLocked from "../Documents/DocumentLocked";

const REACT_FRONT_END = process.env.REACT_APP_FRONT_END;

const styles = {
  folder: {
    position: "relative",
    width: "33.33%",
    boxSizing: "border-box",
    padding: "10px",
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
};

function LayoutProgress() {
  const { t } = useTranslation();
  const classes = useHomeStyles();

  // Modal parameters
  const [modalOpen, setModalOpen] = useState(false);
  const { userData, fetchData } = useSubscription();

  const [titleOption, setTitle] = useState("");
  const [descriptionOption, setDescription] = useState("");
  const [buttonOption, setButton] = useState("");
  const [placeholderMessage, setPlaceholderMessage] = useState("");
  const [type, setType] = useState("");
  const [activateFunction, setActivateFunction] = useState(null);
  const [folders, setFolders] = useState([]);
  const { accessToken, user } = useAuth(); // Use the hook at the top level
  const [isLoading, setIsLoading] = useState(true); // Add this state
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [linkinMessage, setLinkinMessage] = useState("");
  const [widthModal, setWidthModal] = useState("");
  const [disableClose, setDisableClose] = useState(false);
  const theme = useTheme();
  const { previousPath, currentPath } = useNavigation();

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showLimitPopup] = useState(true); // Set showLimitPopup to true by default
  const { allPointsData, fetchAllPointsData } = usePoints();
  const [statsData, setStatsData] = useState(null);

  async function fetchDataAndUpdateStats() {
    if (accessToken && user) {
      try {
        const stats = await fetchDailyStats(accessToken, "GET", null, user);
        setStatsData(stats);
      } catch (error) {
        console.error("Error fetching daily stats: ", error);
      }
    }
  }

  async function fetchDataAndUpdate() {
    if (accessToken && user) {
      fetchUserFolders(accessToken, "GET", null, user).then(fetchFolders => {
        setFolders(fetchFolders);
        setIsLoading(false);
      }).catch(error => {
        console.error("Error fetching folders: ", error);
      });
    }
  }

  useEffect(() => {
    fetchDataAndUpdate();
    fetchDataAndUpdateStats();
    if (!allPointsData) {
      fetchAllPointsData(); // Fetch allPointsData when the component mounts
    }
    // Set loading to false once data is fetched
  }, [accessToken, user]);

  const handleFolderCreated = () => {
    fetchDataAndUpdate();
  };

  const handleUploadFile = () => {
    setModalOpen(true);
    setTitle("📄 " + t("upload_file_sidebar"));
    setDescription(t("sube_material"));
    setButton(t("upload_button"));
    setPlaceholderMessage("");
    setType("upload");
    setWidthModal("5500px");
  };


  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]); // Move messages state up

  const [showAlert, setShowAlert] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [openNotReady, setOpenNotReady] = useState(false);
  const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
  const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
  const [isPreparingExam, setIsPreparingExam] = useState(false);

  const [examRedirectId, setExamRedirectId] = useState("");
  const navigate = useNavigate();

  const handleClickOpenNotReady = () => {
    setOpenNotReady(true);
  };

  const handleCloseNotReady = () => {
    setOpenNotReady(false);
  };

  useEffect(() => {
    if (isPreparingExam) {
      const timer = setTimeout(() => {
        setIsPreparingExam(false);
      }, 4000);
      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
    }
  }, [isPreparingExam]);

  // Early return to show WelcomeOposicion
  const { messageCallback, setMessageCallback } = useContext(WebSocketContext);

  useEffect(() => {
    if (messageCallback) {
      switch (messageCallback.type) {
        case "documentsUpdate":
          setProgressBar(false)
          setShowAlert(false)
          break;
        case "Error":
          setShowSpinner(false);
          setShowAlert(false)
          break;
        case "popups_getting_started":
          fetchData()
          break;

        default:
          console.log("Unhandled message type:", messageCallback.document);
      }
      setMessageCallback(null);

    }
  }, [messageCallback]);

  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');

  const handleOpenPremium = (titleText) => {
    setPremiumTitleText(titleText);
    setShowPremium(true);
  };

  const handleClosePremium = () => {
    setShowPremium(false);
  };

  const [showGettingStartedPopup, setShowGettingStartedPopup] = useState(true);

  const handleCloseGettingStartedPopup = () => {
    setShowGettingStartedPopup(false);
  };




  const [showBlockedDocument, setShowBlockedDocument] = useState(false);



  useEffect(() => {
    // Add custom scrollbar class to the body
    document.body.classList.add('custom-scrollbar');

    // Force the body to be scrollable
    document.body.style.height = '101vh';
    document.body.style.overflowY = 'scroll';

    // Clean up function
    return () => {
      document.body.classList.remove('custom-scrollbar');
      document.body.style.height = '';
      document.body.style.overflowY = '';
    };
  }, []);

  // State to control the visibility of the NewPopupOnboarding
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Check previousPath
  useEffect(() => {
    if (previousPath === "/welcome") {
      setIsPopupOpen(true);
    }
  }, [previousPath]);
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>



      {/* <PopupsGettingStarted 
        open={showGettingStartedPopup} 
        onClose={handleCloseGettingStartedPopup} 
        action = "upload"
      /> */}
      {showAlert &&
        <SmallAlert
          message={message}
          onClose={() => setShowAlert(false)}
          progressBar={true}
          linkinMessage={linkinMessage}
          typeAlert={typeAlert}
        />
      }
      {
        <NeedCredits open={showUpgrade} message={message}
          onClose={() => setShowUpgrade(false)} />
      }

      {modalOpen && (
        <ModalParent
          open={modalOpen}
          title={titleOption}
          description={descriptionOption}
          sendButton={buttonOption}
          placeholdMessage={placeholderMessage}
          setShowSpinner={setShowSpinner}
          setMessage={setMessage}
          setShowAlert={setShowAlert}
          type={type}
          onClose={() => {
            setModalOpen(false);
            fetchDataAndUpdate(); // Refresh data when modal closes
          }}
          callFunction={activateFunction}
          setProgressBar={setProgressBar}
          setShowUpgrade={setShowUpgrade}
          width={widthModal}
          onFolderCreated={handleFolderCreated}
          disableClose={disableClose}
          setIsPreparingExam={setIsPreparingExam}
          setFolderSelectedGuid={setFolderSelectedGuid}
          setDocumentSelectedGuid={setDocumentSelectedGuid}
        />
      )}

      {openNotReady && <ModalNotReady handleClose={handleCloseNotReady} />}

      {isPreparingExam && <FullScreenLoader
        imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
        title={t('fullScreenLoader.examPreparingTitle')}
        message={t('fullScreenLoader.examPreparingMessage')}
        buttonText={t('fullScreenLoader.examPreparingButton')}
        onClose={() => { setIsPreparingExam(false); }}
        onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}
      />}

      <Container style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#F8FFFF",
        maxWidth: "1400px",
        width: "100%",
        position: 'relative',
        zIndex: 1,
        overflowY: "hidden",
      }}>
        <Grid container spacing={3}>
          {/* Left Column - Progress */}
          <Grid item xs={12} md={8}>
            <Paper elevation={0} style={{ padding: "15px", backgroundColor: "white", border: "1px solid #F0F4F4", borderRadius: "20px" }}>
              <Progress handleUploadFile={handleUploadFile} setShowBlockedDocument={setShowBlockedDocument} />
            </Paper>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={4}>
            {/* Tus objetivos */}
            <Objetivos accessToken={accessToken} userData={userData} allPointsData={allPointsData} user={user} />
            {/* Estadisticas */}
            <StatsHome statsData={statsData} />
            {/* Add your statistics content here */}

            {/* Streak Calendar */}
            <SimpleCalendar user_calendar={userData.login_history} />
          </Grid>
        </Grid>

        {showBlockedDocument && (<DocumentLocked open={showBlockedDocument} onClose={() => setShowBlockedDocument(false)} />)}
        {showPremium && (
          <Premium
            onClose={handleClosePremium}
            titleText={premiumTitleText}
          />
        )}
      </Container>
      {/* Render NewPopupOnboarding if the current path is /welcome and the popup is open */}
      {isPopupOpen && (
        <NewPopupOnboarding open={isPopupOpen} onClose={handleClosePopup} />
      )}
    </>
  );
}

export default LayoutProgress;
