import React, { useState } from 'react';
import UploadFilesFolder from "../NewComponents/Upload/UploadFileFolder";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core";
import {
    Button,
    Box,
    FormControl,
    Typography,
    Stepper,
    Step,
    StepLabel
} from "@material-ui/core";
import ShowFolders from '../NewComponents/Upload/ShowFolders';
import { useNavigate, useParams } from 'react-router-dom';
import SubscriptionPopup from './SubscriptionPopup';
import { useSubscription } from "../Context/SubscriptionContext";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '26px'
    },
    buttonSubir: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "20px",
        fontSize: "14px",
        padding: "8px 16px",
        fontWeight: 600,
        '&:disabled': {
            backgroundColor: '#B0B0B0',
            color: '#FFFFFF'
        },
        '&:hover': {
            backgroundColor: '#6BBDBD',
            color: "white",
        }
    },
    buttonSubirAtras: {
        color: "#6BBDBD",
        backgroundColor: "white",
        borderRadius: "20px",
        border: "1px solid #6BBDBD",
        textTransform: "none",
        fontSize: "14px",
        fontWeight: 600,
    },
    stepper: {
        '& .MuiStepLabel-label': {
            fontSize: '14px',
            fontFamily: 'Poppins'
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: '#6BBDBD'
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: '#6BBDBD'
        }
    },
    contentContainer: {
        height: '100%',
        marginTop: "30px",
        display: 'flex',
        flexDirection: 'column'
    }
}));

const ModalUploadFiles = ({
    handleButtonClick,
    setShowSpinner,
    setShowAlert,
    setMessage,
    setProgressBar,
    setShowUpgrade,
    user,
    accessToken,
    onClose,
    updateListDocuments,
    setCredits,
    currentCredits
}) => {
    const classes = useStyles();
    const { folder_id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useSubscription();
    const [activeStep, setActiveStep] = useState(0);
    const [files, setFiles] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState("");
    const [newFolderName, setNewFolderName] = useState("");
    const [pages, setPages] = useState("");
    const [numDocuments, setNumDocuments] = useState("");
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

    const handleNext = () => {
        setActiveStep(1);
    };

    const handleBack = () => {
        setActiveStep(0);
        // Reset both the selected folder and folder name
        setSelectedFolder("");
        handleNewFolder.reset?.();
    };

    const handleSetValue = (value) => {
        setSelectedFolder(value);
    };

    const handleNewFolder = (folderName) => {
        setNewFolderName(folderName);
    };

    const handleUploadClick = () => {
        const hasLargeFiles = files.some(file => file.original_pages > 40);
        if (hasLargeFiles && userData?.plan_name === "Basic") {
            setShowSubscriptionPopup(true);
        } else {
            handleUpload();
        }
    };

    const handleUpload = () => {
        handleButtonClick({
            typed: "upload",
            file: files,
            value: folder_id || selectedFolder || "Nueva Carpeta",
            newFolderName,
            setShowSpinner,
            setShowAlert,
            setMessage,
            setProgressBar,
            setShowUpgrade,
            user: user,
            token: accessToken,
            onClose: onClose,
            navigate: navigate,
            updateListDocuments: updateListDocuments
        });
    };

    const steps = [
        {

            content: (
                <div className={classes.contentContainer}>
                    <div style={{ flex: 1 }}>
                        <UploadFilesFolder
                            files={files}
                            setFiles={setFiles}
                            is_multiple={true}
                            setPages={setPages}
                            setNumDocuments={setNumDocuments}
                        />
                    </div>
                    <Box display="flex" justifyContent="flex-end" marginTop="20px">
                        <Button
                            style={{
                                backgroundColor: files.length === 0 ? "#B0B0B0" : "#6BBDBD",
                                color: "white",
                                textTransform: "none",
                                borderRadius: "20px",
                                fontSize: "14px",
                                fontWeight: 600,
                                padding: "8px 24px",
                                marginBottom: "10px"
                            }}
                            onClick={handleNext}
                            disabled={files.length === 0}
                        >
                            Siguiente
                        </Button>
                    </Box>
                </div>
            )
        },
        {
            content: (
                <div className={classes.contentContainer}>
                    <div style={{ flex: 1 }}>
                        <FormControl fullWidth>
                            <ShowFolders
                                value={selectedFolder}
                                setValue={handleSetValue}
                                handleNewFolder={handleNewFolder}
                                margin="dense"
                            />
                        </FormControl>
                    </div>
                    <Box display="flex" justifyContent="flex-end" marginTop="20px" marginBottom="10px">
                        <Button
                            className={classes.buttonSubirAtras}
                            onClick={handleBack}
                            style={{ marginRight: "10px" }}
                        >
                            Atrás
                        </Button>
                        <Button
                            className={classes.buttonSubir}
                            onClick={handleUploadClick}
                            disabled={!selectedFolder && (newFolderName.trim() === '' || newFolderName === null)}
                        >
                            Subir
                        </Button>
                    </Box>
                </div>
            )
        }
    ];

    return (
        <div>
            {steps[activeStep].content}
            <SubscriptionPopup
                open={showSubscriptionPopup}
                onClose={() => setShowSubscriptionPopup(false)}
                onUpload={handleUpload}
            />
        </div>
    );
};

export default ModalUploadFiles;
