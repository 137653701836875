import React, { useState } from 'react';
import { 
    Box, 
    Typography, 
    Modal, 
    TextField,
    Button,
    IconButton
} from '@mui/material';
import Diana from './Diana.svg';

const EditObjetivosPopup = ({ open, onClose, currentGoal, onSave }) => {
    const [dailyGoal, setDailyGoal] = useState(currentGoal);

    const handleSave = () => {
        onSave(Math.max(20, dailyGoal));
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="edit-objetivos-modal"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'white',
                borderRadius: '20px',
                p: 3,
                width: '90%',
                maxWidth: '400px',
                border: '1px solid #F0F4F4',
                outline: 'none',
            }}>
                {/* Header */}
                <Typography sx={{ 
                    fontWeight: 'bold', 
                    fontSize: '16px', 
                    fontFamily: 'Fira Sans',
                    mb: 1
                }}>
                    Editar objetivo diario
                </Typography>

                {/* Explanatory Text */}
                <Typography sx={{ 
                    fontSize: '14px', 
                    fontFamily: 'Fira Sans',
                    color: '#666666',
                    mb: 3
                }}>
                    Estudiar todos los días te ayudará a mantener un ritmo constante y mejorar la retención.
                </Typography>

                {/* Current Goal Display */}
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2,
                    mb: 3,
                    p: 2,
                    backgroundColor: '#F8FFFF',
                    border: '1px solid #6BBDBD',
                    borderRadius: '12px'
                }}>
                    <img src={Diana} alt="target" height="50px" width="50px"/>
                    <Box>
                        <Typography sx={{ 
                            fontSize: '14px', 
                            fontFamily: 'Fira Sans', 
                            color: '#A0A0A0'
                        }}>
                            Objetivo actual
                        </Typography>
                        <Typography sx={{ 
                            fontSize: '16px', 
                            fontFamily: 'Fira Sans', 
                            fontWeight: 'bold'
                        }}>
                            {currentGoal} preguntas al día
                        </Typography>
                    </Box>
                </Box>

                {/* Input Field */}
                <Box sx={{ mb: 3 }}>
                    <Typography sx={{ 
                        fontSize: '14px', 
                        fontFamily: 'Fira Sans',
                        mb: 1,
                        color: '#666'
                    }}>
                        Nuevo objetivo diario
                    </Typography>
                    <TextField
                        fullWidth
                        type="number"
                        value={dailyGoal}
                        onChange={(e) => setDailyGoal(Math.max(20, parseInt(e.target.value) || 20))}
                        InputProps={{
                            sx: {
                                borderRadius: '12px',
                                '& input': {
                                    padding: '12px',
                                    fontFamily: 'Inter, sans-serif',
                                }
                            }
                        }}
                    />
                </Box>

                {/* Action Buttons */}
                <Box sx={{ 
                    display: 'flex', 
                    gap: 2,
                    justifyContent: 'flex-end'
                }}>
                    <Button
                        onClick={onClose}
                        sx={{
                            color: '#666',
                            textTransform: 'none',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 600,
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleSave}
                        sx={{
                            backgroundColor: '#6BBDBD',
                            color: 'white',
                            textTransform: 'none',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 600,
                            fontSize: '14px',
                            borderRadius: '20px',
                            px: 3,
                            '&:hover': {
                                backgroundColor: '#5AACAC'
                            }
                        }}
                    >
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditObjetivosPopup; 