import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

// Create a styled Dialog component with increased border radius
const RoundedDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: "24px", // Adjust this value to increase or decrease the border radius
  },
}));

const PreguntasDocumentPopup = ({ open, onClose, infoQuestion, chapterTitle }) => {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            {chapterTitle}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" fontWeight="600" gutterBottom>
          {infoQuestion.text}
        </Typography>
        <Box mt={2}>
          {infoQuestion.question_options.map((option, index) => (
            <Typography key={index} variant="body1">
              {String.fromCharCode(65 + index)}. {option.text}
            </Typography>
          ))}
        </Box>
      </DialogContent>
    </RoundedDialog>
  );
};

export default PreguntasDocumentPopup;
