import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    TextField,
    Typography, Link
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import { useSubscription } from "../Context/SubscriptionContext";

import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../Auth/Authhandle";
import "./modal.css";
import ModalInvite from "./ModalInvite";
import ModalUploadFiles from "./ModalUploadFiles";
import ModalLayoutExam from "./PontePrueba/PontePruebaV1/ModalLayoutExam";
import ModalNewFolder from "./ModalNewFolder";
import ModalNewExercise from "./ModalNewExercise";
import General from "./PontePrueba/General";
import ModalLayoutExam2 from "./PontePrueba/PontePruevaV2/ModalLayoutExam";
import WelcomeTyped from "./Welcome/WelcomeTyped";
import * as handle from "./handleModal";
import Premium from "../PopUpsAlerts/Premium/Premium";
import CircularProgress from '@material-ui/core/CircularProgress';
import FarewellPopup from "../Cancelation/PopUpCancelation";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles((theme) => {
    return {
        noMargin: {
            margin: "0px !important",
        },
        dialogTitle: {

            '& h2': {
                fontWeight: "bold !important",
                fontSize: "18px",
                color: "black",
                fontFamily: "Inter"

            },
            '&.MuiDialogTitle-root': {
                paddingBottom: "0px !important",

            }
        },
        dividerContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            margin: theme.spacing(2, 0), // Adjust the margin as needed
        },
        line: {
            flex: 1,
            height: '1px',
            background: '#D3D3D3', // Or any color you want for the line
            marginLeft: theme.spacing(1), // Space before the line starts
            marginRight: theme.spacing(1), // Space after the line ends
        },
        text: {
            whiteSpace: 'nowrap', // Prevents the text from wrapping
            color: 'gray', // Text color
            padding: theme.spacing(0, 1), // Optional: Adjust padding around text
            background: '#FFF', // Background color for the text area to overlap the line
        },
        dialog: {
            '& .MuiPaper-root': {
                borderRadius: "20px !important",
                width: "350px",

            }
        },
        backdrop: {
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Light semi-transparent background
        },
        step3Container: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: theme.zIndex.modal + 1,
        },
        step3Content: {
            textAlign: 'center',
            // backgroundColor: '#fff',
            padding: theme.spacing(3),
            // borderRadius: '8px',
            // boxShadow: theme.shadows[5],
        },
        step3Image: {
            maxWidth: '400px',
            marginBottom: theme.spacing(2),
        },
        yesButton: {
            textTransform: "none",
            backgroundColor: "red",
            color: "white",
            '&:hover': {
                textTransform: "none",
                backgroundColor: "red",
                color: "white",
            }
        },
        noButton: {
            textTransform: "none",
            backgroundColor: "#6BBDBD",
            color: "white",
            '&:hover': {
                textTransform: "none",
                backgroundColor: "#6BBDBD",
                color: "white"
            }
        }
    };

});
function ModalParent({
    onClose,
    open,
    title,
    description,
    width = "350px",  // Default width is 350px, can be overridden via props
    disableClose = false,  // New prop to disable closing the modal

    type, icon, onExamCreated,
    onFolderCreated,
    updateListDocuments, subscription,
    setIsPreparingExam,
    setFolderSelectedGuid, setDocumentSelectedGuid,
    ...props  // This will capture all additional props


}) {
    // OnClose -> state if dialog is close
    // open -> state if dialog is open
    // title -> title of the modal
    // description -> descrption of the modal
    // sendButton -> name of the button
    // placeholdMessage -> input placeholder message
    // type -> type invite | upload | else
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useSubscription();

    const { accessToken, user } = useAuth(); // Use the hook at the top level
    const [credits, setCredits] = useState(0)
    const [currentModalType, setCurrentModalType] = useState(type);
    const [modalWidth, setModalWidth] = useState(width);
    const [newDescription, setDescription] = useState(description);
    const [step, setStep] = useState(1);
    const [canExecuteModal, setCanExecuteModal] = useState(false)
    const [currentCredits, setCurrentCredits] = useState(userData.credits_plan + userData.credits_bought)
    const [isDeleting, setIsDeleting] = useState(false);

    // Function to change the modal type
    const changeModalType = (newType, newWidth = "350px", newDescription) => {
        setCurrentModalType(newType);
        setModalWidth(newWidth);
        setDescription(newDescription)

    };
    useEffect(() => {
        if (userData) {
            setCanExecuteModal(credits === -100 || credits <= userData.credits_plan + userData.credits_bought);
        } else {
            setCanExecuteModal(true); // Default to true if userData is not available
        }

    }, [credits]);


    async function handleDeleteAccount() {
        setIsDeleting(true);
        const apiUrl = `${BACKEND_URL}/api_v1/subscription/delete/${subscription}/`;

        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete account');
            }

            // window.location.href = "https://www.typedai.com";
        } catch (error) {
            console.error('Error deleting account:', error);
            setIsDeleting(false);
            // Handle error (e.g., show an error message to the user)
        }
    }
    const [cancelStats, setCancelStats] = useState([]);

    useEffect(() => {
        if (userData) {
            setCancelStats([
                { value: userData.num_documents, label: 'Documentos estudiados' },
                { value: userData.num_exam_questions, label: 'Preguntas respondidas' },
                { value: userData.num_summary, label: 'Resúmenes generados' },
            ]);
        }
    }, [userData]);
    const [cancelPopupOpen, setCancelPopupOpen] = useState(false);

    useEffect(() => {
        if (type === "delete_account") {
            setCancelPopupOpen(true);
        }
    }, [type]);

    const handleBuyCreditsClick = (e) => {
        e.preventDefault();
        navigate('/plans');
    };
    const handleCloseCancelPopup = () => {
        setCancelPopupOpen(false);
    };
    const creditsAvailable = userData.credits_plan + userData.credits_bought;
    const creditsRequired = credits;
    return (
        <>

            {
                step === 3 ? (
                    <div className={classes.step3Container}>
                        <div className={classes.step3Content}>
                            <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/9176917_6678-removebg-preview+1.png" className={classes.step3Image} />
                            <Typography style={{ fontWeight: "bold", fontSize: "16px", color: "#026277" }}>{t('account_getitng_activated')}</Typography>
                        </div>
                    </div>
                ) : (


                    <Dialog
                        open={open}
                        onClose={disableClose ? null : onClose}
                        className={classes.dialog} BackdropProps={{
                            classes: {
                                root: classes.backdrop
                            }
                        }} PaperProps={{
                            style: {
                                width: modalWidth, // Set the width dynamically
                            },
                        }}
                        style={{ overflowX: 'hidden' }}>
                        <DialogTitle
                            className={classes.dialogTitle}

                        >
                            {title}

                        </DialogTitle>
                        <DialogContent style={{}}>
                            <Typography
                                style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Inter"
                                }}
                            >
                                {newDescription}
                            </Typography>
                            {currentModalType === "invite" || currentModalType === 'subscription' ? (
                                <div style={{ marginTop: "20px" }}>
                                    <ModalInvite {...props} user={user}
                                        accessToken={accessToken} />
                                </div>
                            ) : currentModalType === "upload" ? (
                                // Your code for "upload" condition here

                                <div >
                                    {userData.plan_name === 'Basic' && userData.num_documents >= 4 ? (
                                        <Premium
                                            onClose={onClose}
                                            titleText="documents"
                                        />
                                    ) : (
                                        <div style={{ height: "100%" }}>
                                            <ModalUploadFiles {...props} setShowSpinner={props.setShowSpinner}
                                                setShowAlert={props.setShowAlert}
                                                setMessage={props.setMessage}
                                                setProgressBar={props.setProgressBar}
                                                setShowUpgrade={props.setShowUpgrade} handleButtonClick={handle.handleButtonClick}
                                                user={user}
                                                accessToken={accessToken}
                                                onClose={onClose}
                                                updateListDocuments={updateListDocuments}
                                                setCredits={setCredits}
                                                currentCredits={currentCredits}


                                            />
                                        </div>
                                    )}
                                </div>
                            ) : currentModalType === "layout_exam" ? (<>
                                <General changeModalType={changeModalType} user={user}
                                    accessToken={accessToken} />
                            </>
                            ) : currentModalType === "carpeta" ? (<>
                                <div>
                                    <ModalNewFolder {...props} user={user}
                                        accessToken={accessToken}
                                        onClose={onClose} setShowAlert={props.setShowAlert}
                                        setMessage={props.setMessage} onFolderCreated={onFolderCreated} />
                                </div>
                            </>
                            ) : currentModalType === "exercise" ? (
                                <>
                                    <div >
                                        <ModalNewExercise {...props} user={user}
                                            accessToken={accessToken} />

                                    </div>
                                </>) : currentModalType === "ponteprueba2" ? (
                                    <>
                                        <div >
                                            <ModalLayoutExam {...props} onClose={onClose}
                                                user={user}
                                                accessToken={accessToken}
                                                onExamCreated={onExamCreated} // Pass the callback down
                                                setCredits={setCredits}
                                                setIsPreparingExam={setIsPreparingExam}
                                                currentCredits={currentCredits}
                                                setShowUpgrade={props.setShowUpgrade}
                                                setFolderSelectedGuid={setFolderSelectedGuid}
                                                setDocumentSelectedGuid={setDocumentSelectedGuid}

                                            />

                                        </div>
                                    </>
                                ) : currentModalType === "ponteprueba1" ? (
                                    <>
                                        <div >
                                            <ModalLayoutExam2 {...props} onClose={onClose}
                                                user={user}
                                                accessToken={accessToken}
                                                onExamCreated={onExamCreated} // Pass the callback down
                                                updateListDocuments={updateListDocuments}
                                                setCredits={setCredits}
                                                setIsPreparingExam={setIsPreparingExam}
                                                currentCredits={currentCredits}
                                                setShowUpgrade={props.setShowUpgrade}
                                                setShowAlert={props.setShowAlert}
                                                setMessage={props.setMessage}
                                                setProgressBar={props.setProgressBar}
                                                setFolderSelectedGuid={setFolderSelectedGuid}
                                                setDocumentSelectedGuid={setDocumentSelectedGuid}
                                            />

                                        </div>
                                    </>
                                )
                                : currentModalType === "welcome_onboard" ? (
                                    <>
                                        <div >
                                            <WelcomeTyped {...props} onClose={onClose} user={user}
                                                accessToken={accessToken} step={step} setStep={setStep} onFolderCreated={onFolderCreated} setCredits={setCredits} />

                                        </div>
                                    </>
                                ) : currentModalType == "delete_account" ? (
                                    <>
                                        {/* <div style={{ display: "flex", justifyContent: "center", gap: 10, flexDirection: "row", marginTop: "10px" }}>
                                            <Button
                                                className={classes.yesButton}
                                                onClick={handleDeleteAccount}
                                                disabled={isDeleting}
                                            >
                                                {isDeleting ? <CircularProgress size={24} color="inherit" /> : 'Yes'}
                                            </Button>
                                            <Button
                                                className={classes.noButton}
                                                onClick={onClose}
                                                disabled={isDeleting}
                                            >
                                                No
                                            </Button>
                                        </div> */}
                                        <FarewellPopup
                                            open={cancelPopupOpen}
                                            onClose={handleCloseCancelPopup}
                                            onContinue={handleDeleteAccount}
                                            userName={user?.name || 'Usuario'}
                                            stats={cancelStats}
                                        />

                                    </>
                                ) : (<></>)}
                            {(currentModalType === "welcome_onboard" && step === 2) ||

                                currentModalType === "invite" ||
                                currentModalType === "subscription" ||
                                currentModalType === "exercise" ||
                                currentModalType === "ponteprueba1" ||
                                currentModalType === "ponteprueba2" ? (
                                <>
                                    {/* TODO: traduir  se restaran */}
                                    <Typography style={{ fontSize: "10px", color: "gray", paddingTop: "10px", paddingBottom: "10px" }}> {t('creditMessage', { credits })}</Typography>



                                </>
                            ) : null}
                        </DialogContent>

                    </Dialog >
                )}
        </>
    );
}

export default ModalParent;


