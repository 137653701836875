import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Button, Container, IconButton, Box, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GetAppIcon from '@mui/icons-material/GetApp';
import Select from 'react-select';
import SearchIcon from '@mui/icons-material/Search';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FolderIcon from '@mui/icons-material/Folder';
import PopupGenerarEsquema from './PopupGenerarEsquema';
import MermaidChart from './MermaidChart';
import { fetchUserSchemas } from '../Api/SchemasApi';
import { useAuth } from "../Auth/Authhandle";
import { Excalidraw } from "@excalidraw/excalidraw";
import { exportToSvg } from "@excalidraw/excalidraw";
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteSchema } from '../Api/SchemasApi';
import { convertToExcalidrawElements } from '@excalidraw/excalidraw';
import { parseMermaidToExcalidraw } from '@excalidraw/mermaid-to-excalidraw';
import { updateSchema } from '../Api/SchemasApi';
import { keyframes } from '@mui/system';
import { useCredits } from "../Context/CreditsContext";
import NeedCredits from '../NoCreditsModal/NeedCredits';

const loadingDots = keyframes`
  0%, 20% { content: '.'; }
  40%, 60% { content: '..'; }
  80%, 100% { content: '...'; }
`;

const MySchemas = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFolder, setSelectedFolder] = useState({ value: 'all', label: 'Todas las carpetas' });
  const [openPopup, setOpenPopup] = useState(false);
  const [schemas, setSchemas] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { accessToken, user } = useAuth(); // Use the hook at the top level
  const [excalidrawElements, setExcalidrawElements] = useState({});
  const [editingSchemaId, setEditingSchemaId] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const { credits } = useCredits();
  const [openPremium, setOpenPremium] = useState(false);

  useEffect(() => {
    async function fetchDataAndUpdate() {
      if (accessToken && user) {
        try {
          setIsLoading(true);
          const { schemas, folders } = await fetchUserSchemas(accessToken, user);

          setSchemas(schemas);
          setFolders(folders);
        } catch (error) {
          console.error("Error fetching schemas: ", error);
          setSchemas([]);
          setFolders([{ value: 'all', label: 'Todas las carpetas' }]);
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchDataAndUpdate();
  }, [accessToken, user]);

  useEffect(() => {
    const convertMermaidToElements = async (schema) => {
      // If excalidraw data exists, use it directly
      if (schema.excalidraw) {
        return {
          elements: schema.excalidraw,
          files: null
        };
      }

      // Otherwise convert from mermaid
      if (schema.graph) {
        try {
          const { elements, files } = await parseMermaidToExcalidraw(schema.graph, {
            fontSize: 16,
          });
          return {
            elements: convertToExcalidrawElements(elements),
            files
          };
        } catch (error) {
          console.error("Error converting mermaid:", error);
          return null;
        }
      }
      return null;
    };

    // Convert all schemas
    const convertAllSchemas = async () => {
      const elementsMap = {};
      for (const schema of schemas) {
        elementsMap[schema.id] = await convertMermaidToElements(schema);
      }
      setExcalidrawElements(elementsMap);
    };

    convertAllSchemas();
  }, [schemas]);

  const handleSchemaClick = (schemaId) => {
    navigate(`/schema/${schemaId}`);
  };

  const handleDownload = async (schema) => {
    try {
      // Get the converted elements for this schema
      const schemaElements = excalidrawElements[schema.id];
      if (!schemaElements) {
        console.error("No converted elements found for schema");
        return;
      }

      // Create a temporary Excalidraw element
      const excalidrawElement = document.createElement("div");
      excalidrawElement.style.position = "fixed";
      excalidrawElement.style.opacity = "0";
      excalidrawElement.style.pointerEvents = "none";
      document.body.appendChild(excalidrawElement);

      // Generate SVG using the converted elements
      const svg = await exportToSvg({
        elements: schemaElements.elements,
        appState: {
          viewBackgroundColor: '#f5f5f5',
          exportWithDarkMode: false,
          exportScale: 2,
          gridSize: null,
        },
        files: schemaElements.files,
      });

      // Create download link
      const svgBlob = new Blob([svg.outerHTML], { type: "image/svg+xml" });
      const downloadUrl = URL.createObjectURL(svgBlob);
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.download = `${schema.title || 'schema'}.svg`;

      // Trigger download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Cleanup
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(excalidrawElement);
    } catch (error) {
      console.error("Error downloading schema:", error);
    }
  };

  const handleOpenPopup = () => {
    if (credits === -100 || credits < 20) {
      setOpenPremium(true);
      return;
    }
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleGenerateSchema = (title, text, selectedDocument, selectedChapter) => {
    // Implement the logic to generate the schema
    console.log('Generating schema:', { title, text, selectedDocument, selectedChapter });
    // You can add your schema generation logic here
  };

  const handleViewDiagram = (schemaId) => {
    // Open the map view with the schema id in a new tab
    navigate(`/schema/${schemaId}`);
  };

  const filteredData = schemas.filter((schema) => {
    const matchesSearch = schema.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFolder = selectedFolder.value === 'all' || schema.folder_id === selectedFolder.value;
    return matchesSearch && matchesFolder;
  });

  const handleSchemaCreated = (newSchema) => {
    // Add the new schema to the existing list
    // setSchemas(prevSchemas => [newSchema, ...prevSchemas]);
    console.log("newSchema", newSchema);
    navigate(`/schema/${newSchema.id}`);
  };

  const handleDelete = async (schemaId, documentId) => {
    try {
      console.log('Deleting schema:', schemaId, documentId);
      await deleteSchema(accessToken, user, schemaId, documentId);
      // Remove the deleted schema from the state
      setSchemas(prevSchemas => prevSchemas.filter(schema => schema.id !== schemaId));
    } catch (error) {
      console.error('Error deleting schema:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleUpdateSchemaTitle = async (schemaId, newTitle) => {
    try {
      const updatedSchema = await updateSchema(accessToken, user, schemaId, { name: newTitle });

      // Update the schemas state with the new title
      setSchemas(prevSchemas =>
        prevSchemas.map(schema =>
          schema.id === schemaId ? { ...schema, title: updatedSchema.title } : schema
        )
      );
      setEditingSchemaId(null);
      setEditingTitle('');
    } catch (error) {
      console.error('Error updating schema title:', error);
    }
  };

  if (openPremium) {
    return <NeedCredits initialOpen={openPremium}
      onClose={() => setOpenPremium(false)} />;
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" alignItems="center">
          <AccountTreeIcon style={{ color: '#6BBDBD', marginRight: '8px' }} />
          <Typography variant="h5" style={{ color: '#6BBDBD', fontFamily: 'Inter', fontWeight: 'bold' }}>
            Esquemas
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={handleOpenPopup}
          style={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            fontFamily: 'Inter',
            textTransform: 'none',
            borderRadius: '20px',
            boxShadow: 'none'
          }}
        >
          <AccountTreeIcon style={{ color: 'white', marginRight: '8px' }} />
          Nuevo esquema
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <TextField
          placeholder="Buscar esquemas..."
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <SearchIcon style={{ color: '#6BBDBD', marginRight: '8px' }} />
            ),
            style: {
              fontFamily: 'Inter',
              borderRadius: '20px',
              backgroundColor: '#f5f5f5',
              fontSize: "14px"
            }
          }}
          sx={{
            width: '400px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#E0E0E0',
              },
              '&:hover fieldset': {
                borderColor: '#6BBDBD',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#6BBDBD',
              },
            },
          }}
        />
        <Select
          options={folders}
          value={selectedFolder}
          onChange={setSelectedFolder}
          menuPortalTarget={document.body}
          styles={{
            control: (provided) => ({
              ...provided,
              width: '250px',
              fontFamily: 'Inter',
              fontSize: "14px",
              zIndex: 1000,
            }),
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      </Box>
      <Grid container spacing={2}>
        {isLoading ? (
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              mt: 4,
              '&::after': {
                content: "'.'",
                animation: `${loadingDots} 1.5s infinite`,
                fontSize: '24px',
                color: '#6BBDBD'
              }
            }}
          >
            <Typography variant="h6" style={{ color: '#6BBDBD', fontFamily: 'Inter' }}>
              Cargando
            </Typography>
          </Box>
        ) : filteredData.length === 0 ? (
          <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
            <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" width="120" height="120" />
            <Typography sx={{ mt: 2, color: '#666', fontSize: '16px' }}>
              Aún no tienes ningún esquema
            </Typography>
          </Box>
        ) : (
          filteredData.map((schema) => (
            <Grid item xs={12} sm={6} md={4} key={schema.id}>
              <Card elevation={0} style={{ border: '1px solid #E0E0E0', borderRadius: '10px', height: '300px' }}>
                <CardContent>
                  <Box
                    sx={{
                      width: '100%',
                      height: '180px',
                      backgroundColor: '#f5f5f5',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '10px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                      position: 'relative',
                      '& .App-toolbar-content': {
                        display: 'none !important'
                      }

                    }}

                  >
                    {excalidrawElements[schema.id] ? (

                      <Excalidraw
                        initialData={{
                          elements: excalidrawElements[schema.id].elements,
                          appState: {
                            viewModeEnabled: true,
                            zenModeEnabled: false,
                            gridSize: null,
                            viewBackgroundColor: '#f5f5f5',
                            zoom: { value: 0.1 },
                            scrollToContent: false,
                          },
                          opts: {
                            fitToViewport: true,
                            viewportZoomFactor: 0.5,
                            scrollToCenter: false
                          },
                          files: excalidrawElements[schema.id].files
                        }}
                        UIOptions={{
                          canvasActions: {
                            export: false,
                            loadScene: false,
                            saveAsImage: false,
                            saveScene: false,
                            clearCanvas: false,
                            changeViewBackgroundColor: false,
                          },
                        }}
                        viewModeEnabled={true}
                        zenModeEnabled={false}
                        gridModeEnabled={false}
                        theme="light"
                        style={{ width: '100%', height: '100%', position: 'absolute' }}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        Loading preview...
                      </Typography>
                    )}
                  </Box>
                  {editingSchemaId === schema.id ? (
                    <TextField
                      value={editingTitle}
                      onChange={(e) => setEditingTitle(e.target.value)}
                      onBlur={async () => {
                        const newTitle = editingTitle.trim();
                        // Immediately close the text field
                        setEditingSchemaId(null);

                        if (newTitle !== '') {
                          // Optimistically update the UI
                          setSchemas(prevSchemas =>
                            prevSchemas.map(s =>
                              s.id === schema.id ? { ...s, title: newTitle } : s
                            )
                          );

                          // Update in the background
                          try {
                            await updateSchema(accessToken, user, schema.id, { name: newTitle });
                          } catch (error) {
                            console.error('Error updating schema title:', error);
                            // Revert the title if update fails
                            setSchemas(prevSchemas =>
                              prevSchemas.map(s =>
                                s.id === schema.id ? { ...s, title: schema.title } : s
                              )
                            );
                          }
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.target.blur(); // This will trigger onBlur
                        }
                      }}
                      autoFocus
                      size="small"
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        marginTop: '20px',
                        width: '100%',
                        height: '30px'
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: '30px',
                          '& fieldset': {
                            borderColor: '#026277',
                          },
                          '&:hover fieldset': {
                            borderColor: '#026277',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#026277',
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '5px 10px',
                        }
                      }}
                    />
                  ) : (
                    <Typography
                      onClick={(e) => {

                        setEditingSchemaId(schema.id);
                        setEditingTitle(schema.title);

                      }}
                      variant="body2"
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginTop: '20px',
                        cursor: 'pointer'
                      }}
                    >
                      {schema.title}
                    </Typography>
                  )}
                  <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                    <Box display="flex" alignItems="center">
                      <FolderIcon style={{ fontSize: 16, marginRight: 4, color: '#a0a0a0' }} />
                      <Typography variant="body2" color="textSecondary" style={{ fontFamily: 'Inter' }}>
                        {schema.folder}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton size="small" onClick={() => handleViewDiagram(schema.id)} style={{ color: '#6BBDBD' }}>
                        <VisibilityIcon />
                        <Typography variant="body2" style={{ marginLeft: "5px", fontFamily: 'Inter', fontSize: '14px', fontWeight: 'bold' }}>Ver</Typography>
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownload(schema);
                        }}
                        style={{ color: '#6BBDBD' }}
                      >
                        <GetAppIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(schema.id, schema.document_id);
                        }}
                        style={{ color: '#FF5733' }}
                      >
                        <DeleteIcon style={{ color: '#6BBDBD' }} />
                      </IconButton>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
      <PopupGenerarEsquema
        open={openPopup}
        onClose={handleClosePopup}
        onSchemaCreated={handleSchemaCreated}
      />
    </Container >
  );
};

export default MySchemas;
