import React, { useEffect } from 'react';
import { Box, Typography, Card, Grid, IconButton, Button, Tooltip } from '@mui/material';
import Taks from '../Taks.png';
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate } from 'react-router-dom';
import { fetchFolderExams, fetchExamId } from '../../Api/ExamsApi';
import { useCredits } from "../../Context/CreditsContext";
import NeedCredits from '../../NoCreditsModal/NeedCredits';

const Tasks = ({ accessToken, user }) => {
  const [showMore, setShowMore] = React.useState(false);
  const [exams, setExams] = React.useState([]);
  const [totalTasks, setTotalTasks] = React.useState(0);
  const [completedTasks, setCompletedTasks] = React.useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExams = async () => {
      if (accessToken && user) {
        try {
          const examsResponse = await fetchFolderExams(accessToken, "GET", null, user, null, null);
          const allExams = Object.entries(examsResponse).map(([id, exam]) => ({
            id,
            ...exam
          }));

          // Get current date components
          const now = new Date();
          const currentYear = now.getFullYear();
          const currentMonth = now.getMonth() + 1; // getMonth() returns 0-11
          const currentDay = now.getDate();

          // Get total number of tasks for today
          const tasks = allExams.filter(exam => {
            if (!exam.is_task || !exam.date) return false;

            // Parse exam date (format: "2024-11-09 19:17:37")
            const [datePart] = exam.date.split(' ');
            const [year, month, day] = datePart.split('-').map(Number);
            return exam.is_task === true && (exam.answered &&
              year === currentYear &&
              month === currentMonth &&
              day === currentDay) || !exam.answered;
          });

          setTotalTasks(tasks.length);

          // Get number of completed tasks
          const completed = tasks.filter(exam => exam.answered && exam.answered !== "");
          setCompletedTasks(completed.length);

          // Filter for incomplete tasks to display
          const incompleteTasks = tasks.filter(exam => !exam.answered || exam.answered === "");
          setExams(incompleteTasks);
        } catch (error) {
          console.error("Error fetching exams:", error);
          setExams([]);
          setTotalTasks(0);
          setCompletedTasks(0);
        }
      }
    };

    fetchExams();
  }, [accessToken, user]);

  const visibleTasks = showMore ? exams : exams.slice(0, 6);
  const { updateCredits, credits } = useCredits();

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Fira Sans' }}>
          Tus tareas de hoy
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{
            bgcolor: '#e8f4f4',
            px: 2,
            py: 0.5,
            borderRadius: '20px',
            fontFamily: 'Fira Sans',
            fontWeight: 'bold',
            color: '#6BBDBD'
          }}>
            {`${completedTasks}/${totalTasks}`}
          </Typography>
          <img
            src={Taks}
            alt="Notebook"
            style={{ width: '24px', height: '24px' }}
          />
        </Box>
      </Box>

      <Typography sx={{ color: '#7c8a97', mb: 3, fontSize: '16px', fontFamily: 'Inter' }}>
        Completa las siguientes tareas para avanzar en tu estudio
      </Typography>

      {exams.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: "50px",
            textAlign: 'center'
          }}
        >
          <img
            src={"https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Podium+2.png"}
            alt="Podium"
            style={{
              width: '200px',
              height: 'auto',
              marginBottom: '24px'
            }}
          />

          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '20px',
              fontFamily: 'Fira Sans',
              color: '#6BBDBD'
            }}
          >
            Has completado todas tus tareas 🎉
          </Typography>
          <Typography
            sx={{
              color: '#7c8a97',
              fontSize: '16px',
              fontFamily: 'Inter',
              mb: 4
            }}
          >
            Responde a más preguntas con tests personalizados
          </Typography>
          <Button
            startIcon={<SchoolIcon />}
            onClick={() => navigate('/new-exam')}
            sx={{
              color: 'white',
              backgroundColor: '#6BBDBD',
              border: 'none',
              borderRadius: '20px',
              padding: '8px 24px',
              cursor: 'pointer',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'none',
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: '#6BBDBD',
                transform: 'translateY(-2px)',
                boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
              }
            }}
          >
            Estudiar
          </Button>
        </Box>
      ) : (
        <>
          {/* Cards Grid */}
          <Grid container spacing={2}>
            {visibleTasks.map((exam, index) => (
              <Grid item xs={12} sm={6} md={4} key={exam.id}>
                <Card
                  onClick={async () => {
                    if (credits === -100 || credits >= 40) {
                      if (!exam.credits_deducted) {
                        let payload = { "credits_deducted": true }
                        try {
                          navigate(`/exam/${exam.id}`);
                          // Update exam first, then update credits
                          fetchExamId(accessToken, "PUT", exam.id, user, payload)
                            .then(() => updateCredits())
                            .catch(error => {
                              console.error("Error updating exam or credits:", error);
                            });
                        } catch (error) {
                          console.error("Error navigating to exam:", error);
                        }
                      } else {
                        navigate(`/exam/${exam.id}`);
                      }
                    } else {
                      <NeedCredits />
                    }
                  }}
                  sx={{
                    padding: "20px",
                    borderRadius: "20px",
                    position: 'relative',
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #E7E7E7",
                    overflow: 'visible',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
                    },
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      bottom: 0,
                      right: 0,
                      width: "12px",
                      borderRadius: '20px 0 0 20px',
                      backgroundColor: exam.folder_color || '#6BBDBD',
                    }
                  }}
                >
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'  // Ensure the Box takes full width
                  }}>
                    <Box sx={{
                      flexGrow: 1,  // Allow this Box to grow and take available space
                      minWidth: 0   // Allow text truncation to work properly
                    }}>
                      <Tooltip title={exam.title || 'Untitled Exam'}>
                        <Typography sx={{
                          fontWeight: 'bold',
                          mb: 1,
                          fontSize: '14px',
                          fontFamily: 'Fira Sans',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}>
                          {exam.title || 'Untitled Exam'}
                        </Typography>
                      </Tooltip>
                      <Typography sx={{ color: '#7c8a97', fontSize: '12px', fontFamily: 'Inter' }}>
                        {`${exam.num_questions || 0} preguntas`}
                      </Typography>
                    </Box>
                    <IconButton sx={{ color: '#6BBDBD', backgroundColor: "#E2F1F1" }}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.25 10.0835V5.68351C5.25 4.65675 5.25 4.14299 5.44074 3.75081C5.60852 3.40585 5.87604 3.12559 6.20532 2.94982C6.57967 2.75 7.07008 2.75 8.05017 2.75H11.9654C12.0726 2.75 12.1664 2.75 12.25 2.7508M17.4992 8.25C17.5 8.33764 17.5 8.43605 17.5 8.54854V16.3199C17.5 17.3447 17.5 17.8571 17.3094 18.2489C17.1417 18.5939 16.8734 18.8746 16.5441 19.0504C16.1702 19.25 15.6809 19.25 14.7027 19.25L11.375 19.25M17.4992 8.25C17.4968 7.9883 17.4875 7.82261 17.4513 7.66455C17.4084 7.47748 17.3374 7.29863 17.2415 7.1346C17.1333 6.94958 16.9828 6.79148 16.6801 6.4744L13.9453 3.60938C13.6428 3.2925 13.4912 3.13369 13.3147 3.02035C13.1581 2.91983 12.9876 2.84557 12.809 2.80066C12.658 2.76269 12.4999 2.75318 12.25 2.7508M17.4992 8.25H17.5002M17.4992 8.25H15.0473C14.0691 8.25 13.5793 8.25 13.2053 8.05037C12.876 7.87461 12.6085 7.59359 12.4407 7.24863C12.25 6.85645 12.25 6.34343 12.25 5.31667V2.7508M7.875 12.8333L9.625 14.6667M3.5 19.25V16.9583L10.0625 10.0833L12.25 12.375L5.6875 19.25H3.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </IconButton>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          {exams.length > 6 && (  // Only show the button if there are more than 6 tasks
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Typography
                component="button"
                onClick={() => setShowMore(!showMore)}
                sx={{
                  color: 'white',
                  backgroundColor: '#6BBDBD',
                  border: 'none',
                  borderRadius: '20px',
                  padding: '8px 24px',
                  cursor: 'pointer',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: '#6BBDBD',
                    transform: 'translateY(-2px)',
                    boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
                  }
                }}
              >
                {showMore ? 'Mostrar menos' : 'Mostrar más'}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Tasks;
