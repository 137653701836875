import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogContent } from '@mui/material';

const DocumentLocked = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleSubscribe = () => {
    onClose();
    navigate('/plans');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="document-locked-dialog"
      aria-describedby="document-locked-description"
      sx={{ 
        width: '100%', 
        maxWidth: '500px', 
        margin: 'auto', 
        fontFamily: 'Inter, sans-serif',
        '& .MuiDialog-paper': {
          borderRadius: '24px',
        },
      }}
    >
      <DialogContent>
        <Box >
          <Typography variant="h6" sx={{ fontFamily: 'Inter, sans-serif', fontWeight: 'bold', fontSize: '26px', textAlign: 'center', lineHeight: '1.2', marginTop: '20px' }}>
            🔒 Este documento esta bloqueado
          </Typography>
          <Typography sx={{ fontFamily: 'Inter, sans-serif', textAlign: 'center', fontSize: '16px', fontWeight: '600', width: '80%', margin: 'auto', marginTop: '16px', marginBottom: '16px' }}>
            Como usuario gratuito sólo puedes subir 3 documentos
          </Typography>
          <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 'bold', marginLeft: '12px', textAlign: 'left', fontFamily: 'Inter, sans-serif', marginTop: '20px' }}>
            ¿Porqué deberías suscribirte a Typed?
          </Typography>
          <List >
            {[
              'Todas las preguntas desbloqueadas',
              'Estudia por temas',
              'Sube tantos documentos como quieras',
              'Sin límite de preguntas por examen',
              'Resume cualquier documento en segundos',
              'Sin límite de créditos diario',
              'Resuelve tus dudas 24/7 con Chat AI'
            ].map((text, index) => (
              <ListItem key={index} disablePadding>
                <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px' }}>
                  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8119 0C5.53644 0 0.449219 4.47715 0.449219 10C0.449219 15.5228 5.53644 20 11.8119 20C18.0873 20 23.1745 15.5228 23.1745 10C23.1672 4.47982 18.0842 0.00642897 11.8119 0Z" fill="#E2F1F1" />
                    <path d="M18.37 6.83337L11.8886 14.5742C11.734 14.7547 11.5035 14.873 11.2489 14.9024C10.9943 14.9319 10.7369 14.8701 10.5346 14.7309L5.90618 11.4742C5.49775 11.1866 5.43162 10.662 5.75846 10.3025C6.08531 9.94309 6.68136 9.88489 7.08978 10.1725L10.9493 12.89L16.8456 5.84754C17.0389 5.5922 17.376 5.45103 17.7231 5.48007C18.0702 5.50912 18.3711 5.70368 18.5063 5.98652C18.6415 6.26935 18.5892 6.59469 18.37 6.83337Z" fill="#6BBDBD" />
                  </svg>
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            onClick={handleSubscribe}
            sx={{
                backgroundColor: '#6BBDBD',
                marginTop: '20px',
                color: 'white',
                textTransform: 'none',
                fontWeight: '600',
                fontFamily: "'Inter', sans-serif",
                borderRadius: '24px',
                padding: '8px 24px',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                boxShadow: 'none',

                '&:hover': {
                  backgroundColor: '#6BBDBD',
                },
                width: '60%',
                maxWidth: 'none',
                margin: 'auto',
                marginBottom: '20px'
              }}
          >
            ¡Suscribirse!
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentLocked;
