import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditObjetivosPopup from '../EditObjetivosPopup';
import Diana from '../Diana.svg';
import { fetchUserInfo } from '../../Api/UserApi';
const Objetivos = ({ accessToken, user, allPointsData, userData }) => {

  const emoji = ['🎉', '😀', '😥', '😭'];

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [dailyGoal, setDailyGoal] = useState(userData?.objective_questions || 0);
  React.useEffect(() => {
    if (userData?.objective_questions !== undefined) {
      setDailyGoal(userData.objective_questions);
    }
  }, [userData]);

  const getEmoji = (score, dayIndex) => {
    const today = new Date().getDay();
    const currentDayIndex = today === 0 ? 6 : today - 1;


    // Return empty string for future days
    if (dayIndex > currentDayIndex) return '';
    // If it's current day and score is 0, return empty string
    if (dayIndex === currentDayIndex && score === 0) return '';

    if (score / dailyGoal >= 1) return emoji[0];
    if (score / dailyGoal >= 0.75) return emoji[1];
    if (score / dailyGoal >= 0.50) return emoji[2];
    return emoji[3];
  };

  // Add new function to handle point display
  const getPointDisplay = (points, index) => {
    const today = new Date().getDay();
    const currentDayIndex = today === 0 ? 6 : today - 1;

    return index > currentDayIndex ? "-" : points;
  };

  const handleSaveGoal = async (newGoal) => {
    try {

      await fetchUserInfo(accessToken, "PUT", { "objective_questions": newGoal }, user);
      setDailyGoal(newGoal);
    } catch (error) {
      console.error('Error updating goal:', error);
    }
  };

  // Modify the getBackgroundColor function to handle border styles
  const getBackgroundColor = (points, index) => {
    const today = new Date().getDay();
    const currentDayIndex = today === 0 ? 6 : today - 1;

    if (index > currentDayIndex) {
      return {
        backgroundColor: 'white',
        border: '2px solid #f8d7da'  // pinky color border
      };
    }

    return {
      backgroundColor: points >= 25 ? '#d1e7dd' :
        points >= 15 ? '#fff3e0' :
          points >= 5 ? '#fde2e2' :
            '#f8d7da',
      border: 'none'
    };
  };

  return (
    <Paper elevation={0} sx={{ p: 2, borderRadius: "20px", border: '1px solid #F0F4F4' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px', fontFamily: 'Fira Sans' }}>Tus objetivos</Typography>
          <IconButton
            onClick={() => setIsEditOpen(true)}
            sx={{ padding: '4px' }}
          >
            <EditIcon sx={{ fontSize: 16, color: '#A0A0A0' }} />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
          <img src={Diana} alt="edit" height="50px" width="50px" />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography sx={{ fontSize: '14px', fontFamily: 'Fira Sans', color: '#A0A0A0' }}>
              {(allPointsData?.find(data => data.isToday)?.points || 0)}/{dailyGoal}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontFamily: 'Fira Sans', color: '#A0A0A0' }}>Objetivo diario</Typography>
          </Box>
        </Box>
      </Box>

      <EditObjetivosPopup
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        currentGoal={dailyGoal}
        onSave={handleSaveGoal}
      />

      <Grid container spacing={1} sx={{ width: '100%' }}>
        {allPointsData?.map((dayData, index) => (
          <Grid item xs={12 / 7} key={index}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: '14px', fontFamily: 'Fira Sans', color: '#D9D9D9' }}>
                {dayData.date.substring(0, 1)}
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 35,
                height: 35,
                borderRadius: '50%',
                ...getBackgroundColor(dayData.points, index)
              }}>
                <Typography sx={{ fontSize: '20px' }}>
                  {getEmoji(dayData.points, index)}
                </Typography>
              </Box>
              <Typography sx={{ fontSize: '14px', fontFamily: 'Fira Sans', color: '#6BBDBD' }}>
                {getPointDisplay(dayData.points, index)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default Objetivos;
