import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import Premium from '../PopUpsAlerts/Premium/Premium';
import { useSubscription } from "../Context/SubscriptionContext";

const CustomizeExamModal = ({ onClose, onSave, documentStats, documentName, selectedDocuments, examCustomization, onCustomizationChange }) => {
  const { userData } = useSubscription();
  const isPremium = userData?.plan_name !== "Basic";

  const [showPremium, setShowPremium] = useState(false);
  const [premiumTrigger, setPremiumTrigger] = useState('');

  const handleQuestionCountChange = (event, newValue) => {
    if (!isPremium && newValue > 15) {
      setShowPremium(true);
      setPremiumTrigger('questions');
      return;
    }
    onCustomizationChange({
      ...examCustomization,
      questionCount: newValue
    });
  };

  const handleTypeChange = (type) => {
    if (!isPremium && type !== 'mix') {
      setShowPremium(true);
      setPremiumTrigger('topics');
      return;
    }

    let newTypes;
    if (type === 'mix') {
      newTypes = {
        memorized: !examCustomization.questionTypes.mix,
        learning: !examCustomization.questionTypes.mix,
        incorrect: !examCustomization.questionTypes.mix,
        notStarted: !examCustomization.questionTypes.mix,
        mix: !examCustomization.questionTypes.mix
      };
    } else {
      newTypes = {
        ...examCustomization.questionTypes,
        mix: false,
        [type]: !examCustomization.questionTypes[type]
      };
    }

    onCustomizationChange({
      ...examCustomization,
      questionTypes: newTypes
    });
  };

  const isSelectionValid = () => {
    if (examCustomization.questionTypes.mix) return true;

    return Object.entries(examCustomization.questionTypes).some(([type, isSelected]) => {
      if (!isSelected) return false;

      const count = type === 'memorized' ? totalStats?.memorized :
        type === 'learning' ? totalStats?.learning :
          type === 'incorrect' ? totalStats?.notKnown :
            type === 'notStarted' ? totalStats?.notStarted : 0;

      return count > 0;
    });
  };

  const handleStartExam = () => {
    if (!isSelectionValid()) return;

    onSave({
      questionCount: examCustomization.questionCount,
      questionTypes: examCustomization.questionTypes
    });
  };

  const calculateTotalStats = () => {
    if (!selectedDocuments) return null;

    const totals = {
      memorized: 0,
      learning: 0,
      notKnown: 0,
      notStarted: 0
    };

    selectedDocuments.forEach(doc => {
      totals.memorized += doc.status?.memorized || 0;
      totals.learning += doc.status?.learning || 0;
      totals.notKnown += doc.status?.notKnown || 0;
      totals.notStarted += doc.status?.notStarted || 0;
    });

    return totals;
  };

  const totalStats = calculateTotalStats();

  return (
    <Box sx={{ p: 2 }}>
      {showPremium && (
        <Premium
          onClose={() => setShowPremium(false)}
          titleText={premiumTrigger}
        />
      )}

      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          fontFamily: 'Fira Sans, sans-serif',
          color: 'black',
          textAlign: 'center',
          fontSize: '20px',
          marginBottom: '16px'
        }}
      >
        Personalizar test
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{
            fontSize: '14px',
            color: '#666',
            mb: 1,
            fontFamily: 'Inter, sans-serif'
          }}
        >
          {selectedDocuments?.length > 1 ?
            `${selectedDocuments.length} documentos seleccionados:` :
            'Documento seleccionado:'}
        </Typography>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          backgroundColor: '#F8F9FA',
          borderRadius: '8px',
          p: 2
        }}>
          {selectedDocuments?.map((doc) => (
            <Box
              key={doc.guid}
              sx={{
                backgroundColor: '#E2F1F1',
                borderRadius: '6px',
                padding: '4px 12px',
                fontSize: '14px',
                color: '#333',
                fontFamily: 'Inter, sans-serif'
              }}
            >
              {doc.title}
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontFamily: 'Fira Sans, sans-serif',
            textAlign: 'left',
            marginBottom: '20px'
          }}
        >
          1. Elige el número de preguntas ({examCustomization.questionCount})
        </Typography>
        <Slider
          value={examCustomization.questionCount}
          onChange={handleQuestionCountChange}
          min={1}
          max={200}
          valueLabelDisplay="auto"
          sx={{
            color: '#6BBDBD',
            '& .MuiSlider-thumb': {
              backgroundColor: '#fff',
              border: '2px solid #6BBDBD',
            },
          }}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            mt: 1,
            fontFamily: 'Inter, sans-serif'
          }}
        >
          {isPremium ?
            'Selecciona entre 1 y 200 preguntas' :
            'Selecciona entre 1 y 15 preguntas (Premium hasta 200)'}
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontFamily: 'Fira Sans, sans-serif',
            textAlign: 'left',
            marginBottom: '20px'
          }}
        >
          2. Elige el tipo de preguntas {!isPremium && '(Premium)'}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={examCustomization.questionTypes.mix}
                onChange={() => handleTypeChange('mix')}
                sx={{
                  color: '#6BBDBD',
                  '&.Mui-checked': {
                    color: '#6BBDBD',
                  },
                }}
              />
            }
            label={
              <Typography sx={{ fontFamily: 'Inter, sans-serif', fontSize: '14px' }}>
                Mezcla de todos los tipos
              </Typography>
            }
          />
          {['memorized', 'learning', 'incorrect', 'notStarted'].map((type) => (
            <Box
              key={type}
              sx={{
                display: 'flex',
                alignItems: 'center',
                opacity: !isPremium ? 0.7 : 1
              }}
            >
              <FormControlLabel
                disabled={!isPremium}
                control={
                  <Checkbox
                    checked={examCustomization.questionTypes[type]}
                    onChange={() => handleTypeChange(type)}
                    sx={{
                      color: type === 'memorized' ? '#00B564' :
                        type === 'learning' ? '#FFA726' :
                          type === 'incorrect' ? '#AA161D' :
                            '#666666',
                      '&.Mui-checked': {
                        color: type === 'memorized' ? '#00B564' :
                          type === 'learning' ? '#FFA726' :
                            type === 'incorrect' ? '#AA161D' :
                              '#666666',
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontFamily: 'Inter, sans-serif', fontSize: '14px' }}>
                    {type === 'memorized' ? `Correctas (${totalStats?.memorized || 0})` :
                      type === 'learning' ? `Aprendiendo (${totalStats?.learning || 0})` :
                        type === 'incorrect' ? `Incorrectas (${totalStats?.notKnown || 0})` :
                          `Sin empezar (${totalStats?.notStarted || 0})`}
                  </Typography>
                }
                sx={{ flex: 1 }}
              />
              {!isPremium && (
                <LockIcon
                  sx={{
                    fontSize: 16,
                    color: '#666',
                    ml: 1,
                    mr: 2
                  }}
                />
              )}
            </Box>
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default CustomizeExamModal; 