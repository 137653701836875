import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@material-ui/core';
import { getCsrfToken } from '../Api/TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStylesMarkQuestionError = makeStyles((theme) => ({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    dialog: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        borderRadius: '20px',
        fontFamily: 'Inter, sans-serif',
    },
    actions: {
        justifyContent: 'space-between',
        fontFamily: 'Inter, sans-serif',
        marginTop: '15px',
        marginBottom: '10px',
    },
    dialogTitle: {
        '& .MuiTypography-root': {
            fontFamily: 'Inter, sans-serif',
            fontSize: '18px',
            fontWeight: 600,
            color: '#333',
        },
    },
    markButton: {
        backgroundColor: '#D32F2F',
        color: 'white',
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: '20px',
        padding: '6px 16px',
        '&:hover': {
            backgroundColor: '#B71C1C',
        },
    },
    cancelButton: {
        backgroundColor: '#6BBDBD',
        color: 'white',
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: '20px',
        padding: '6px 16px',
        '&:hover': {
            backgroundColor: '#5AACAC',
        },
    },
}));

const Flag = ({ open, onClose, questionId, accessToken, user, setIsFlagged, handleTrueFlag }) => {
    const classes = useStylesMarkQuestionError();

    const markQuestionAsErroneous = async () => {
        try {
            setIsFlagged(true);
            const user_id = user['sub'];
            const apiUrl = `${BACKEND_URL}/api_v1/question-info/${questionId}/?user_id=${user_id}`;

            const csrfToken = getCsrfToken();
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            };

            if (csrfToken) {
                headers['X-CSRFToken'] = csrfToken;
            }

            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Call handleTrueFlag to update the flag status in the parent components
            handleTrueFlag();
        } catch (error) {
            console.error('Error marking question as erroneous:', error);
        }
        onClose();

    };

    const handleNo = () => {
        setIsFlagged(false);
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                root: classes.dialog,
                paper: classes.content,
            }}
            BackdropProps={{
                classes: {
                    root: classes.backdrop,
                },
            }}
        >
            <DialogTitle className={classes.dialogTitle}>
                Pregunta Errónea
            </DialogTitle>
            <DialogContent>
                <Typography style={{ marginBottom: '10px', fontFamily: 'Inter, sans-serif' }}>
                    ¿Quieres marcar la pregunta como <strong>errónea</strong>? Si lo haces no aparecerá en <strong>futuros exámenes</strong>.
                </Typography>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '40px', width: '100%' }}>
                    <Button onClick={markQuestionAsErroneous} className={classes.markButton}>
                        Sí, marcar
                    </Button>
                    <Button onClick={handleNo} className={classes.cancelButton}>
                        No, seguir con el examen
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default Flag;
