import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Tooltip, Skeleton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BookIcon from '@mui/icons-material/Book';
import { useSubscription } from "../Context/SubscriptionContext";
import LockIcon from '@mui/icons-material/Lock';

import { useNavigate } from 'react-router-dom';
import { keyframes } from '@mui/system';
import { getCsrfToken } from "../Api/TokenApi";
;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const DocumentSkeleton = () => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '32px',

            minHeight: '120px',
        }}
    >
        <Skeleton variant="circular" width={80} height={80} sx={{ marginRight: '20px' }} />
        <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="text" width="60%" height={24} sx={{ marginBottom: '8px' }} />
            <Skeleton variant="text" width="40%" height={20} />
        </Box>
    </Box>
);
// Add this keyframes definition outside of the component
const colorChange = keyframes`
  0% { color: #4ECDC4; }  // Light teal
  33% { color: #45B7A0; } // Seafoam green
  66% { color: #2AB7CA; } // Sky blue
  100% { color: #4ECDC4; } // Back to light teal
`;
const DocumentList = ({
    listDocuments,
    isLoading,
    selectedDocument,
    onDocumentSelect,
    selectable = true,
    box = true,
    setShowBlockedDocument,
    accessToken,

}) => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [visibleDocuments, setVisibleDocuments] = useState(5);

    const filteredDocuments = listDocuments?.filter(doc =>
        doc.title.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const { userData } = useSubscription();



    const itemColors = [
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/greenCard.svg", color: '#00B564', label: 'Preguntas Memorizadas' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/yellowCard.svg", color: '#FFA726', label: 'Preguntas Aprendiendo' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/redCar.svg", color: '#AA161D', label: 'Preguntas Incorrectas' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/grayCard.svg", color: '#D9D9D9', label: 'Preguntas Sin Empezar' },
        { image: "", color: '#2196F3', label: 'Preguntas Bloqueadas' }
    ];

    const getMemorizedChaptersText = (document) => {
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        return `${totalPoints} de ${totalMaxPoints} puntos de memoria🧠`;
    };

    const getProgress = (document) => {
        let total;
        if (userData?.plan_name !== "Basic" && !document.is_constitution)
            total = document.status.memorized + document.status.learning +
                document.status.notKnown + document.status.notStarted + document.status.blocked
        else
            total = document.status.memorized + document.status.learning +
                document.status.notKnown + document.status.notStarted;
        const percentageMemorized = total > 0 ?
            (document.status.memorized / total) * 100 : 0;
        const result = percentageMemorized.toFixed(0);
        return isNaN(result) || result === 'null' ? '0' : result;
    };

    const getProgressTopic = (document) => {
        if (userData?.plan_name !== "Basic" && !document.is_constitution)
            return { memorized: document.status.memorized, learning: document.status.learning, notKnown: document.status.notKnown, notStarted: document.status.notStarted, blocked: 0 };

        // For Basic users, calculate blocked questions
        const totalQuestions = document.pages * 12;
        const availableQuestions = document.status.memorized + document.status.learning + document.status.notKnown + document.status.notStarted;
        const blockedQuestions = totalQuestions - availableQuestions;

        return {
            memorized: document.status.memorized,
            learning: document.status.learning,
            notKnown: document.status.notKnown,
            notStarted: document.status.notStarted,
            blocked: blockedQuestions
        };
    };

    const handleBoxClick = (document, event) => {
        if (!box) {
            event.stopPropagation();
            if (!document.is_blocked) {
                navigate(`/${document.folder}/${document.guid}`);
            } else {
                setShowBlockedDocument(true)
            }
        } else if (selectable) {
            onDocumentSelect(document);
        }
    };

    const handleGenerateQuestions = async (folderId, documentGuid) => {
        try {

            const response = await fetch(`${BACKEND_URL}/api_v1/generation_questions/${documentGuid}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    'X-CSRFToken': getCsrfToken()
                },
                body: JSON.stringify({
                    "needGenerateQuestions": true
                })
            });

            if (response.ok) {
                const data = await response.json();

                navigate(`/${folderId}/${documentGuid}`);
                // You might want to update the questions state here or trigger a re-fetch
                // For example: setQuestions({ ...questions, is_generating: true });
            } else {
                throw new Error('Failed to start question generation');
            }
        } catch (error) {
            console.error("Error generating questions:", error);

        }
    };

    const handleShowMoreDocuments = () => {
        setVisibleDocuments(prev => prev + 5);
    };

    const content = (
        <>
            <Box sx={{ marginBottom: '20px', padding: '0 32px' }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Buscar documentos..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '45px',
                            backgroundColor: 'white',
                            '& fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.38)',
                            },
                            '&:hover fieldset': {
                                borderColor: 'rgba(0, 0, 0, 0.38)',
                            },
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            {isLoading ? (
                Array.from({ length: 3 }).map((_, index) => (
                    <DocumentSkeleton key={index} />
                ))
            ) : filteredDocuments.length === 0 ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '32px',
                    minHeight: '10px'
                }}>
                    <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" width="120" height="120" />
                    <Typography sx={{ mt: 2, color: '#666', fontSize: '16px' }}>
                        {searchQuery ? 'No se encontraron documentos' : 'Debes subir primero un documento'}
                    </Typography>
                </Box>
            ) : (
                filteredDocuments.slice(0, visibleDocuments).map((document, index) => (
                    <Box
                        key={document.guid}
                        onClick={(event) => handleBoxClick(document, event)}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '32px',
                            borderRadius: '24px',
                            borderBottom: index < filteredDocuments.length - 1 ? 'none' : 'none',
                            minHeight: '120px',
                            backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : 'white',
                            cursor: box ? (selectable ? 'pointer' : 'default') : 'pointer',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                                backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : '#E2F1F1',
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                backgroundColor: '#E2F1F1',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: '20px',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '80px',
                                    height: '80px',
                                }}
                            >
                                <CircularProgress
                                    variant="determinate"
                                    value={getProgress(document)}
                                    size={80}
                                    thickness={4}
                                    sx={{
                                        color: '#6BBDBD',
                                        '& .MuiCircularProgress-circle': {
                                            strokeLinecap: 'round',
                                        },
                                        '& .MuiCircularProgress-circleBackground': {
                                            stroke: '#E2F1F1',
                                        },
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '60px',
                                        height: '60px',
                                        borderRadius: '50%',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: '#6BBDBD',
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {getProgress(document)}%
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ flexGrow: 1, textAlign: 'left', marginLeft: '20px' }}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    fontFamily: 'Fira Sans',
                                    color: '#333',
                                    marginBottom: '8px',
                                }}
                            >
                                {document.title.length > 30 ? (
                                    <Tooltip title={document.title}>
                                        <span>{document.title.substring(0, 30)}...</span>
                                    </Tooltip>
                                ) : (
                                    document.title
                                )}

                                {/* {userData?.plan_name === "Basic" && !document.is_constitution && (
                                    < Tooltip title={`Tienes ${((document.status.blocked) / (document?.pages * 12) * 100)}% de preguntas bloqueadas`}>
                                        <LockIcon sx={{
                                            fontSize: '16px',
                                            color: 'gray',
                                            verticalAlign: 'middle',
                                            marginLeft: '8px'
                                        }} />
                                    </Tooltip>
                                )} */}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4px',
                            }}>
                                <Box sx={{
                                    width: '250px',
                                    height: '10px',
                                    backgroundColor: '#F5F5F5',
                                    borderRadius: '6px',
                                    display: 'flex',
                                    overflow: 'hidden'
                                }}>
                                    {Object.entries(getProgressTopic(document)).map(([key, value], i) => {
                                        const total = Object.values(getProgressTopic(document))
                                            .reduce((sum, curr) => sum + curr, 0);
                                        const percentage = (value / total) * 100;

                                        return (
                                            <Tooltip
                                                key={key}
                                                title={`${itemColors[i].label}: ${value}`}
                                                arrow
                                            >
                                                <Box
                                                    sx={{
                                                        width: `${percentage}%`,
                                                        height: '100%',
                                                        backgroundColor: itemColors[i].color,
                                                        transition: 'width 0.3s ease'
                                                    }}
                                                />
                                            </Tooltip>
                                        );
                                    })}
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'Inter',
                                        color: '#666',
                                    }}
                                >
                                    {userData?.plan_name === "Basic" && !document.is_constitution ? (
                                        <>
                                            {document.status.memorized} de {document.pages * 12} preguntas correctas
                                        </>
                                    ) : (
                                        <>
                                            {document.status.memorized} de {Object.values(document.status).reduce((sum, curr) => sum + curr, 0)} preguntas correctas
                                        </>
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        {
                            document.is_blocked ? (
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#AA161D',
                                        color: 'white',
                                        textTransform: 'none',
                                        fontWeight: '600',
                                        fontFamily: "'Fira Sans', sans-serif",
                                        borderRadius: '24px',
                                        padding: '8px 24px',
                                        fontSize: '14px',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: '#8B1217',
                                        }
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // Add your unblock logic here
                                        setShowBlockedDocument(true)
                                    }}
                                >
                                    Desbloquear
                                </Button>
                            ) : document.is_error_generating ? (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start', // Aligns content to the left
                                    flexGrow: 1,
                                    gap: '32px',
                                }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#666' }}>
                                        Error generando preguntas
                                    </Typography>
                                </Box>
                            ) : document.is_generating ? (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start', // Aligns content to the left
                                    flexGrow: 1,
                                    gap: '32px',
                                }}>
                                    <Typography sx={{ fontWeight: 'bold', animation: `${colorChange} 2s ease-in-out infinite` }}>
                                        {'Generando preguntas...'.split('').map((char, index) => (
                                            <span
                                                key={index}
                                                sx={{  // Change this from 'style' to 'sx'
                                                    display: 'inline-block !important',
                                                    animation: `${colorChange} 2s ease-in-out infinite !important`,
                                                    animationDelay: `${index * 0.1}s !important`,
                                                }}
                                            >
                                                {char}
                                            </span>
                                        ))}
                                    </Typography> </Box>
                            ) : !document.questions_generated ? (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start', // Aligns content to the left
                                    flexGrow: 1,
                                    gap: '32px',
                                }}>


                                </Box>
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start', // Aligns content to the left
                                    flexGrow: 1,
                                    gap: '32px',
                                }}>
                                    {!document.is_blocked && document.questions_generated && (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            flexGrow: 1,
                                            gap: '32px',
                                        }}>
                                        </Box>
                                    )}
                                </Box>
                            )
                        }
                        {!box && !document.is_blocked && !document.is_generating && <Box sx={{ display: 'flex', gap: '8px', marginLeft: "20px", alignItems: 'center' }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px'
                            }}>
                                {document.pending_tasks ? (
                                    <>
                                        <WarningAmberIcon
                                            sx={{
                                                color: '#FF6B6B',
                                                fontSize: '20px'
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                textDecoration: 'underline',
                                                marginRight: '16px',
                                                fontSize: '14px',
                                                fontFamily: "'Inter', sans-serif",
                                                color: '#FF6B6B',
                                                fontWeight: '500'
                                            }}
                                        >
                                            Tareas pendientes
                                        </Typography>
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                            </Box>
                            <Button
                                variant="contained"
                                sx={{
                                    display: { xs: 'none', lg: 'flex' },
                                    backgroundColor: 'white',
                                    color: '#6BBDBD',
                                    border: '1px solid #6BBDBD',
                                    textTransform: 'none',
                                    fontWeight: '600',
                                    fontFamily: "'Fira Sans', sans-serif",
                                    borderRadius: '24px',
                                    padding: '6px 22px',
                                    fontSize: '14px',
                                    alignItems: 'center',
                                    gap: '8px',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: '#6BBDBD',
                                        color: 'white',
                                    }
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/${document.folder}/${document.guid}`);
                                }}
                            >
                                <BookIcon sx={{ fontSize: 20 }} />
                                Material
                            </Button>
                        </Box>}
                    </Box>
                ))
            )}

            {
                visibleDocuments < filteredDocuments.length && (
                    <Button
                        onClick={handleShowMoreDocuments}
                        style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: '600',
                            fontFamily: "'Fira Sans', sans-serif",
                            color: 'white',
                            backgroundColor: '#6BBDBD',
                            padding: '8px 16px',
                            borderRadius: '24px',
                            boxShadow: 'none',
                            marginTop: '16px',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        Mostrar más
                    </Button>
                )
            }
        </>
    );

    return box ? (
        <Box sx={{ marginTop: '30px', marginBottom: '40px', backgroundColor: 'white', overflowX: 'hidden' }}>
            <Box className="custom-scrollbar" sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                {content}
            </Box>
        </Box>
    ) : content;
};

export default DocumentList;
