import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { fetchUserAllFiles } from '../Api/FilesApi';
import { useAuth } from "../Auth/Authhandle";
import PDFViewerComponent from '../Documents/Viewers/PdfViewer';
import { createSchema } from '../Api/SchemasApi';
import { useCredits } from "../Context/CreditsContext";
import NeedCredits from '../NoCreditsModal/NeedCredits';
const PopupGenerarEsquema = ({ open, onClose, onSchemaCreated }) => {
  const [text, setText] = useState(
    'EJEMPLO DE ENTRADA PARA CREAR UN ESQUEMA:\n\n' +
    'La Constitución Española de 1978 es la norma suprema del ordenamiento jurídico español. ' +
    'Se estructura en: un preámbulo, un título preliminar y diez títulos numerados. ' +
    'Los principios fundamentales incluyen que España es un Estado social y democrático de Derecho, ' +
    'la soberanía nacional reside en el pueblo español y la forma política es la Monarquía parlamentaria. ' +
    'Los valores superiores son: libertad, justicia, igualdad y pluralismo político. ' +
    'El castellano es la lengua oficial del Estado, y las demás lenguas españolas serán también oficiales en sus respectivas Comunidades Autónomas.'
  );
  const [title, setTitle] = useState('');
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [selectedChapter, setSelectedChapter] = useState('');
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);
  const { accessToken, user } = useAuth(); // Use the hook at the top level
  const { updateCredits, credits } = useCredits();
  const [openPremium, setOpenPremium] = useState(false);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {

        const response = await fetchUserAllFiles(accessToken, "GET", user);
        const filteredDocuments = response.documents.filter(doc => !doc.is_blocked);
        setDocuments(filteredDocuments);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    if (open) {
      fetchDocuments();
    }
  }, [open, accessToken, user]);

  // Reset form when dialog closes
  useEffect(() => {
    if (!open) {
      setText('EJEMPLO DE ENTRADA PARA CREAR UN ESQUEMA:\n\n' +
        'La Constitución Española de 1978 es la norma suprema del ordenamiento jurídico español. ' +
        'Se estructura en: un preámbulo, un título preliminar y diez títulos numerados. ' +
        'Los principios fundamentales incluyen que España es un Estado social y democrático de Derecho, ' +
        'la soberanía nacional reside en el pueblo español y la forma política es la Monarquía parlamentaria. ' +
        'Los valores superiores son: libertad, justicia, igualdad y pluralismo político. ' +
        'El castellano es la lengua oficial del Estado, y las demás lenguas españolas serán también oficiales en sus respectivas Comunidades Autónomas.'
      );
      setTitle('');
      setSelectedDocument('');
      setSelectedChapter('');
      setTopics([]);
    }
  }, [open]);

  // Validate form fields
  const isFormValid = () => {
    return (
      selectedDocument &&
      title.trim() !== '' &&
      text.trim() !== ''
    );
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText.slice(0, 3500));
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDocumentChange = (event) => {
    const docId = event.target.value;
    setSelectedDocument(docId);

    // Find the selected document and get its main_topics
    const selectedDoc = documents.find(doc => doc.guid === docId);
    if (selectedDoc && selectedDoc.main_topics) {
      setTopics(selectedDoc.main_topics);
    } else {
      setTopics([]);
    }

    // Reset selected chapter/topic when document changes
    setSelectedChapter('');
  };

  const handleChapterChange = (event) => {
    setSelectedChapter(event.target.value);
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const payload = {
        "title": title,
        "text": text,
        "document_id": selectedDocument,
        "chapter": selectedChapter,
      };

      const response = await createSchema(accessToken, user, selectedDocument, payload);
      updateCredits()
      if (onSchemaCreated) {
        onSchemaCreated(response);
      }
      setIsLoading(false);
      onClose();

    } catch (error) {
      console.error('Error generating schema:', error);
      setIsLoading(false);
      setErrorMessage(true);
    }
  };

  // Add useEffect for progress bar
  useEffect(() => {
    let interval;
    if (isLoading) {
      // Start at 0
      setProgress(0);
      // Update every 450ms to complete in ~45 seconds
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          // Increment by ~2.2 to reach 100 in 45 seconds
          const newProgress = oldProgress + 2.2;
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 450);

      // Clean up interval after 45 seconds
      setTimeout(() => {
        clearInterval(interval);
      }, 45000);
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  if (openPremium) {
    return <NeedCredits />
  }
  // Update loading dialog
  if (isLoading || errorMessage) {
    return (
      <Dialog
        open={true}
        PaperProps={{
          style: {
            borderRadius: '20px',
            padding: '40px',
            textAlign: 'center',
            width: '350px'
          }
        }}
      >
        {errorMessage ? (
          <>
            <IconButton
              aria-label="close"
              onClick={() => { setIsLoading(false); setErrorMessage(false); onClose() }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: '600',
                marginBottom: '20px',
                whiteSpace: 'pre-line'
              }}
            >
              Ha habido un error al generar es esquema.
              Intentalo de nuevo
            </Typography>
          </>
        ) : (
          <>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: '600',
                marginBottom: '20px'
              }}
            >
              Estamos generando tu esquema
            </Typography>
            <Box sx={{ width: '100%', my: 2 }}>
              <Box sx={{
                width: '100%',
                height: '10px',
                bgcolor: '#8db8c2',
                borderRadius: '6px',
                overflow: 'hidden'
              }}>
                <Box sx={{
                  width: `${progress}%`,
                  height: '100%',
                  bgcolor: '#026277',
                  transition: 'width 0.5s ease-in-out'
                }} />
              </Box>
              <Typography style={{
                fontWeight: 500,
                color: "gray",
                fontSize: "12px",
                marginTop: '10px'
              }}>
                Este proceso puede tardar unos segundos
              </Typography>
            </Box>
          </>
        )}
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '20px',

          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
        }
      }}
    >
      <DialogTitle style={{ fontFamily: 'Inter', fontWeight: 'bold', paddingRight: '48px' }}>
        <Box display="flex" alignItems="center">
          <AccountTreeIcon style={{ color: 'black', marginRight: '8px' }} />
          <Typography style={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: '16px' }}>
            Generar un nuevo esquema
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ height: '100%' }}>
          <Grid item xs={6}>
            {/* Left column content */}
            <Box sx={{ marginBottom: "20px" }}>
              <Typography gutterBottom style={{ fontFamily: 'Inter', fontSize: '14px', marginBottom: '10px' }}> Elige el documento y el capítulo sobre el que quieres generar el esquema:</Typography>
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  {/* Document dropdown */}
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="document-select-label"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        backgroundColor: 'white',
                        px: 1,
                      }}
                    >
                      Selecciona un documento
                    </InputLabel>
                    <Select
                      labelId="document-select-label"
                      id="document-select"
                      value={selectedDocument}
                      onChange={handleDocumentChange}
                      label="Selecciona un documento"
                      sx={{
                        height: '45px',
                        borderRadius: '8px',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        '& .MuiSelect-select': {
                          padding: '8px 14px',
                          fontSize: '14px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#e0e0e0',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#6BBDBD',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#6BBDBD',
                        }
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 300,
                            '& .MuiMenuItem-root': {
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              padding: '10px 14px',
                              '&:hover': {
                                backgroundColor: '#f5f5f5',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#e8f4f4',
                                '&:hover': {
                                  backgroundColor: '#dceeee',
                                }
                              }
                            }
                          }
                        }
                      }}
                    >
                      {documents.map((doc) => (
                        <MenuItem key={doc.guid} value={doc.guid}>
                          {doc.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
            </Box>
            <Box>
              {/* Title TextField */}
              <Typography gutterBottom style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                Introduce un título para tu esquema:
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={title}
                onChange={handleTitleChange}
                placeholder="Título del esquema"
                sx={{
                  fontFamily: "Inter",
                  marginBottom: "16px",
                  '& .MuiInputBase-root': {
                    height: "40px",
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                    fontFamily: 'Inter',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '14px',
                    fontFamily: 'Inter',
                  },
                }}
              />
            </Box>
            <Box>
              {/* Text TextField */}
              <Typography variant="body1" gutterBottom style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                Introduce a continuación el texto sobre el que quieres generar el esquema.
              </Typography>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                rows={17}
                value={text}
                onChange={handleTextChange}
                placeholder="Puedes introducir hasta 3500 caracteres."
                sx={{
                  '& .MuiInputBase-root': {
                    maxHeight: '1000px',
                    overflowY: 'auto',
                    fontSize: '12px',
                    fontFamily: 'Inter',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#f1f1f1',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#888',
                      borderRadius: '4px',
                      '&:hover': {
                        background: '#666',
                      },
                    },
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                    fontFamily: 'Inter',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '14px',
                    fontFamily: 'Inter',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e0e0e0',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#6BBDBD',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#6BBDBD',
                  },
                }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="15px">
              {/* Character count and chapter info */}
              <Typography
                color={text.length === 3500 ? "error" : "textSecondary"}
                style={{
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  fontWeight: '600'
                }}
              >
                {text.length} de 3500 caracteres
              </Typography>
              <Typography color="textSecondary" style={{ fontSize: '14px', fontFamily: 'Inter' }}>
                El esquema se guardará en el capítulo {selectedChapter}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {/* Right column - Gray rectangle */}
            <Box
              sx={{
                backgroundColor: '#f0f0f0',
                height: '100%',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selectedDocument ? (<PDFViewerComponent small={true} docInfo={documents.find(doc => doc.guid === selectedDocument)} token={accessToken} />) : (

                <Typography variant="body1" color="textSecondary">
                  Preview will appear here
                </Typography>

              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingRight: '24px', paddingBottom: '30px', marginTop: '-20px' }}>
        <Button
          onClick={handleGenerate}
          color="primary"
          variant="contained"
          disabled={!isFormValid()}
          style={{
            fontFamily: 'Inter',
            textTransform: 'none',
            backgroundColor: isFormValid() ? '#6BBDBD' : '#e0e0e0',
            color: 'white',
            borderRadius: '20px',
            padding: '8px 24px',
            fontSize: '14px',
            width: '150px',
            fontWeight: '600',
            boxShadow: "none"
          }}
        >
          ¡Generar!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupGenerarEsquema;
