import { makeStyles } from "@material-ui/core/styles";

const useChatViewStyles = makeStyles((theme) => ({
    gridContainer: {
        flexWrap: "nowrap",
        display: "flex",
        overflowY: "hidden",
        justifyContent: "center", // Add this line
        maxWidth: "none", // Add this line
        margin: "0px", // Add this lin        // Other styles...
        height: "100%"
    },

    leftPart: {
        maxWidth: "initial",
        flexBasis: '50vw', // Add this line

        [theme.breakpoints.down('1700')]: {
            flexBasis: '50vw', // Adjust the line
        },
        [theme.breakpoints.down('1800')]: {
            flexBasis: '50vw', // Adjust the line
        },
        [theme.breakpoints.up('1800')]: {
            flexBasis: '50vw', // Adjust the line
        },
        [theme.breakpoints.up('2000')]: {
            flexBasis: '60vw', // Adjust the line
        },
        [theme.breakpoints.down('1600')]: {
            flexBasis: '60vw', // Adjust the line
        },

    },
    rightPart: {
        overflowX: 'hidden',
        flexBasis: '46vw', // Sets the base size of the left part
        [theme.breakpoints.up('2000')]: {
            flexBasis: '46vw',
        },
        [theme.breakpoints.down('1500')]: {
            flexBasis: '46vw', // Sets the base size of the left part
        },
    },
    paperright: {
        padding: "20px",
        marginRight: "10px",
        height: '100%',  // Make Paper take full height of its container
        display: 'flex',
        flexDirection: 'column',  // Ensures the children (like Chat) also stretch properly
        backgroundColor: "#F8FFFF",
        boxShadow: 'none',
        paddingRight: "1px",
        paddingTop: "0px"
        // padding: ({ isDocument }) => !isDocument ? "1px solid #6BBDBD" : "none", // Add this line

    },
    paper: {
        padding: "20px 40px 20px 40px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        border: "none", // Add this line
        borderBottom: "none", // Add this line
        border: "1px solid #6BBDBD",
        // borderRadius: ({ isDocument }) => !isDocument ? "20px" : "none", // Add this line
        borderTopLeftRadius: "20px",// Add this line
        borderTopRightRadius: "20px" // Add this line
    },
    chatFixed: {
        position: "fixed",
        bottom: "10px", // Adjust as needed
        right: "10px", // Adjust as needed
        width: "25vw", // Adjust as needed
        maxHeight: "100%", // Adjust as needed
        overflowY: "auto", // To allow internal scrolling of the chat
        zIndex: 1000, // Make sure the chat is above other elements
        // ... other necessary styles
    },
    importantPart: {
        position: 'fixed',
        paddingRight: "30px",
        width: '-webkit-fill-available',
        zIndex: 10,
        height: '-webkit-fill-available', // Full height of its container
        display: 'flex', // Add flex display
        flexDirection: 'column', // Stack children vertically
    },
    breadcrumbContainer: {
        position: 'sticky',
        top: 0, // Stick to the top of the viewport
        zIndex: 1100, // Higher index to ensure it's above other content
        padding: theme.spacing(2), // Uniform padding
        width: '100%', // Full width
    },
    summaryContainer: {
        overflowY: "auto", // Only the summary content will scroll
        height: "100%", // You might need to adjust this depending on your layout
    },
    titleAndButtonContainer: {
        justifyContent: 'space-between',
        display: "flex",
        marginBottom: 10,
        marginTop: 15,
        flexShrink: 0,
    },
    chatContainer: {
        flex: 1, // This will allow the chat to grow and fill the space
        display: 'flex', // Ensure it is a flex container
        flexDirection: 'column', // Stack children vertically
        paddingBottom: "20px"
    },
    folderButton: {
        width: 'fit-content',
        height: '35px',
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 2,
        paddingBottom: 2,
        background: 'white',
        borderRadius: 12,
        border: '1px #6BBDBD solid',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex',
        textTransform: 'none',
        color: '#6BBDBD',
        fontSize: 12,
        fontFamily: 'Inter',
        fontWeight: '400',
        marginRight: 5,
        wordWrap: 'break-word',
        "&.active": {
            backgroundColor: '#6BBDBD',
            color: 'white',
        },
        marginBottom: "8px"
    },
    moreButton: {
        padding: '6px 12px',
        margin: '4px',
        fontSize: '14px',
        textTransform: 'none',
        color: '#6BBDBD',
        border: '1px solid #6BBDBD',
        borderRadius: '12px',
        backgroundColor: 'white',
    },
    dropdownMenu: {
        marginTop: theme.spacing(1),
    },
}));

export default useChatViewStyles;
