import { makeStyles } from '@material-ui/core/styles';

const useExamViewStyles = makeStyles((theme) => ({
    // gridContainer: {
    //     height: '100vh',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'stretch',
    //     margin: '0 auto',
    //     maxWidth: '100%',
    // },
    gridContainer: {
        flexWrap: "nowrap",
        display: "flex",
        overflowY: "hidden",
        justifyContent: "center", // Add this line
        maxWidth: "none", // Add this line
        margin: "0px", // Add this lin        // Other styles...
        // height: "100%"
        // height: "92vh",
        paddingBottom: "100px",
        height: "95vh",
        paddingBottom: "20px"
    },
    memorizedChip: {
        marginLeft: '16px !important', // Custom spacing value
        fontFamily: 'Inter, sans-serif !important',
        backgroundColor: '#D9F9D4 !important',
        fontWeight: "bold",
        color: '#00B564 !important',
    },

    learningChip: {
        marginLeft: '16px !important', // Custom spacing value
        fontFamily: 'Inter, sans-serif !important',
        backgroundColor: '#FFECCF !important',
        fontWeight: "bold",
        color: '#FCAB32 !important',
    },

    wrongChip: {
        marginLeft: '16px !important', // Custom spacing value
        fontFamily: 'Inter, sans-serif !important',
        backgroundColor: '#F49296 !important',
        fontWeight: "bold",
        color: '#AA161D !important',
    },

    sinEmpezarChip: {
        marginLeft: '16px !important', // Custom spacing value
        fontFamily: 'Inter, sans-serif !important',
        backgroundColor: '#E4E4E4wro !important',
        fontWeight: "bold",
        color: '#A0A0A0 !important',
    },

    leftPart: {
        maxWidth: "initial",
        flexBasis: '60vw', // Add this line

        [theme.breakpoints.down('1700')]: {
            flexBasis: '50vw', // Adjust the line
        },
        [theme.breakpoints.down('1800')]: {
            flexBasis: '50vw', // Adjust the line
        },
        [theme.breakpoints.up('1800')]: {
            flexBasis: '50vw', // Adjust the line
        },
        [theme.breakpoints.up('2000')]: {
            flexBasis: '60vw', // Adjust the line
        },
        [theme.breakpoints.down('1600')]: {
            flexBasis: '60vw', // Adjust the line
        },

    },
    paperright: {
        padding: "20px",
        marginRight: "10px",
        height: '100%',  // Make Paper take full height of its container
        display: 'flex',
        flexDirection: 'column',  // Ensures the children (like Chat) also stretch properly
        backgroundColor: "none",
        boxShadow: 'none',
        paddingRight: "1px",
        paddingTop: "0px",
        paddingBottom: "1px"


        // padding: ({ isDocument }) => !isDocument ? "1px solid #6BBDBD" : "none", // Add this line

    },
    rightPart: {
        overflowX: 'hidden',
        flexBasis: '34vw', // Sets the base size of the left part
        [theme.breakpoints.up('2000')]: {
            flexBasis: '46vw',
        },
        [theme.breakpoints.down('1500')]: {
            flexBasis: '46vw', // Sets the base size of the left part
        },
    },
    step3Container: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: theme.zIndex.modal + 1,
    },
    step3Content: {
        textAlign: 'center',
        // backgroundColor: '#fff',
        padding: theme.spacing(3),
        // borderRadius: '8px',
        // boxShadow: theme.shadows[5],
    },
    step3Image: {
        maxWidth: '400px',
        marginBottom: theme.spacing(2),
    },
    buttonUpload: {
        backgroundColor: "#026277",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "30px",

        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#026277', // Gray color for the disabled state
            color: "white",
            // padding: "8px 14px", // Added padding for better appearance

        }
    },

    buttonNewExam: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "flex-end",
        gap: "5px",
        // width: "30px",

        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",
            // padding: "8px 14px", // Added padding for better appearance

        }
    },


    paper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    paperLeft: {
        padding: "20px 40px 20px 40px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        border: "none", // Add this line
        border: "1px solid #F0F4F4",
        // borderRadius: ({ isDocument }) => !isDocument ? "20px" : "none", // Add this line
        borderRadius: "20px"
    },
    paperRight: {
        height: "calc(100% - 100px)",
        display: 'flex',
        flexDirection: 'column',
        boxShadow: "none",
        flexGrow: 1, // This will make the paper fill the height of its container

    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
}));

export default useExamViewStyles;
