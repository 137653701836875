import React from 'react';
import { Excalidraw } from "@excalidraw/excalidraw";
import { useState, useEffect } from 'react';
import { parseMermaidToExcalidraw, convertToExcalidrawElements } from '@excalidraw/excalidraw';




const DEFAULT_FONT_SIZE = 16; // Set your desired font size

const ExcalidrawBoard = () => {
    const [excalidrawData, setExcalidrawData] = useState(null);


    const diagramDefinition = `
      graph TD;
      A[Se requiere Movilidad Funcional] --> B{¿Funciones superiores o inferiores?};
      B -->|Superiores| C{¿Duración > 6 meses en 1 año o 8 meses en 2 años?};
      B -->|Inferiores| D[Mantener salario de origen];
      C -->|Sí| E[Trabajador puede reclamar ascenso];
      C -->|No| F[Retribución correspondiente a funciones realizadas];
      E --> G{¿Lo permite el convenio colectivo?};
      G -->|Sí| H[Se concede el ascenso];
      G -->|No| I[Cubrir vacante según reglas de la empresa];
      B --> J{¿Existen razones técnicas u organizativas?};
      J -->|Sí| K[Movilidad permitida por tiempo imprescindible];
      J -->|No| L[Movilidad no permitida];
      K --> M[Empresario debe comunicar a representantes];
      A --> N{¿Cambios no incluidos en este artículo?};
      N -->|Sí| O[Requiere acuerdo entre las partes];
      N -->|No| P[Seguir reglas para modificaciones sustanciales];
      A --> Q[Empresa no puede alegar ineptitud o falta de adaptación];
      E --> R{¿Empresa niega el ascenso?};
      R -->|Sí| S[Trabajador puede reclamar en jurisdicción social];
      R -->|No| H;
    `;
  
    useEffect(() => {
      const convertMermaidToExcalidraw = async () => {
        try {
          // Parse the Mermaid diagram
          const { elements, files } = await parseMermaidToExcalidraw(diagramDefinition, {
            fontSize: DEFAULT_FONT_SIZE,
          });
  
          // Convert to fully qualified Excalidraw elements
          const excalidrawElements = convertToExcalidrawElements(elements);
  
          // Set the Excalidraw data (elements and files)
          setExcalidrawData({
            elements: excalidrawElements,
            appState: {
              viewModeEnabled: false,
              zenModeEnabled: false,
              gridSize: null,
              
            },
            files,
          });

        } catch (e) {
          console.error('Error converting Mermaid to Excalidraw:', e);
        }
      };
  
      convertMermaidToExcalidraw();
    }, [diagramDefinition]);
  return (
    <div style={{ width: '100%', height: '90vh' }}>
    {excalidrawData ? (
     /* <Excalidraw
        ref={excalidrawRef} // Attach ref to Excalidraw component
        initialData={excalidrawData}
        UIOptions={{
          canvasActions: {
            export: false,
            loadScene: false,
            saveAsImage: false,
            saveScene: false,
            clearCanvas: false,
            fitToContent: true,
            changeViewBackgroundColor: false,
          },
        }}
      />*/
      <Excalidraw
      initialData={excalidrawData}
    />
    ) : (
      <p>Loading diagram...</p>
    )}
  </div>
  );
};

export default ExcalidrawBoard;

