import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import Lottie from 'lottie-react';
import animationData from '../Images/singing-contract.json';


const WaitingQuestions = ({ handleGenerateQuestions }) => {
  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Lottie
          animationData={animationData}
          style={{
            width: 200,
            height: 200,

          }}
        />
      </Box>
      <Typography variant="h6" align="center" sx={{
        fontFamily: 'Inter, sans-serif',
        marginBottom: "20px",
        '@media (max-width: 1200px)': {
          fontSize: '0.875rem', // Smaller font size
        }
      }}>
        Haz click aquí para generar preguntas sobre tu documento
      </Typography>
      <Button sx={{
        backgroundColor: '#6BBDBD',
        color: 'white',
        textTransform: 'none',
        fontWeight: '600',
        fontFamily: "'Inter', sans-serif",
        borderRadius: '24px',
        padding: '8px 24px',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#6BBDBD',
        },
        width: '250px',
        maxWidth: 'none',
        margin: 'auto',
        '@media (max-width: 1200px)': {
          fontSize: '14px', // Smaller font size
          padding: '6px 20px', // Smaller padding
          width: '200px', // Smaller width
        }
      }} onClick={handleGenerateQuestions}>Generar preguntas</Button>
    </Container>
  );
};

export default WaitingQuestions;
