import { makeStyles } from "@material-ui/core/styles";

const useMainStructureStyles = makeStyles((theme) => ({
    gridContainer: {
        flexWrap: "nowrap",
        display: "flex",
        overflowY: "hidden",
        justifyContent: ({ isDocument }) => !isDocument ? "center" : "flex-start", // Add this line
        maxWidth: ({ isDocument }) => !isDocument ? "1000px" : "none", // Add this line
        margin: ({ isDocument }) => !isDocument ? "auto" : "none", // Add this lin        // Other styles...

    },

    leftPart: {
        paddingRight: ({ isDocument }) => !isDocument ? "0" : "60px", // Add this line
        maxWidth: "initial",
        flexBasis: ({ isDocument }) => !isDocument ? '100%' : '50vw', // Add this line
        height: ({ isDocument }) => !isDocument ? "100%" : "90vh", // Add this line

        [theme.breakpoints.down('1700')]: {
            flexBasis: ({ isDocument }) => !isDocument ? '100%' : '50vw', // Adjust the line
        },
        [theme.breakpoints.down('1800')]: {
            flexBasis: ({ isDocument }) => !isDocument ? '100%' : '50vw', // Adjust the line
        },
        [theme.breakpoints.up('1800')]: {
            flexBasis: ({ isDocument }) => !isDocument ? '100%' : '50vw', // Adjust the line
        },
        [theme.breakpoints.up('2000')]: {
            flexBasis: ({ isDocument }) => !isDocument ? '100%' : '60vw', // Adjust the line
        },
        [theme.breakpoints.down('1600')]: {
            flexBasis: ({ isDocument }) => !isDocument ? '100%' : '60vw', // Adjust the line
        },

    },
    rightPart: {
        overflowX: 'hidden',
        height: "calc(100vh - 40px)", /* Full viewport height */
        flexBasis: '34vw', // Sets the base size of the left part
        [theme.breakpoints.up('2000')]: {
            flexBasis: '34vw',
        },
        [theme.breakpoints.down('1500')]: {
            flexBasis: '34vw', // Sets the base size of the left part
        },
    },
    paperright: {
        padding: "20px",
        marginRight: "10px",
        height: '100%',  // Make Paper take full height of its container
        display: 'flex',
        flexDirection: 'column',  // Ensures the children (like Chat) also stretch properly
        backgroundColor: "#F8FFFF",
        paddingTop: "0px"

    },
    paper: {
        padding: "20px 82px 20px 82px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        border: ({ isDocument }) => !isDocument ? "1px solid #E2F1F1" : "none", // Add this line
        borderBottom: ({ isDocument }) => !isDocument ? "0px" : "none", // Add this line
        // borderRadius: ({ isDocument }) => !isDocument ? "20px" : "none", // Add this line
        borderTopLeftRadius: ({ isDocument }) => !isDocument ? "20px" : "none", // Add this line
        borderTopRightRadius: ({ isDocument }) => !isDocument ? "20px" : "none", // Add this line
        // padding: ({ isDocument }) => !isDocument ? "1px solid #6BBDBD" : "none", // Add this line

    },
    chatFixed: {
        position: "fixed",
        bottom: "10px", // Adjust as needed
        right: "10px", // Adjust as needed
        width: "25vw", // Adjust as needed
        maxHeight: "100%", // Adjust as needed
        overflowY: "auto", // To allow internal scrolling of the chat
        zIndex: 1000, // Make sure the chat is above other elements
        // ... other necessary styles
    },
    importantPart: {
        position: 'fixed',
        paddingRight: "30px",
        width: '-webkit-fill-available',
        zIndex: 10,
        height: '-webkit-fill-available', // Full height of its container
        display: 'flex', // Add flex display
        flexDirection: 'column', // Stack children vertically
    },
    breadcrumbContainer: {
        position: 'sticky',
        top: 0, // Stick to the top of the viewport
        zIndex: 1100, // Higher index to ensure it's above other content
        padding: theme.spacing(2), // Uniform padding
        width: '100%', // Full width
    },
    summaryContainer: {
        overflowY: "auto", // Only the summary content will scroll
        height: "100%", // You might need to adjust this depending on your layout
    },
    titleAndButtonContainer: {
        justifyContent: 'space-between',
        display: "flex",
        marginBottom: 10,
        marginTop: 15,
        flexShrink: 0,
    },
    chatContainer: {
        flex: 1, // This will allow the chat to grow and fill the space
        display: 'flex', // Ensure it is a flex container
        flexDirection: 'column', // Stack children vertically
        paddingBottom: "20px"
    },
}));

export default useMainStructureStyles;