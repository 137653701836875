import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Tooltip, Skeleton, Button, TextField, Menu, MenuItem } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteDoc from '../PopUpsAlerts/DeleteDoc/DeleteDoc';
import { fetchUserFile } from '../Api/FilesApi';
import * as handleDoc from "./handleDoc";
import { useSubscription } from "../Context/SubscriptionContext";

import { keyframes } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';

const DocumentSkeleton = () => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '32px',
            borderBottom: '1px solid #E0E0E0',
            minHeight: '120px',
        }}
    >
        <Skeleton variant="circular" width={80} height={80} sx={{ marginRight: '20px' }} />
        <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="text" width="60%" height={24} sx={{ marginBottom: '8px' }} />
            <Skeleton variant="text" width="40%" height={20} />
        </Box>
    </Box>
);
// Add this keyframes definition outside of the component
const colorChange = keyframes`
  0% { color: #4ECDC4; }  // Light teal
  33% { color: #45B7A0; } // Seafoam green
  66% { color: #2AB7CA; } // Sky blue
  100% { color: #4ECDC4; } // Back to light teal
`;
const DocumentListFolder = ({
    listDocuments,
    isLoading,
    selectedDocument,
    onDocumentSelect,
    selectable = true,
    box = true,
    token,
    user,
    updateListDocuments,
    setShowPremium
}) => {
    const navigate = useNavigate();
    const { userData } = useSubscription();

    const itemColors = [
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/greenCard.svg", color: '#00B564', label: 'Preguntas Memorizadas' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/yellowCard.svg", color: '#FFA726', label: 'Preguntas Aprendiendo' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/redCar.svg", color: '#AA161D', label: 'Preguntas Incorrectas' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/grayCard.svg", color: '#BDBDBD', label: 'Preguntas Sin Empezar' },
        { image: "", color: '#2196F3', label: 'Preguntas Bloqueadas' },

    ];

    const getMemorizedChaptersText = (document) => {
        const memorizedChapters = document.main_topics.filter(topic =>
            (topic.points / topic.TotalPossiblePoints * 100) >= 80
        ).length;
        const totalChapters = document.main_topics.length;
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        return `${totalPoints} de ${totalMaxPoints} puntos de memoria🧠`;
    };

    const getProgress = (document) => {
        let total;
        if (userData?.plan_name !== "Basic" && !document.is_constitution)
            total = document.status.memorized + document.status.learning +
                document.status.notKnown + document.status.notStarted + document.status.blocked
        else
            total = document.status.memorized + document.status.learning +
                document.status.notKnown + document.status.notStarted;
        const percentageMemorized = total > 0 ?
            (document.status.memorized / total) * 100 : 0;
        const result = percentageMemorized.toFixed(0);
        return isNaN(result) || result === 'null' ? '0' : result;
    };
    const getProgressTopic = (document) => {
        if (userData?.plan_name !== "Basic" && !document.is_constitution)
            return { memorized: document.status.memorized, learning: document.status.learning, notKnown: document.status.notKnown, notStarted: document.status.notStarted, blocked: 0 };

        // For Basic users, calculate blocked questions
        const totalQuestions = document.pages * 12;
        const availableQuestions = document.status.memorized + document.status.learning + document.status.notKnown + document.status.notStarted;
        const blockedQuestions = totalQuestions - availableQuestions;

        return {
            memorized: document.status.memorized,
            learning: document.status.learning,
            notKnown: document.status.notKnown,
            notStarted: document.status.notStarted,
            blocked: blockedQuestions
        };
    };

    const handleBoxClick = (document, event) => {
        event.stopPropagation();
        if (!document.is_blocked) {
            navigate(`/${document.folder}/${document.guid}`);
        } else {
            setShowPremium(true)
        }
    };

    const [editingDocId, setEditingDocId] = useState(null);
    const [editableTitle, setEditableTitle] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDeleteDoc, setShowDeleteDoc] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    const handleEditClick = (event, document) => {
        event.stopPropagation();
        setEditingDocId(document.guid);
        setEditableTitle(document.title);
    };

    const handleTitleBlur = async (document) => {
        setEditingDocId(null);
        try {
            await fetchUserFile(token, "PUT", document.folder, document.guid, { "docName": editableTitle }, user);
            // Update the document title in the list
            updateListDocuments(document.guid, { ...document, title: editableTitle });
        } catch (error) {
            console.error(error);
        }
    };

    const handleTitleChange = (event) => {
        setEditableTitle(event.target.value);
    };

    const handleClickMenu = (event, document) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setDocumentToDelete(document);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleDeleteDocument = async () => {
        try {
            await handleDoc.deleteDocument(token, documentToDelete.guid, documentToDelete.folder, user);
            updateListDocuments(documentToDelete.guid);
        } catch (error) {
            console.error(error);
        }
        setShowDeleteDoc(false);
        handleCloseMenu();
    };

    const handleStudyClick = () => {
        navigate('/new-exam');
        handleCloseMenu();
    };

    const content = (
        <>
            <DeleteDoc
                open={showDeleteDoc}
                onClose={() => setShowDeleteDoc(false)}
                onDelete={handleDeleteDocument}
                documentName={documentToDelete?.title}
            />
            {
                listDocuments.length === 0 ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '32px',
                        minHeight: '300px'
                    }}>
                        <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" width="120" height="120" />
                        <Typography sx={{ mt: 2, color: '#666', fontSize: '16px' }}>
                            Debes subir primero un documento
                        </Typography>
                    </Box>
                ) : (
                    listDocuments.map((document, index) => (
                        <Box
                            key={document.guid}
                            onClick={(event) => handleBoxClick(document, event)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '32px',
                                borderRadius: '24px',
                                borderBottom: index < listDocuments.length - 1 ? 'none' : 'none',
                                minHeight: '120px',
                                backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : 'white',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                    backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : '#E2F1F1',
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                    backgroundColor: '#E2F1F1',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '20px',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: '80px',
                                        height: '80px',
                                    }}
                                >
                                    <CircularProgress
                                        variant="determinate"
                                        value={getProgress(document)}
                                        size={80}
                                        thickness={4}
                                        sx={{
                                            color: '#6BBDBD',
                                            '& .MuiCircularProgress-circle': {
                                                strokeLinecap: 'round',
                                            },
                                            '& .MuiCircularProgress-circleBackground': {
                                                stroke: '#E2F1F1',
                                            },
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '60px',
                                            height: '60px',
                                            borderRadius: '50%',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#6BBDBD',
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                            }}
                                        >
                                            {getProgress(document)}%
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ flexGrow: 1, textAlign: 'left', marginLeft: '20px' }}>
                                {editingDocId === document.guid ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            value={editableTitle}
                                            onChange={handleTitleChange}
                                            variant="standard"
                                            onClick={(event) => event.stopPropagation()}
                                            autoFocus
                                            inputProps={{
                                                maxLength: 120,
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    color: '#333',
                                                    width: '300px',
                                                }
                                            }}
                                        />
                                        <CheckIcon
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleTitleBlur(document);
                                            }}
                                            sx={{
                                                ml: 1,
                                                cursor: 'pointer',
                                                color: '#6BBDBD',
                                                '&:hover': {
                                                    color: '#026277'
                                                }
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                                fontFamily: 'Fira Sans',
                                                color: '#333',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            {document.title.length > 60 ? (
                                                <Tooltip title={document.title}>
                                                    <span>{document.title.substring(0, 60)}...</span>
                                                </Tooltip>
                                            ) : (
                                                document.title
                                            )}
                                        </Typography>
                                        <EditIcon
                                            onClick={(event) => handleEditClick(event, document)}
                                            style={{ marginLeft: '8px', marginBottom: '8px', cursor: 'pointer', fontSize: '16px', color: '#C2C2C2' }}
                                        />
                                    </Box>
                                )}
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '4px',
                                }}>
                                    <Box sx={{
                                        width: '250px',
                                        height: '10px',
                                        backgroundColor: '#F5F5F5',
                                        borderRadius: '6px',
                                        display: 'flex',
                                        overflow: 'hidden'
                                    }}>
                                        {Object.entries(getProgressTopic(document)).map(([key, value], i) => {
                                            const total = Object.values(getProgressTopic(document))
                                                .reduce((sum, curr) => sum + curr, 0);
                                            const percentage = (value / total) * 100;

                                            return (
                                                <Tooltip
                                                    key={key}
                                                    title={`${itemColors[i].label}: ${value}`}
                                                    arrow
                                                >
                                                    <Box
                                                        sx={{
                                                            width: `${percentage}%`,
                                                            height: '100%',
                                                            backgroundColor: itemColors[i].color,
                                                            transition: 'width 0.3s ease'
                                                        }}
                                                    />
                                                </Tooltip>
                                            );
                                        })}
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontFamily: 'Inter',
                                            color: '#666',
                                        }}
                                    >
                                        {userData?.plan_name === "Basic" && !document.is_constitution ? (
                                            <>
                                                {document.status.memorized} de {document.pages * 12} preguntas correctas
                                            </>
                                        ) : (
                                            <>
                                                {document.status.memorized} de {Object.values(document.status).reduce((sum, curr) => sum + curr, 0)} preguntas correctas
                                            </>
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                            {
                                document.is_blocked ? (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#AA161D',
                                            color: 'white',
                                            textTransform: 'none',
                                            fontWeight: '600',
                                            fontFamily: "'Inter', sans-serif",
                                            borderRadius: '24px',
                                            padding: '8px 24px',
                                            fontSize: '14px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                backgroundColor: '#8B1217',
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowPremium(true)
                                        }}
                                    >
                                        Desbloquear
                                    </Button>
                                ) : document.is_error_generating ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#666' }}>
                                            Error generando preguntas
                                        </Typography>
                                    </Box>
                                ) : document.is_generating ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                        <Typography sx={{ fontWeight: 'bold', animation: `${colorChange} 2s ease-in-out infinite` }}>
                                            {'Generando preguntas...'.split('').map((char, index) => (
                                                <span
                                                    key={index}
                                                    sx={{
                                                        display: 'inline-block !important',
                                                        animation: `${colorChange} 2s ease-in-out infinite !important`,
                                                        animationDelay: `${index * 0.1}s !important`,
                                                    }}
                                                >
                                                    {char}
                                                </span>
                                            ))}
                                        </Typography>
                                    </Box>
                                ) : !document.questions_generated ? (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#666' }}>
                                            No tienes preguntas generadas
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        flexGrow: 1,
                                        gap: '32px',
                                    }}>
                                    </Box>
                                )
                            }

                            <MoreVertIcon
                                onClick={(event) => handleClickMenu(event, document)}
                                style={{ color: "#026277", cursor: 'pointer', marginLeft: '8px' }}
                            />
                        </Box>
                    ))
                )
            }
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {documentToDelete && !documentToDelete.is_blocked && !documentToDelete.is_generating && documentToDelete.questions_generated && (
                    <MenuItem onClick={handleStudyClick}>Estudiar</MenuItem>
                )}
                <MenuItem onClick={() => {
                    setShowDeleteDoc(true);
                    handleCloseMenu();
                }}>Delete</MenuItem>
            </Menu>
        </>
    );

    return box ? (
        <Box sx={{ marginTop: '20px', marginBottom: '40px', backgroundColor: 'white', overflowX: 'hidden' }}>
            <Box className="custom-scrollbar" sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {content}
            </Box>
        </Box>
    ) : content;
};

export default DocumentListFolder;
