import { makeStyles } from '@material-ui/core/styles';

const useStylePreguntasListas = makeStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Gray background with opacity
    zIndex: 999, // Ensure it's behind the popup but above other content
  },
  popup: {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '20px',
    textAlign: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    margin: 'auto',
    position: 'fixed', // Add this
    top: '50%', // Add this
    left: '50%', // Add this
    transform: 'translate(-50%, -50%)', // Add this
    zIndex: 1000, // Add this
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  title: {


    '&.MuiTypography-root': {
      fontFamily: 'inherit',
      fontSize: '22px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
  },
  subtitle: {
    fontSize: '1rem',

    '&.MuiTypography-root': {
      fontFamily: 'inherit',
      marginBottom: '24px',
    },
  },
  button: {
    backgroundColor: '#8CCDC1',
    color: 'white',
    padding: '8px 24px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#7BBCB1',
    },
    '&.MuiButton-root': {
      fontFamily: 'inherit',
    },
  },
});

export default useStylePreguntasListas;
