import { makeStyles } from '@material-ui/core/styles';
const useExamCorrectViewStyles = makeStyles({
    resultsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        margin: "0px 50px 50px 50px",
        width: "80%"
    },
    resultBox: {
        border: '1px solid #6BBDBD',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
    },
    resultHeader: {
        backgroundColor: '#6BBDBD',
        color: '#ffffff',
        padding: '16px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
    },
    resultContent: {
        padding: '16px',
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: 'center', // This aligns items vertically in the center
        marginTop: "10px"

    },
    headerTitle: {
        fontWeight: 'bold',
        color: "white"
    },
    score: {
        width: "100px",
        height: "100px",
        backgroundPadding: "#6BBDBD",
        margin: "auto",

    },
    details: {
        display: 'flex',
        gap: '16px',
        justifyContent: "space-between",
        flexDirection: "column",
        marginRight: "50px"

    },
    detailItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    correct: {
        fontSize: "20px !important",
        color: "#A0A0A0"
    },
    incorrect: {
        fontSize: "20px !important",
        color: "#A0A0A0"
    },
    correctIcon: {
        color: '#4caf50',
    },
    incorrectIcon: {
        color: '#f44336',
    },
    conceptsBox: {
        border: '1px solid #FDD32E',
        borderRadius: '20px',
        backgroundColor: 'white',
        maxHeight: '54vh',
        overflow: 'hidden', // Ensure content does not overflow the box
        display: 'flex',
        flexDirection: 'column',
    },
    conceptsHeader: {
        backgroundColor: '#FDD32E',
        color: '#000000',
        padding: '16px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
    },
    conceptsContent: {
        padding: '16px',
        overflowY: 'auto', // Make content scrollable
        flex: 1, // Allow the content to take available space
        height: '100%', // Ensure it fills the parent height
        marginBottom: "10px"

    },
});

export default useExamCorrectViewStyles;
