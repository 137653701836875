import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, IconButton, Typography, Button } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useChooseFileStyles from "./styles/useStylesReadFile";
import TextEditor from "../NewComponents/TextEditor";
import "./styles/ReadComponents.css";
import Tooltip from '@material-ui/core/Tooltip';

import QuillEditor from "../NewComponents/TextEditor";

// replace with your SVG path
import { ReactComponent as SendIcon } from './icons/send.svg'; // replace with your SVG path
import { createMemorise, fetchMemorise } from "./Api/handle";
import IndexList from "./ListIndexComponent";

function MainComponent({ fileChosen, accessToken, user, initPage, finalPage, nextStep }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useChooseFileStyles();
    const [isRecording, setIsRecording] = useState(false);
    const [transcript, setTranscript] = useState("");
    const [recognition, setRecognition] = useState(null);
    const [editorValue, setEditorValue] = useState("");


    const { memorise_id } = useParams();
    const [fileData, setFileData] = useState(fileChosen);
    const [documentId, setDocumentId] = useState("");
    const [memoriseData, setMemoriseData] = useState(fileChosen);


    useEffect(() => {
        if (memorise_id && accessToken) {
            fetchMemorise(accessToken, memorise_id, user)
                .then(data => {
                    setFileData(data.document);
                    setDocumentId(data.document.guid);
                    setMemoriseData(data);
                })
                .catch(error => {
                    console.error("Error fetching file data:", error);
                });
        }
    }, [accessToken, memorise_id, user, fileChosen]);


    const truncateText = (text, length) => {
        if (text) {
            if (text.length <= length) {
                return text;
            }
            return text.substring(0, length) + '...';
        }
    };
    useEffect(() => {
        if ('webkitSpeechRecognition' in window) {
            const speechRecognition = new window.webkitSpeechRecognition();
            speechRecognition.continuous = true;
            speechRecognition.interimResults = true;
            speechRecognition.lang = 'es-ES';

            speechRecognition.onresult = (event) => {
                let interimTranscript = "";
                for (let i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        setEditorValue(prev => prev + event.results[i][0].transcript + ' ');
                    } else {
                        interimTranscript += event.results[i][0].transcript;
                    }
                }
            };

            speechRecognition.onerror = (event) => {
                console.error("Speech recognition error detected: " + event.error);
            };

            setRecognition(speechRecognition);
        } else {
            console.warn("Web Speech API is not supported by this browser.");
        }
    }, []);

    const handleStartRecording = () => {
        if (recognition) {
            recognition.start();
            setIsRecording(true);
        }
    };

    const handleStopRecording = () => {
        if (recognition) {
            recognition.stop();
            setIsRecording(false);
        }
    };

    const handleSendTranscript = async () => {
        if (isRecording) {
            alert("Debes parar la grabación antes de enviar")
        } else {
            if (memorise_id != documentId && documentId != "") {
                nextStep();
                await createMemorise(accessToken, { "text": editorValue, "memorise_id": memorise_id, "file_id": documentId, "initPage": initPage, "finalPage": finalPage, "type_post": "correction" }, user);
            } else {
                alert("Ha habido un error. Recarga la página y vuelve a probar")
            }
        }
    };
    // const debounceSaveData = useCallback(debounce((content) => {
    //     saveData(content);
    // }, 2000), []);

    const handleEditorChange = (value) => {
        setEditorValue(value);
        // debounceSaveData(value);
    };

    return (
        <Container style={{ display: "flex", flexDirection: "row", gap: "50px", backgroundColor: "#F8FFFF", maxWidth: "none", height: "100vh", overflowY: "hidden" }}>
            <Grid container spacing={0} className={classes.leftPart}>
                <Paper elevation={3} className={classes.paperLeft}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
                        <Typography className={classes.title}>Prepara {truncateText(fileData.title, 20)}</Typography>
                        {/* <div><Button className={classes.buttonEmpezar} onClick={() => handleSendTranscript()}>Evaluar</Button></div> */}
                    </div>
                    <Typography style={{ paddingTop: "1px", paddingBottom: "5px", fontSize: "14px" }}>Escribe o habla en voz alta todo lo que recuerdes del temario</Typography>
                    <div id="editor-container" style={{ height: "65vh" }} className="notranslate">

                        {isRecording ? (
                            <div className="boxContainer">
                                <div className="box box1"></div>
                                <div className="box box2"></div>
                                <div className="box box3"></div>
                                <div className="box box4"></div>
                                <div className="box box5"></div>
                            </div>
                        ) : (<QuillEditor
                            value={editorValue}
                            onChange={handleEditorChange}
                            theme="snow"

                        />)}

                    </div>
                    <div style={{ margin: "0 auto", display: "fixed" }}>
                        <Tooltip title={isRecording ? "Detener grabación" : "Iniciar grabación"} arrow>
                            <IconButton color="primary" onClick={isRecording ? handleStopRecording : handleStartRecording}>
                                {isRecording ? <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/stop.svg" alt="Stop" /> : <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/mic.svg" alt="Microphone" />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Enviar texto" arrow>
                            <IconButton color="primary" onClick={handleSendTranscript}>
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Paper>
            </Grid>
            <Grid
                item
                className={`${classes.rightPart}`}

                style={{ overflowY: "hidden", paddingBottom: "30px", marginBottom: "30px" }}

            >
                {memoriseData &&
                    <IndexList accessToken={accessToken} guid={memorise_id} user={user} memoriseData={memoriseData} />
                }



            </Grid>

        </Container>
    );
}

export default MainComponent;
