import { styled } from '@mui/system';


const Container = styled('div')({
  textAlign: 'center',
  padding: '24px',
  fontFamily: 'Inter, sans-serif',
});

const Title = styled('h1')({
  fontSize: '34px',
  fontFamily: 'Fira Sans',
  fontWeight: "bold",
  color: '#000', // You can adjust this color as needed
});

const Subtitle = styled('h2')({
  fontSize: '16px',
  fontWeight: "600",
  color: '#666', // You can adjust this color as needed
  textAlign: 'left',
});

const TemasEmpty = styled('div')({
  backgroundColor: '#f5f5f5',
  border: '2px dashed #ccc',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  color: '#666',
  fontSize: '18px',
  fontWeight: "600",
  fontFamily: 'Inter, sans-serif',
  marginBottom: '20px',
  width: '60%',
  margin: '50px auto', // Center horizontally
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Points = styled('span')({
  fontWeight: 'bold',
  fontSize: '14px',
});

export { Container, Title, Subtitle, TemasEmpty, Points };
