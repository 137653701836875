import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import './editor.css'; // Custom styles

const QuillEditor = ({ value, theme }) => {
    return (
        <div className="quill-editor-container"
            onCopy={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}>
            <ReactQuill
                value={value}

                theme={theme}
                modules={{
                    toolbar: false,
                    keyboard: {
                        bindings: {
                            tab: false,
                            'ctrl+c': false,
                            'command+c': false,
                            'ctrl+x': false,
                            'command+x': false
                        }
                    }
                }}
                readOnly={true}
                formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet',
                    'link', 'image', 'video'
                ]}
                className="quill-editor"
            />
        </div>
    );
};

export default QuillEditor;
