import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from "@auth0/auth0-react";
import './index.css';
import App from './App';
import posthog from 'posthog-js'

import reportWebVitals from './reportWebVitals';
const AUTH0_REDIRECT = process.env.REACT_APP_AUTH0_REDIRECT;
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

// import * as Sentry from "@sentry/react";

// Sentry.init({
//     dsn: "https://a0e0c40a2eae4ee7922ef8b10d91c812@o4504966200492032.ingest.sentry.io/4504966206980096",
//     integrations: [new Sentry.BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });

const root = ReactDOM.createRoot(document.getElementById('root'));


const POSTHOG = process.env.REACT_APP_POSTHOG;
// TODO move posthog inside app
posthog.init(POSTHOG, { api_host: 'https://eu.i.posthog.com' })
root.render(
    // shouldRenderApp ? (

    <Auth0Provider
        // domain="dev-63gz71ilaewjvg5q.eu.auth0.com"
        domain={AUTH0_DOMAIN}
        // clientId="HlGEtf0zI6zXDjG5dCbja5DEL791hPF3"
        clientId={AUTH0_CLIENT_ID}
        authorizationParams={{
            // redirect_uri: "https://localhost:3001/LoginSuccess",
            redirect_uri: AUTH0_REDIRECT,
            audience: AUTH0_AUDIENCE,
            scope: "profile openid email",
        }}
        cacheLocation="localstorage"
    >
        <App />
    </Auth0Provider >


);


